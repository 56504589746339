import React from 'react';
import ShowMoreText from 'react-show-more-text';
import Iframe from 'react-iframe';

const PreviewComponent = ({ previewData }) => {
  function executeOnClick(isExpanded) {
    console.log(isExpanded);
  }
  return (
    <React.Fragment>
      <div className='DetailsComponent DBlock'>
        <div className='Title DFlex'>
          <h3>{previewData[0].title}</h3>
          <strong>{previewData[0].price}AED</strong>
        </div>
        <ul className='BreadCrumbUl DFlex justify-content-start'>
          <li>Home </li>
          <li>Place Your Ad</li>
          <li>Properties</li>
          <li>Properties For Sale</li>
          <li>Residential For Sale</li>
        </ul>
        <div className='DetailCarousel DBlock'>
          <div className='row'>
            <div className='col-lg-8'>
              <div id='carouselExampleIndicators' className='carousel slide' data-ride='carousel'>
                <ol className='carousel-indicators'>
                  {previewData[0].img.map((img, index) => (
                    <li data-target='#carouselExampleIndicators' data-slide-to={index} key={index} className={index === 0 ? 'active' : ''}>
                      <img src={img.images} alt='' />
                    </li>
                  ))}
                </ol>
                <div className='carousel-inner'>
                  {previewData[0].img.map((img, index) => (
                    <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                      <img src={img.images} className='d-block' alt='Item_Image' />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <ul className='ChatUl DBlock'>
                <li>
                  <img src='/assets/images/Chat.png' alt='' /> <span>Live Chat with Seller</span>
                </li>
                <li>
                  <img src='/assets/images/WhatsApp.png' alt='' /> <span>Whatsapp Seller</span>
                </li>
                <li data-toggle='modal' data-target='#staticBackdropEmail'>
                  <img src='/assets/images/Email.png' alt='' /> <span>Emailer Seller</span>
                </li>
                <li>
                  <img src='/assets/images/Call.png' alt='' /> <span>Call Seller</span>
                </li>
              </ul>
            </div>
          </div>
          <div className='PostedDate DBlock'>Ad Posted: 2 hours ago</div>
        </div>
        <div className='DetailsListDiv DBlock'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='DetailsListItem DBlock'>
                <h6>Property Specifications</h6>
                <ul className='ListDetailsUl DBlock'>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Property Type
                    </span>
                    <b>{previewData[0].propertiesSpecification[0].propertyType}</b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Variety
                    </span>
                    <b>{previewData[0].propertiesSpecification[0].variety}</b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Area/Size
                    </span>
                    <b>{previewData[0].propertiesSpecification[0].area}</b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Offered by
                    </span>
                    <b>{previewData[0].propertiesSpecification[0].offeredBy}</b>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='DetailsListItem DBlock'>
                <h6>Key Features</h6>
                <ul className='ListDetailsUl DBlock'>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Lifestyle:
                    </span>
                    <b>{previewData[0].keyFutures[0].lifeStyle}</b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      No. of Bedrooms:
                    </span>
                    <b>{previewData[0].keyFutures[0].bedrooms}</b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      No. Of Bathrooms:
                    </span>
                    <b>{previewData[0].keyFutures[0].bathrooms}</b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Furniture:
                    </span>
                    <b>{previewData[0].keyFutures[0].furniture}</b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Parking Lot:
                    </span>
                    <b>{previewData[0].keyFutures[0].parking}</b>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='DetailsListItem DBlock'>
                <h6>Amenities </h6>
                <ul className='ListDetailsUl DBlock'>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Balcony
                    </span>
                    <b>
                      <img src='/assets/svg/TickMark.svg' alt='' />
                    </b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Central A/C & Heating
                    </span>
                    <b>
                      <img src='/assets/svg/TickMark.svg' alt='' />
                    </b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Built in Kitchen Appliance
                    </span>
                    <b>
                      <img src='/assets/svg/TickMark.svg' alt='' />
                    </b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Mades room
                    </span>
                    <b>
                      <img src='/assets/svg/TickMark.svg' alt='' />
                    </b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Made Service
                    </span>
                    <b>
                      <img src='/assets/svg/TickMark.svg' alt='' />
                    </b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Private Garden
                    </span>
                    <b>
                      <img src='/assets/svg/TickMark.svg' alt='' />
                    </b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Pets Allowed
                    </span>
                    <b>
                      <img src='/assets/svg/TickMark.svg' alt='' />
                    </b>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='DetailsListItem DBlock'>
                <h6>Available Time</h6>
                <ul className='ListDetailsUl DBlock'>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' /> Availability
                    </span>
                    <b>{previewData[0].morning}</b>
                  </li>
                  <li>
                    <span></span>
                    <b>{previewData[0].afternoon}</b>
                  </li>
                  <li>
                    <span></span>
                    <b>{previewData[0].evening}</b>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='Description DBlock DBlock'>
          <h5>Description</h5>
          <div className='ExpandText DBlock'>
            <ShowMoreText lines={3} more='View full description' less='View less description' onClick={executeOnClick} expanded={false} height={280}>
              {previewData[0].description.map((desc, index) => (
                <p key={index}>{desc.text}</p>
              ))}
            </ShowMoreText>
          </div>
        </div>
        <div className='LocationDiv DBlock'>
          <h6>Location</h6>
          <h5>
            <img src='/assets/svg/LocIcon.svg' alt='' /> {previewData[0].location}
          </h5>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='LocationIframe DBlock'>
                <Iframe
                  url={previewData[0].locationUrl}
                  id='myId'
                  className='myClassname'
                  frameborder='0'
                  allowfullscreen=''
                  aria-hidden='false'
                  tabIndex='0'
                  position='relative'
                />
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='RelatedLocDiv DBlock'>
                <p>Nearest Station</p>
                <ul className='LocUl'>
                  {previewData[0].station.map((station, index) => (
                    <li key={index}>{station}</li>
                  ))}
                </ul>
                <p>Zone </p>
                <ul className='LocUl'>
                  {previewData[0].zone.map((zone, index) => (
                    <li key={index}>{zone}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Emailer Modal */}
        <div
          className='modal fade EmailerModal'
          id='staticBackdropEmail'
          data-backdrop='static'
          data-keyboard='false'
          tabIndex='-1'
          aria-labelledby='staticBackdropEmailLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='staticBackdropEmailLabel'>
                  Email Seller
                </h5>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <form action='' className='form'>
                  <div className='form-row'>
                    <div className='col-lg-3 mb-3'>
                      <label htmlFor=''>Full Name:*</label>
                    </div>
                    <div className='col-lg-9 mb-3'>
                      <input type='text' placeholder='Name' name='' required />
                    </div>
                    <div className='col-lg-3 mb-3'>
                      <label htmlFor=''>Message</label>
                    </div>
                    <div className='col-lg-9 mb-3'>
                      <textarea name='' placeholder='Message' required></textarea>
                    </div>
                    <div className='col-lg-3 mb-3'>
                      <label htmlFor=''>Telephone:</label>
                    </div>
                    <div className='col-lg-9 mb-3'>
                      <input type='text' placeholder='Telephone' name='' required />
                    </div>
                    <div className='col-lg-3 mb-3'>
                      <label htmlFor=''>Email</label>
                    </div>
                    <div className='col-lg-9 mb-3'>
                      <input type='text' placeholder='example@example. Ae' name='' required />
                    </div>
                  </div>
                  <div className='form-row my-3 text-center justify-content-center'>
                    <button className='EmailBtn' type='submit'>
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PreviewComponent;
