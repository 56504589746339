import axios from 'axios';

export async function getClassifiedCategory() {
  const res = await axios.get('category', { headers: { ApiKey: 'usman', 'Content-Type': 'application/json' } });
  return res;
}

export async function getClassifiedWarranty() {
  const res = await axios.get('warranty', { headers: { ApiKey: 'usman', 'Content-Type': 'application/json' } });
  return res;
}

export async function getClassifiedAge() {
  const res = await axios.get('age', { headers: { ApiKey: 'usman', 'Content-Type': 'application/json' } });
  return res;
}

export async function getClassifiedUsage() {
  const res = await axios.get('usage', { headers: { ApiKey: 'usman', 'Content-Type': 'application/json' } });
  return res;
}

export async function getClassifiedCondition() {
  const res = await axios.get('condition', { headers: { ApiKey: 'usman', 'Content-Type': 'application/json' } });
  return res;
}
