import React from 'react';

const PropertiesSpecification = ({ handleSpecification, propType, price, type, propVariety, variety, size, propOfferedBy, offeredBy }) => {
  const BG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>3</span>
        <h3>
          Properties Specification <small>(Properties technical specifications detail)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-12 mb-3'>
              <input type='number' name='price' required value={price} placeholder='Price:* 10.000.00 AED' onChange={(e) => handleSpecification(e)} />
            </div>
            <div className='col-12 mb-3'>
              <select name='type' required style={BG} value={type} onChange={(e) => handleSpecification(e)}>
                <option value=''>Property Type:*</option>
                {propType.map((type, index) => (
                  <option value={type.name} key={index}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='variety' required style={BG} value={variety} onChange={(e) => handleSpecification(e)}>
                <option value=''>Variety:*</option>
                {propVariety.map((variety, index) => (
                  <option value={variety.name} key={index}>
                    {variety.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <input
                type='number'
                name='size'
                required
                value={size}
                placeholder='Area/Size:* 2,650 (SqFt)'
                onChange={(e) => handleSpecification(e)}
              />
            </div>
            <div className='col-12 mb-3'>
              <select name='offeredBy' required style={BG} value={offeredBy} onChange={(e) => handleSpecification(e)}>
                <option value=''>Offered by:*</option>
                {propOfferedBy.map((offeredBy, index) => (
                  <option value={offeredBy.name} key={index}>
                    {offeredBy.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default PropertiesSpecification;
