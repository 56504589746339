import React, { Component } from 'react';
import AddDescription from './AddDescription';
import MapLocation from './MapLocation';
import TimeSlots from './TimeSlots';
import UploadImage from './UploadImage';
import WhatsappPermit from './WhatsappPermit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import PreviewComponent from './PreviewComponent';
import { previewApi } from './PreviewApi';
import { Link } from 'react-router-dom';

export class CreateCompanyAds extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setComponent: 'AddDescription',
      subCategory: subCategory,
      checkedA: false,
      checkedB: false,
      preview: false,
      previewData: previewApi,
      agree: false,
    };
  }

  subCategoryHandle = (id) => {
    this.setState({
      setComponent: 'AddDescription',
    });
    //console.log(id);
  };

  updateState = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  previewForm = (e) => {
    this.setState({
      ...this.state,
      setComponent: null,
      preview: true,
    });
  };

  checkState() {
    switch (this.state.setComponent) {
      case 'AddDescription':
        return <AddDescription updateState={this.updateState} />;
      case 'UploadImage':
        return <UploadImage updateState={this.updateState} />;
      case 'MapLocation':
        return <MapLocation updateState={this.updateState} />;
      case 'TimeSlots':
        return <TimeSlots updateState={this.updateState} />;
      case 'WhatsappPermit':
        return <WhatsappPermit updateState={this.updateState} />;
      case 'AdsFeature':
        return (
          <React.Fragment>
            <div className='FormHeading DFlex justify-content-start'>
              <h3>
                <FormControlLabel
                  labelPlacement='start'
                  control={
                    <Switch
                      checked={this.state.checkedA}
                      onChange={(e) => this.setState({ ...this.state, [e.target.name]: e.target.checked })}
                      name='checkedA'
                      color='primary'
                    />
                  }
                  label='Do you want to make your Ad as Feature Ad?'
                />
              </h3>
              <div className='NextBtnDiv DBlock text-center'>
                <button className='NextBtn' onClick={this.previewForm}>
                  Preview your Ad
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      default:
        return this.state.setComponent;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className='PlaceAdsWrapper DBlock'>
          <ul className='BreadCrumb DFlex justify-content-start'>
            <li>Home </li>
            <li>Jobs</li>
            <li>Job Employer </li>
            <li>Create Company Profile</li>
          </ul>
          <div className='CompanyShadowDiv DBlock'>
            <div className='Title DBlock'>
              <h5>Create Company Profile</h5>
            </div>
            <div className='AdsFormOuterDiv DBlock'>{this.state.setComponent !== null && this.checkState()}</div>
            <div className='AdsFormOuterDiv DBlock'>{this.state.preview && <PreviewComponent previewData={this.state.previewData} />}</div>
            {this.state.preview && (
              <React.Fragment>
                <div className='BtnDiv DFlex justify-content-center'>
                  <button className='GoBack NoBefore' onClick={(e) => this.setState({ setComponent: 'AddDescription', preview: false })}>
                    Edit Again
                  </button>
                  <button className='Submit' data-toggle='modal' data-target='#staticBackdropSubmit'>
                    Submit
                  </button>
                </div>
              </React.Fragment>
            )}
            {/* <!-- Modal Submited --> */}
            <div
              className='modal fade'
              id='staticBackdropSubmit'
              data-backdrop='static'
              data-keyboard='false'
              tabIndex='-1'
              aria-labelledby='staticBackdropSubmitLabel'
              aria-hidden='true'
            >
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-body'>
                    <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                      <span aria-hidden='true'>&times;</span>
                    </button>
                    {!this.state.agree ? (
                      <React.Fragment>
                        <div className='ModalInnerDiv DBlock'>
                          <div className='ModalText DBlock'>
                            <h6>Let’s stay safe!</h6>
                            <p> Your add will not go live if it is:</p>
                            <ul className='TextUl DBlock text-left'>
                              <li>
                                <span>1</span>Prohibited item or activity that violates UAE law
                              </li>
                              <li>
                                <span>2</span>Wrong category placement
                              </li>
                              <li>
                                <span>3</span>Placed multiple times, or placed in more than one category
                              </li>
                              <li>
                                <span>4</span>Fraudulent or misleading information
                              </li>
                              <li>
                                <span>5</span>For items located outside of UAE
                              </li>
                            </ul>
                            <p>
                              For more information, read our <span>terms and conditions </span>
                            </p>
                          </div>
                          <div className='ModalBtnDiv DFlex justify-content-center'>
                            <button className='ModalBtn' onClick={() => this.setState({ agree: true })}>
                              I agree
                            </button>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className='ModalInnerDiv DBlock'>
                          <div className='ModalText DBlock'>
                            <h6>Congratulations!</h6>
                            <p>Your Add has been submitted successfully!</p>
                            <img src='/assets/svg/TickCircle.svg' className='Tick' alt='' />
                          </div>
                          <div className='ModalBtnDiv DFlex justify-content-center'>
                            <Link to='#!' className='ModalBtn'>
                              View Ad research
                            </Link>
                            <Link to='#!'>Place another add</Link>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateCompanyAds;

const subCategory = [
  {
    id: 1,
    text: 'Baby Items',
  },
  {
    id: 2,
    text: 'Books',
  },
  {
    id: 3,
    text: 'Business & Industrial',
  },
  {
    id: 4,
    text: 'Camaras & Imaging',
  },
  {
    id: 5,
    text: 'Clothing & Accessories',
  },
  {
    id: 6,
    text: 'Collectibles',
  },
  {
    id: 7,
    text: 'Computers & Networking',
  },
  {
    id: 8,
    text: 'DVD’s & Movies',
  },
  {
    id: 9,
    text: 'Electronics',
  },
  {
    id: 10,
    text: 'Free stuff',
  },
  {
    id: 11,
    text: 'Furniture, Home & Garden',
  },
  {
    id: 12,
    text: 'Gaming',
  },
  {
    id: 13,
    text: 'Home Appliances',
  },
  {
    id: 14,
    text: 'Jewelry & Watches',
  },
  {
    id: 15,
    text: 'Mobile Phones & PDAs',
  },
  {
    id: 16,
    text: 'Music',
  },
  {
    id: 17,
    text: 'Pets',
  },
  {
    id: 18,
    text: 'Sports Equipment',
  },
  {
    id: 19,
    text: 'Stuff Wanted',
  },
  {
    id: 20,
    text: 'Tickets & Vouchers',
  },
  {
    id: 21,
    text: 'Toys',
  },
  {
    id: 22,
    text: 'Misc',
  },
];
