import React from 'react';
import CommunityAnalyticsList from './CommunityAnalyticsList';

const CommunityAnalytics = ({ item, type }) => {
  return (
    <React.Fragment>
      {item.map((item, index) => (
        <CommunityAnalyticsList key={index} item={item} index={index} type={type} />
      ))}
    </React.Fragment>
  );
};

export default CommunityAnalytics;
