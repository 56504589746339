import React from 'react';

const KeyFutures = ({
  handleKeyFeature,
  keyManageBy,
  keyOfferedBy,
  keyBodyType,
  keyTransmission,
  keyModalYear,
  keyKilometers,
  keyCarSize,
  keyMechanicalCondition,
  manageBy,
  offeredBy,
  bodyType,
  transmission,
  modalYear,
  kilometers,
  carSize,
  mechanicalCondition,
}) => {
  const BG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>4</span>
        <h3>
          Key Features <small>(Basic Information about your Ad)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-12 mb-3'>
              <select name='manageBy' required style={BG} value={manageBy} onChange={(e) => handleKeyFeature(e)}>
                <option value=''>Managed by:*</option>
                {keyManageBy.map((manageBy, index) => (
                  <option value={manageBy.name} key={index}>
                    {manageBy.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='offeredBy' required style={BG} value={offeredBy} onChange={(e) => handleKeyFeature(e)}>
                <option value=''>Offered by:*</option>
                {keyOfferedBy.map((offeredBy, index) => (
                  <option value={offeredBy.name} key={index}>
                    {offeredBy.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='bodyType' required style={BG} value={bodyType} onChange={(e) => handleKeyFeature(e)}>
                <option value=''>Body Type:*</option>
                {keyBodyType.map((bodyType, index) => (
                  <option value={bodyType.name} key={index}>
                    {bodyType.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='manufacturer' required style={BG} value={transmission} onChange={(e) => handleKeyFeature(e)}>
                <option value=''>Transmission:*</option>
                {keyTransmission.map((transmission, index) => (
                  <option value={transmission.name} key={index}>
                    {transmission.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='modalYear' required style={BG} value={modalYear} onChange={(e) => handleKeyFeature(e)}>
                <option value=''>Model Year:*</option>
                {keyModalYear.map((modalYear, index) => (
                  <option value={modalYear.name} key={index}>
                    {modalYear.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='kilometers' required style={BG} value={kilometers} onChange={(e) => handleKeyFeature(e)}>
                <option value=''>Kilometers:*</option>
                {keyKilometers.map((kilometers, index) => (
                  <option value={kilometers.name} key={index}>
                    {kilometers.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='carSize' required style={BG} value={carSize} onChange={(e) => handleKeyFeature(e)}>
                <option value=''>Size of Car:*</option>
                {keyCarSize.map((carSize, index) => (
                  <option value={carSize.name} key={index}>
                    {carSize.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='mechanicalCondition' required style={BG} value={mechanicalCondition} onChange={(e) => handleKeyFeature(e)}>
                <option value=''>Mechanical Condition:*</option>
                {keyMechanicalCondition.map((mechanicalCondition, index) => (
                  <option value={mechanicalCondition.name} key={index}>
                    {mechanicalCondition.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default KeyFutures;
