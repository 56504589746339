import React from 'react';
import { Link } from 'react-router-dom';
import { companyItems } from '../companySearch/Api';

const PreviewComponent = ({ previewData }) => {
  function executeOnClick(isExpanded) {
    console.log(isExpanded);
  }
  return (
    <React.Fragment>
      <div className='DetailsComponent DBlock'>
        <div className='Title DFlex'>
          <h3>{companyItems[0].title}</h3>
          <Link className='ViewJob' to={`/job-search`}>
            Open Jobs
          </Link>
        </div>
        <ul className='BreadCrumbUl DFlex justify-content-start'>
          <li>Home </li>
          <li>Jobs</li>
          <li>Find a Company</li>
          <li>Citi Bank</li>
        </ul>
        <div className='CompanyImgDiv DBlock'>
          <div className='MainImg'>
            <img src={companyItems[0].img[0].images} alt='' />
          </div>
          <img src={companyItems[0].coverImg} alt='' />
        </div>
        <div className='DetailsListDiv DBlock'>
          <div className='row mb-4'>
            <div className='col-md-6'>
              <div className='DetailsListItem DBlock'>
                <h6>Overview:</h6>
                <ul className='ListDetailsUl DBlock'>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Industry:
                    </span>
                    <b>{companyItems[0].industry}</b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Trade License No.:
                    </span>
                    <b>{companyItems[0].tradeLicence}</b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Established Since:
                    </span>
                    <b>{companyItems[0].established}</b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Number of Employees
                    </span>
                    <b>{companyItems[0].Employees} + Employees</b>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Location
                    </span>
                    <b>{companyItems[0].location}</b>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-md-6'>
              <div className='DetailsListItem DBlock'>
                <h6>Specialties:</h6>
                <ul className='ListDetailsUl DBlock'>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Credit Cards
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Cash Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Capital Markets
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Investment
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src='/assets/svg/setting.svg' alt='' />
                      Consumer Lending
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='Description DBlock DBlock'>
          <h5>About Us</h5>
          {companyItems[0].description.map((text, index) => (
            <p key={index}>{text.text}</p>
          ))}
        </div>
        <div className='MoreBankDiv DBlock'>
          <h5>More from City Bank:</h5>
          <div className='row'>
            <div className='col-md-6'>
              <div className='MoreBank DFlex'>
                <div className='ImgDiv DBlock'>
                  <img src='/assets/images/MoreBank1.png' alt='' className='' />
                </div>
                <div className='TextDiv DBlock'>
                  <p>Citigold</p>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='MoreBank DFlex'>
                <div className='ImgDiv DBlock'>
                  <img src='/assets/images/MoreBank2.png' alt='' className='' />
                </div>
                <div className='TextDiv DBlock'>
                  <p>Citi Private Bank</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PreviewComponent;
