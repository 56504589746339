import React from 'react';

const JobInfo = () => {
  const BG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };

  return (
    <div className='FormInnerDiv DBlock'>
      <h4>Job Info</h4>
      <form action=''>
        <div className='form-row'>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Title name of Job: </label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='text' placeholder='Job Name' required />
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Company name: </label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='text' placeholder='Company Name' required />
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Job title: </label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='text' placeholder='Job Title' required />
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Location:</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <select name='' id='' style={BG}>
              <option value=''>Dubai, UAE</option>
              <option value=''>Sharjah</option>
            </select>
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Job Field:</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <select name='' id='' style={BG}>
              <option value=''>Graphic Design</option>
              <option value=''>Ui/UX Design</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  );
};

export default JobInfo;
