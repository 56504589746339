import React from 'react';

const KeyFutures = ({ handleKeyFeature, keyFeatureAge, keyFeatureCondition, keyFeatureUsage }) => {
  const [values, setValues] = React.useState({ age: '', condition: '', usage: '' });

  const handleForm = (e) => {
    e.preventDefault();
    handleKeyFeature({ values });
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  const BG = {
    backgroundImage: 'url(assets/svg/DownArr.svg)',
  };
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>4</span>
        <h3>
          Key Features <small>(Basic Information about your Ad)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action='' onSubmit={(e) => handleForm(e)}>
          <div className='form-row'>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Age:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='age' style={BG} value={values.age} onChange={(e) => handleChange(e)} required>
                <option value=''>Select an option</option>
                {keyFeatureAge.map((warranty, index) => (
                  <option value={warranty.name} key={index}>
                    {warranty.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Usage:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='usage' style={BG} value={values.usage} onChange={(e) => handleChange(e)} required>
                <option value=''>Select an option</option>
                {keyFeatureUsage.map((warranty, index) => (
                  <option value={warranty.name} key={index}>
                    {warranty.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Condition:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='condition' style={BG} value={values.condition} onChange={(e) => handleChange(e)} required>
                <option value=''>Select an option</option>
                {keyFeatureCondition.map((warranty, index) => (
                  <option value={warranty.name} key={index}>
                    {warranty.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='NextBtnDiv DBlock text-center'>
            <button className='NextBtn' type='submit'>
              Next
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default KeyFutures;
