import React from 'react';

const MotorSpecification = ({
  handleSpecification,
  specManufacturer,
  specModal,
  specDoor,
  specColor,
  specFuelType,
  specHorsepower,
  specBodyCondition,
  bodyCondition,
  horsepower,
  fuelType,
  color,
  door,
  modal,
  manufacturer,
  price,
}) => {
  const BG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>3</span>
        <h3>
          Motors Specification <small>(Car technical specifications detail)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-12 mb-3'>
              <input type='text' name='price' value={price} placeholder='Price:* 10.000.00 AED' onChange={(e) => handleSpecification(e)} />
            </div>
            <div className='col-12 mb-3'>
              <select name='manufacturer' required style={BG} value={manufacturer} onChange={(e) => handleSpecification(e)}>
                <option value=''>Select an Manufacturer:*</option>
                {specManufacturer.map((manufacturer, index) => (
                  <option value={manufacturer.name} key={index}>
                    {manufacturer.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='modal' required style={BG} value={modal} onChange={(e) => handleSpecification(e)}>
                <option value=''>Select an Model:*</option>
                {specModal.map((modal, index) => (
                  <option value={modal.name} key={index}>
                    {modal.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='door' required style={BG} value={door} onChange={(e) => handleSpecification(e)}>
                <option value=''>Select a Doors:*</option>
                {specDoor.map((door, index) => (
                  <option value={door.name} key={index}>
                    {door.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='color' required style={BG} value={color} onChange={(e) => handleSpecification(e)}>
                <option value=''>Select a Color:*</option>
                {specColor.map((color, index) => (
                  <option value={color.name} key={index}>
                    {color.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='fuelType' required style={BG} value={fuelType} onChange={(e) => handleSpecification(e)}>
                <option value=''>Select Fuel Type:*</option>
                {specFuelType.map((fuelType, index) => (
                  <option value={fuelType.name} key={index}>
                    {fuelType.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='horsepower' required style={BG} value={horsepower} onChange={(e) => handleSpecification(e)}>
                <option value=''>Select an Horsepower:*</option>
                {specHorsepower.map((horsepower, index) => (
                  <option value={horsepower.name} key={index}>
                    {horsepower.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='bodyCondition' required style={BG} value={bodyCondition} onChange={(e) => handleSpecification(e)}>
                <option value=''>Select Body Condition:*</option>
                {specBodyCondition.map((bodyCondition, index) => (
                  <option value={bodyCondition.name} key={index}>
                    {bodyCondition.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default MotorSpecification;
