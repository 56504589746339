import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './Profile.scss';

const ProfileLeftSideBar = () => {
  let activeUrl = useHistory().location.pathname;
  return (
    <React.Fragment>
      <ul className='SideUl DBlock'>
        <li>
          <Link to='/my-ads' className={activeUrl === '/my-ads' ? 'active' : ''}>
            <i className='far fa-file-alt icon'></i>
            My Ads
          </Link>
        </li>
        <li>
          <Link to='/liked-ads' className={activeUrl === '/liked-ads' ? 'active' : ''}>
            <i className='far fa-heart icon'></i>Liked Ads
          </Link>
        </li>
        <li>
          <Link to='/ads-analytics' className={activeUrl === '/ads-analytics' ? 'active' : ''}>
            <i className='fas fa-chart-bar icon'></i>
            Data
          </Link>
        </li>
        <li>
          <Link to='/profile' className={activeUrl === '/profile' ? 'active' : ''}>
            <i className='far fa-user icon'></i> My Profile
          </Link>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default ProfileLeftSideBar;
