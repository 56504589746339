import React from 'react';

const KeyFutures = ({ handleKeyFeature, keyFeatureAge, keyFeatureCondition, keyFeatureUsage, age, usage, condition }) => {
  const BG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>4</span>
        <h3>
          Key Features <small>(Basic Information about your Ad)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-12 mb-3'>
              <select name='age' style={BG} value={age} onChange={(e) => handleKeyFeature(e)} required>
                <option value=''>Select an Age:*</option>
                {keyFeatureAge.map((age, index) => (
                  <option value={age.name} key={index}>
                    {age.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='usage' style={BG} value={usage} onChange={(e) => handleKeyFeature(e)} required>
                <option value=''>Select an Usage:*</option>
                {keyFeatureUsage.map((usage, index) => (
                  <option value={usage.name} key={index}>
                    {usage.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='condition' style={BG} value={condition} onChange={(e) => handleKeyFeature(e)} required>
                <option value=''>Select an Condition:*</option>
                {keyFeatureCondition.map((condition, index) => (
                  <option value={condition.name} key={index}>
                    {condition.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default KeyFutures;
