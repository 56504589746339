import React from 'react';
import PropertiesAnalyticsList from './PropertiesAnalyticsList';

const PropertiesAnalytics = ({ item, type }) => {
  return (
    <React.Fragment>
      {item.map((item, index) => (
        <PropertiesAnalyticsList key={index} type={type} index={index} item={item} />
      ))}
    </React.Fragment>
  );
};

export default PropertiesAnalytics;
