import React, { Component } from 'react';
import { classifiedItems, classifiedTrends } from './Api';
import GridViewComponent from './GridViewComponent';
import SortItem from './SortItem';
import FilterComponent from './FilterComponent';

export class MainComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      classifiedItems: classifiedItems,
      classifiedTrends: classifiedTrends,
      top: -150,
    };
  }

  handleFilter = () => {
    this.setState({
      top: 0,
    });
  };

  removeFilter = () => {
    this.setState({
      top: -150,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className='MainComponent DBlock'>
          <ul className='BreadUl DFlex'>
            <li>Home</li>
            <li>Classified</li>
            {this.props.breadCrumb && this.props.breadCrumb.map((list, index) => <li key={index}>{list}</li>)}
          </ul>
          <p className='Result'>
            <span>23,456</span> Results in Classifieds
          </p>
          <ul className='FilterUl DFlex'>
            <li>
              <button onClick={this.handleFilter}>
                <i className='fas fa-list-ul icon'></i>Filter
              </button>
            </li>
            <li>
              <SortItem />
            </li>
          </ul>
          <div className='MostPopularDiv DBlock'>
            <h2>Most Popular Classifieds</h2>
            <GridViewComponent item={this.state.classifiedItems} />
          </div>
          <div className='MostPopularDiv DBlock'>
            <h2>Trending in Mobile</h2>
            <GridViewComponent item={this.state.classifiedTrends} />
          </div>
        </div>
        <div className='FilterBar DBlock' style={{ top: this.state.top + '%' }} id='FilterBar'>
          <FilterComponent removeFilter={this.removeFilter} />
        </div>
      </React.Fragment>
    );
  }
}

export default MainComponent;
