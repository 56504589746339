import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const Form = () => {
  const classes = useStyles();
  const [education, setEducation] = React.useState([{ education: null, school: null, date: null, description: null }]);

  const addRate = (e) => {
    e.preventDefault();
    setEducation([...education, { education: null, school: null, date: null, description: null }]);
  };

  const BG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form Handling');
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        {education.map((row, index) => (
          <div key={index} className='form-row'>
            <div className='col-md-5 col-12 mb-3'>
              <label htmlFor=''>Academic Degree: *</label>
            </div>
            <div className='col-md-7 col-12 mb-3'>
              <select name='' id='' style={BG}>
                <option value=''>Doctoral Degree</option>
                <option value=''>Master's Degree</option>
                <option value=''>Bachelor's Degree</option>
                <option value=''>Certificates & Diploma</option>
                <option value=''>High School</option>
                <option value=''>Others</option>
              </select>
            </div>
            <div className='col-md-5 col-12 mb-3'>
              <label htmlFor=''>School Name: *</label>
            </div>
            <div className='col-md-7 col-12 mb-3'>
              <input type='text' placeholder='Enter School' required />
            </div>
            <div className='col-md-3 col-12 mb-3'>
              <label htmlFor=''>From: *</label>
            </div>
            <div className='col-md-4 col-12 mb-3'>
              <TextField
                id='date'
                type='date'
                defaultValue='2017-05-24'
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className='col-md-1 col-12 mb-3'>
              <span className='d-block text-center'>-</span>
            </div>
            <div className='col-md-4 col-12 mb-3'>
              <TextField
                id='date'
                type='date'
                defaultValue='2017-05-24'
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className='col-md-5 col-12 mb-3'>
              <label htmlFor=''>Education Description: *</label>
            </div>
            <div className='col-md-7 col-12 mb-3'>
              <textarea name='' placeholder='Enter Description'></textarea>
            </div>
          </div>
        ))}
        <button onClick={(e) => addRate(e)} className='AddBtn'>
          <i className='fas fa-plus-circle icon'></i> Add Education
        </button>
      </form>
    </React.Fragment>
  );
};

const Education = () => {
  return (
    <div className='FormInnerDiv DBlock'>
      <h4>Education</h4>
      <Form />
    </div>
  );
};

export default Education;
