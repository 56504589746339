import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

export class WhatsappPermit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      whatsapp: false,
      value: 'no',
      phone: '',
    };
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  setPhoneNumber(e) {
    console.log(e);
    if (e !== undefined) {
      this.setState({ phone: e });
    }
  }

  // handlePermit = (e) => {
  //   if (this.state.value === 'yes') {
  //     console.log('working1');
  //     if (this.state.phone !== undefined && this.state.phone !== '') {
  //       console.log('working2');
  //       this.props.updateState(e, this.state.phone);
  //     } else {
  //       console.log('working3');
  //       e.preventDefault();
  //       console.error('Enter the whatsapp number');
  //     }
  //   } else {
  //     console.log('working4');
  //     this.props.updateState(e);
  //   }
  // };

  render() {
    return (
      <React.Fragment>
        <div className='FormHeading DFlex justify-content-start'>
          <span className='CustomSpan'>8</span>
          <h3>
            Whatsapp Permit <small>(Prefered number for Whatsapp)</small>
          </h3>
        </div>
        <div className='FormDescription DBlock'>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Do you allow the buyer to contact you on WhatsApp?</FormLabel>
            <RadioGroup aria-label='gender' name='gender1' value={this.state.value} onChange={(e) => this.handleChange(e)}>
              <FormControlLabel value='yes' control={<Radio />} label='Yes' />
              <FormControlLabel value='no' control={<Radio />} label='No' />
            </RadioGroup>
          </FormControl>
          {this.state.value === 'yes' ? (
            <React.Fragment>
              <form action='' className='form'>
                <div className='form-row mt-3'>
                  <div className='col-12'>
                    <PhoneInput placeholder='Enter phone number' required value={this.state.phone} onChange={(e) => this.props.setWhatsapp(e)} />
                    <button className='EditBtn' type='submit'>
                      <i className='fas fa-pencil-alt icon'></i>
                    </button>
                  </div>
                </div>
              </form>
            </React.Fragment>
          ) : (
            ''
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default WhatsappPermit;
