import React from 'react';
import ClassifiedAdsList from './ClassifiedAdsList';

const ClassifiedAds = ({ item, type }) => {
  return (
    <React.Fragment>
      {item.map((item, index) => (
        <ClassifiedAdsList item={item} key={index} index={index} type={type} />
      ))}
    </React.Fragment>
  );
};

export default ClassifiedAds;
