import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './Header.scss';

export default function Header() {
  let activeUrl = useHistory().location.pathname;
  const [login, setLogin] = React.useState(false);
  const [active, setActive] = React.useState(activeUrl);

  const handleActive = (e) => {
    setActive(e.target.name);
  };
  return (
    <React.Fragment>
      <nav className='navbar navbar-expand-md navbar-light w-100'>
        <Link className='navbar-brand' to='/'>
          <img src='/assets/svg/Logo.svg' alt='' />
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav ml-auto'>
            {login ? (
              <li className='nav-item dropdown'>
                <Link
                  className='nav-link dropdown-toggle'
                  to='#SignIn'
                  id='navbarDropdown'
                  role='button'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  Hello Mohamed{' '}
                </Link>
                <div className='dropdown-menu p-0 overflow-hidden' aria-labelledby='navbarDropdown'>
                  <ul className='FormLoginUl DBlock'>
                    <li>
                      <Link to='/my-ads' className={active === 'my-ads' ? 'active' : ''} name='my-ads' onClick={(e) => handleActive(e)}>
                        <img src='assets/svg/ads.svg' alt='' /> My Ads
                      </Link>
                    </li>
                    <li>
                      <Link to='/liked-ads' className={active === 'liked-ads' ? 'active' : ''} name='liked-ads' onClick={(e) => handleActive(e)}>
                        <img src='assets/svg/like.svg' alt='' /> Liked Ads
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/ads-analytics'
                        className={active === 'ads-analytics' ? 'active' : ''}
                        name='ads-analytics'
                        onClick={(e) => handleActive(e)}
                      >
                        <img src='assets/svg/data.svg' alt='' /> Data
                      </Link>
                    </li>
                    <li>
                      <Link to='/profile' className={active === 'profile' ? 'active' : ''} name='profile' onClick={(e) => handleActive(e)}>
                        <img src='assets/svg/person.svg' alt='' /> My profile
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            ) : (
              <li className='nav-item dropdown'>
                <Link
                  className='nav-link dropdown-toggle'
                  to='#SignIn'
                  id='navbarDropdown'
                  role='button'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  Sign in{' '}
                </Link>
                <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                  <form action='' className='form'>
                    <div className='form-row'>
                      <div className='col-12 mb-3'>
                        <input type='email' name='email' id='email' placeholder='Email' required />
                      </div>
                      <div className='col-12 mb-3'>
                        <input type='password' name='password' id='password' placeholder='Password' required />
                      </div>
                      <div className='col-12 mb-3 pb-3 Border'>
                        <button className='FormBtn' type='submit'>
                          Sign In
                        </button>
                      </div>
                    </div>
                  </form>
                  <ul className='FormOuterUl DFlex justify-content-center text-center'>
                    <li>
                      <p>Don't have an account</p>
                    </li>
                    <li>
                      <Link to='/sign-up'>Sign Up</Link>
                    </li>
                  </ul>
                </div>
              </li>
            )}
          </ul>
          <Link className='PlaceYourAdd' to='/place-your-ad'>
            Place Your Add
          </Link>
        </div>
      </nav>
    </React.Fragment>
  );
}
