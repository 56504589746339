import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const applicants = ['Email', 'In Person'];

const ApplicationSetting = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [application, setApplication] = React.useState('Email');
  const [individual, setIndividual] = React.useState({ checked: false });

  const handleIndividual = (event) => {
    setIndividual({ [event.target.name]: event.target.checked });
  };

  const handleSalary = (event) => {
    setApplication(event.target.value);
  };

  return (
    <div className='FormInnerDiv DBlock'>
      <h4>Application Setting</h4>
      <form action=''>
        <div className='form-row'>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Recive Applications* :</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <Button aria-controls='simple-menu' aria-haspopup='true' onClick={(e) => setAnchorEl(e.currentTarget)}>
              Select
            </Button>
            <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={(e) => setAnchorEl(null)}>
              <RadioGroup aria-label='contract' name='contract' value={application} onChange={handleSalary}>
                {applicants.map((applicant, i) => (
                  <FormControlLabel key={i} value={applicant} control={<Radio />} label={applicant} />
                ))}
              </RadioGroup>
            </Menu>
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Daily Updates of Candidates Will Be Sent To *:</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='email' placeholder='email@email.ae' required />
          </div>
          <div className='col-12'>
            <FormControlLabel
              className='CheckBox'
              control={<Checkbox checked={individual.checked} onChange={handleIndividual} name='checked' color='primary' />}
              label='Send an individual email update every time a new candidate applies'
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ApplicationSetting;
