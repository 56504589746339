import React from 'react';

const CommunitySpecification = ({ handleSpecification, price }) => {
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>3</span>
        <h3>
          Key Specification <small>(Key specifications detail)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-12 mb-3'>
              <input type='number' name='price' required value={price} placeholder='Price:* 10.000.00 AED' onChange={(e) => handleSpecification(e)} />
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default CommunitySpecification;
