import React from 'react';
import MainDetailsComponent from './MainDetailsComponent';

export default function PropertiesDetails() {
  return (
    <React.Fragment>
      <div className='ClassifiedCom DBlock position-relative px-3'>
        <div className='CompDiv DFlex'>
          <div className='MainComponent DBlock'>
            <MainDetailsComponent />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
