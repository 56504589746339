import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';

const Footer = () => {
  return (
    <React.Fragment>
      <footer className='MobFooter DBlock'>
        <div className='FooterLinks DFlex'>
          <Link to='/'>
            <img src='/assets/svg/HomeFooter.svg' alt='' />
            <span>Home</span>
          </Link>
          <Link to='/place-your-ad'>
            <img src='/assets/svg/PlaceFooter.svg' alt='' />
            <span>Place Your Ad</span>
          </Link>
          <Link to='/search'>
            <img src='/assets/svg/SearchFooter.svg' alt='' />
            <span>Search</span>
          </Link>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
