import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';
import Iframe from 'react-iframe';
import { communityItems } from '../Api';

function MainDetailsComponent(props) {
  let { id } = useParams();
  const detailItem = communityItems.findIndex((item) => item.id === parseInt(id));
  function executeOnClick(isExpanded) {
    console.log(isExpanded);
  }
  const leftArrow = {
    backgroundImage: 'url(/assets/svg/LeftArrow.svg)',
  };
  const rightArrow = {
    backgroundImage: 'url(/assets/svg/RightArrow.svg)',
  };
  return (
    <React.Fragment>
      <Link to='/community' className='GoBack'>
        Back to Community listings
      </Link>
      {detailItem !== -1 ? (
        <div className='DetailsComponent DBlock'>
          <div className='Title DFlex'>
            <h3>{communityItems[detailItem].title}</h3>
            <strong>{communityItems[detailItem].price}AED</strong>
          </div>
          <ul className='BreadCrumbUl DFlex justify-content-start'>
            <li>Home </li>
            <li>Community </li>
            <li>Services</li>
            <li>Body Builder</li>
          </ul>
          <div className='DetailCarousel DBlock'>
            <div className='row'>
              <div className='col-lg-8'>
                <div id='carouselExampleControls' className='carousel slide' data-ride='carousel'>
                  <div className='carousel-inner'>
                    {communityItems[detailItem].img.map((img, index) => (
                      <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                        <img src={img.images} className='d-block' alt='Item_Image' />
                      </div>
                    ))}
                  </div>
                  <a className='carousel-control-prev' href='#carouselExampleControls' role='button' data-slide='prev'>
                    <span className='carousel-control-prev-icon' style={leftArrow} aria-hidden='true'></span>
                    <span className='sr-only'>Previous</span>
                  </a>
                  <a className='carousel-control-next' href='#carouselExampleControls' role='button' data-slide='next'>
                    <span className='carousel-control-next-icon' style={rightArrow} aria-hidden='true'></span>
                    <span className='sr-only'>Next</span>
                  </a>
                </div>
              </div>
              <div className='col-lg-4'>
                <ul className='ChatUl DBlock'>
                  <li>
                    <img src='/assets/images/Chat.png' alt='' /> <span>Live Chat with Seller</span>
                  </li>
                  <li>
                    <img src='/assets/images/WhatsApp.png' alt='' /> <span>Whatsapp Seller</span>
                  </li>
                  <li data-toggle='modal' data-target='#staticBackdropEmailMobile'>
                    <img src='/assets/images/Email.png' alt='' /> <span>Emailer Seller</span>
                  </li>
                  <li>
                    <img src='/assets/images/Call.png' alt='' /> <span>Call Seller</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className='PostedDate DBlock'>Ad Posted: 2 hours ago</div>
          </div>
          <div className='DetailsListDiv DBlock'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='DetailsListItem DBlock'>
                  <h6>Available Time</h6>
                  <ul className='ListDetailsUl DBlock'>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' /> Availability
                      </span>
                      <b>{communityItems[detailItem].morning}</b>
                    </li>
                    <li>
                      <span></span>
                      <b>{communityItems[detailItem].afternoon}</b>
                    </li>
                    <li>
                      <span></span>
                      <b>{communityItems[detailItem].evening}</b>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='Description DBlock DBlock'>
            <h5>Description</h5>
            <div className='ExpandText DBlock'>
              <ShowMoreText
                lines={3}
                more='View full description'
                less='View less description'
                anchorclassName=''
                onClick={executeOnClick}
                expanded={false}
                height={280}
              >
                {communityItems[detailItem].description.map((desc, index) => (
                  <p key={index}>{desc.text}</p>
                ))}
              </ShowMoreText>
            </div>
          </div>
          <div className='LocationDiv DBlock'>
            <h6>Location</h6>
            <h5>
              <img src='/assets/svg/LocIcon.svg' alt='' /> {communityItems[detailItem].location}
            </h5>
            <div className='row'>
              <div className='col-lg-8'>
                <div className='LocationIframe DBlock'>
                  <Iframe
                    url={communityItems[detailItem].locationUrl}
                    id='myId'
                    className='myClassname'
                    frameborder='0'
                    allowfullscreen=''
                    aria-hidden='false'
                    tabIndex='0'
                    position='relative'
                  />
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='RelatedLocDiv DBlock'>
                  <p>Nearest Station</p>
                  <ul className='LocUl'>
                    {communityItems[detailItem].station.map((station, index) => (
                      <li key={index}>{station}</li>
                    ))}
                  </ul>
                  <p>Zone </p>
                  <ul className='LocUl'>
                    {communityItems[detailItem].zone.map((zone, index) => (
                      <li key={index}>{zone}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Emailer Modal */}
          <div
            className='modal fade EmailerModal'
            id='staticBackdropEmailMobile'
            data-backdrop='static'
            data-keyboard='false'
            tabIndex='-1'
            aria-labelledby='staticBackdropEmailMobileLabel'
            aria-hidden='true'
          >
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title' id='staticBackdropEmailMobileLabel'>
                    Email Seller
                  </h5>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div className='modal-body'>
                  <form action='' className='form'>
                    <div className='form-row'>
                      <div className='col-lg-3 mb-3'>
                        <label htmlFor=''>Full Name:*</label>
                      </div>
                      <div className='col-lg-9 mb-3'>
                        <input type='text' placeholder='Name' name='' required />
                      </div>
                      <div className='col-lg-3 mb-3'>
                        <label htmlFor=''>Message</label>
                      </div>
                      <div className='col-lg-9 mb-3'>
                        <textarea name='' placeholder='Message' required></textarea>
                      </div>
                      <div className='col-lg-3 mb-3'>
                        <label htmlFor=''>Telephone:</label>
                      </div>
                      <div className='col-lg-9 mb-3'>
                        <input type='text' placeholder='Telephone' name='' required />
                      </div>
                      <div className='col-lg-3 mb-3'>
                        <label htmlFor=''>Email</label>
                      </div>
                      <div className='col-lg-9 mb-3'>
                        <input type='text' placeholder='example@example. Ae' name='' required />
                      </div>
                    </div>
                    <div className='form-row my-3 text-center justify-content-center'>
                      <button className='EmailBtn' type='submit'>
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='NotFound DBlock'>
          <h3>Opps Your Items is not found!</h3>
        </div>
      )}
    </React.Fragment>
  );
}

export default MainDetailsComponent;
