import React from 'react';

const ClassifiedSpecification = ({ handleSpecification, specificationWarranty, price, warranty }) => {
  const BG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>3</span>
        <h3>
          Classified Specification <small>(Classified technical specifications detail)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-12 mb-3'>
              <input type='number' name='price' required value={price} placeholder='Price:* 10.000.00 AED' onChange={(e) => handleSpecification(e)} />
            </div>
            <div className='col-12 mb-3'>
              <select name='warranty' required style={BG} value={warranty} onChange={(e) => handleSpecification(e)}>
                <option value=''>Select an Warranty:*</option>
                {specificationWarranty.map((warranty, index) => (
                  <option value={warranty.name} key={index}>
                    {warranty.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default ClassifiedSpecification;
