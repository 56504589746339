import React from 'react';
import MotorsAnalyticsList from './MotorsAnalyticsList';

const MotorsAnalytics = ({ item, type }) => {
  return (
    <React.Fragment>
      {item.map((item, index) => (
        <MotorsAnalyticsList key={index} item={item} index={index} type={type} />
      ))}
    </React.Fragment>
  );
};

export default MotorsAnalytics;
