import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';
import { motorsItems } from '../Api';
import Iframe from 'react-iframe';

function MainDetailsComponent(props) {
  let { id } = useParams();
  const detailItem = motorsItems.findIndex((item) => item.id === parseInt(id));
  function executeOnClick(isExpanded) {
    console.log(isExpanded);
  }
  return (
    <React.Fragment>
      <Link to='/motors' className='GoBack'>
        Back to Motors listings
      </Link>
      {detailItem !== -1 ? (
        <div className='DetailsComponent DBlock'>
          <div className='Title DFlex'>
            <h3>{motorsItems[detailItem].title}</h3>
            <strong>{motorsItems[detailItem].price}AED</strong>
          </div>
          <ul className='BreadCrumbUl DFlex justify-content-start'>
            <li>Home </li>
            <li>Motors</li>
            <li>Used Cares For Sale</li>
            <li>Jeep</li>
          </ul>
          <div className='DetailCarousel DBlock'>
            <div className='row'>
              <div className='col-lg-8'>
                <div id='carouselExampleIndicators' className='carousel slide' data-ride='carousel'>
                  <ol className='carousel-indicators'>
                    {motorsItems[detailItem].img.map((img, index) => (
                      <li data-target='#carouselExampleIndicators' data-slide-to={index} key={index} className={index === 0 ? 'active' : ''}>
                        <img src={img.images} alt='' />
                      </li>
                    ))}
                  </ol>
                  <div className='carousel-inner'>
                    {motorsItems[detailItem].img.map((img, index) => (
                      <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                        <img src={img.images} className='d-block' alt='Item_Image' />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <ul className='ChatUl DBlock'>
                  <li>
                    <img src='/assets/images/Chat.png' alt='' /> <span>Live Chat with Seller</span>
                  </li>
                  <li>
                    <img src='/assets/images/WhatsApp.png' alt='' /> <span>Whatsapp Seller</span>
                  </li>
                  <li data-toggle='modal' data-target='#staticBackdropEmail'>
                    <img src='/assets/images/Email.png' alt='' /> <span>Emailer Seller</span>
                  </li>
                  <li>
                    <img src='/assets/images/Call.png' alt='' /> <span>Call Seller</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className='PostedDate DBlock'>Ad Posted: 2 hours ago</div>
          </div>
          <div className='DetailsListDiv DBlock'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='DetailsListItem DBlock'>
                  <h6>Motors Specification</h6>
                  <ul className='ListDetailsUl DBlock'>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Manufacturer
                      </span>
                      <b>{motorsItems[detailItem].motorsSpecification[0].manufacturer}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Model
                      </span>
                      <b>{motorsItems[detailItem].motorsSpecification[0].model}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Doors
                      </span>
                      <b>{motorsItems[detailItem].motorsSpecification[0].doors}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Colors
                      </span>
                      <b>{motorsItems[detailItem].motorsSpecification[0].color}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Seats
                      </span>
                      <b>{motorsItems[detailItem].motorsSpecification[0].seats}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Fuel Type
                      </span>
                      <b>{motorsItems[detailItem].motorsSpecification[0].fuel}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Horse Power
                      </span>
                      <b>{motorsItems[detailItem].motorsSpecification[0].horsePower}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Body Condition
                      </span>
                      <b>{motorsItems[detailItem].motorsSpecification[0].bodyCondition}</b>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='DetailsListItem DBlock'>
                  <h6>Key Features</h6>
                  <ul className='ListDetailsUl DBlock'>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Managed by
                      </span>
                      <b>{motorsItems[detailItem].keyFutures[0].managedBy}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Seller
                      </span>
                      <b>{motorsItems[detailItem].keyFutures[0].seller}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Body Type
                      </span>
                      <b>{motorsItems[detailItem].keyFutures[0].body}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Transmission
                      </span>
                      <b>{motorsItems[detailItem].keyFutures[0].transmission}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Model Year
                      </span>
                      <b>{motorsItems[detailItem].keyFutures[0].ModelYear}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Mileage
                      </span>
                      <b>{motorsItems[detailItem].keyFutures[0].Mileage}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Size of car
                      </span>
                      <b>{motorsItems[detailItem].keyFutures[0].size}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Mechanical Condition
                      </span>
                      <b>{motorsItems[detailItem].keyFutures[0].mechanicalCondition}</b>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='DetailsListItem DBlock'>
                  <h6>Additional Features</h6>
                  <ul className='ListDetailsUl DBlock'>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Leather Seats
                      </span>
                      <b>
                        <img src='/assets/svg/TickMark.svg' alt='' />
                      </b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Bluetooh System
                      </span>
                      <b>
                        <img src='/assets/svg/TickMark.svg' alt='' />
                      </b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Sunroof
                      </span>
                      <b>
                        <img src='/assets/svg/TickMark.svg' alt='' />
                      </b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Spoiler
                      </span>
                      <b>
                        <img src='/assets/svg/TickMark.svg' alt='' />
                      </b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Premiun Paints
                      </span>
                      <b>
                        <img src='/assets/svg/TickMark.svg' alt='' />
                      </b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Parking Sensor
                      </span>
                      <b>
                        <img src='/assets/svg/TickMark.svg' alt='' />
                      </b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Air Conditioning
                      </span>
                      <b>
                        <img src='/assets/svg/TickMark.svg' alt='' />
                      </b>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='DetailsListItem DBlock'>
                  <h6>Available Time</h6>
                  <ul className='ListDetailsUl DBlock'>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' /> Availability
                      </span>
                      <b>{motorsItems[detailItem].morning}</b>
                    </li>
                    <li>
                      <span></span>
                      <b>{motorsItems[detailItem].afternoon}</b>
                    </li>
                    <li>
                      <span></span>
                      <b>{motorsItems[detailItem].evening}</b>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='Description DBlock DBlock'>
            <h5>Description</h5>
            <div className='ExpandText DBlock'>
              <ShowMoreText
                lines={3}
                more='View full description'
                less='View less description'
                onClick={executeOnClick}
                expanded={false}
                height={280}
              >
                {motorsItems[detailItem].description.map((desc, index) => (
                  <p key={index}>{desc.text}</p>
                ))}
              </ShowMoreText>
            </div>
          </div>
          <div className='LocationDiv DBlock'>
            <h6>Location</h6>
            <h5>
              <img src='/assets/svg/LocIcon.svg' alt='' /> {motorsItems[detailItem].location}
            </h5>
            <div className='row'>
              <div className='col-lg-8'>
                <div className='LocationIframe DBlock'>
                  <Iframe
                    url={motorsItems[detailItem].locationUrl}
                    id='myId'
                    className='myClassname'
                    frameborder='0'
                    allowfullscreen=''
                    aria-hidden='false'
                    tabIndex='0'
                    position='relative'
                  />
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='RelatedLocDiv DBlock'>
                  <p>Nearest Station</p>
                  <ul className='LocUl'>
                    {motorsItems[detailItem].station.map((station, index) => (
                      <li key={index}>{station}</li>
                    ))}
                  </ul>
                  <p>Zone </p>
                  <ul className='LocUl'>
                    {motorsItems[detailItem].zone.map((zone, index) => (
                      <li key={index}>{zone}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Emailer Modal */}
          <div
            className='modal fade EmailerModal'
            id='staticBackdropEmail'
            data-backdrop='static'
            data-keyboard='false'
            tabIndex='-1'
            aria-labelledby='staticBackdropEmailLabel'
            aria-hidden='true'
          >
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title' id='staticBackdropEmailLabel'>
                    Email Seller
                  </h5>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div className='modal-body'>
                  <form action='' className='form'>
                    <div className='form-row'>
                      <div className='col-lg-3 mb-3'>
                        <label htmlFor=''>Full Name:*</label>
                      </div>
                      <div className='col-lg-9 mb-3'>
                        <input type='text' placeholder='Name' name='' required />
                      </div>
                      <div className='col-lg-3 mb-3'>
                        <label htmlFor=''>Message</label>
                      </div>
                      <div className='col-lg-9 mb-3'>
                        <textarea name='' placeholder='Message' required></textarea>
                      </div>
                      <div className='col-lg-3 mb-3'>
                        <label htmlFor=''>Telephone:</label>
                      </div>
                      <div className='col-lg-9 mb-3'>
                        <input type='text' placeholder='Telephone' name='' required />
                      </div>
                      <div className='col-lg-3 mb-3'>
                        <label htmlFor=''>Email</label>
                      </div>
                      <div className='col-lg-9 mb-3'>
                        <input type='text' placeholder='example@example. Ae' name='' required />
                      </div>
                    </div>
                    <div className='form-row my-3 text-center justify-content-center'>
                      <button className='EmailBtn' type='submit'>
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='NotFound DBlock'>
          <h3>Opps Your Items is not found!</h3>
        </div>
      )}
    </React.Fragment>
  );
}

export default MainDetailsComponent;
