import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MainComponent } from './MainComponent';
import '../Mobile.scss';

export class Classified extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mainCom: false,
      category: subCategory,
      breadCrumb: [],
    };
  }

  handleCategory = (id, text) => {
    const filterCategory = this.state.category.find((list) => list.id === id);
    let joined = this.state.breadCrumb.concat(text);
    this.setState({
      breadCrumb: joined,
    });
    if (filterCategory.sub_menu) {
      this.setState({
        category: filterCategory.sub_menu,
      });
    } else {
      this.setState({
        mainCom: true,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className='ClassifiedWrapper DBlock'>
          {this.state.mainCom ? (
            <React.Fragment>
              <MainComponent breadCrumb={this.state.breadCrumb} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='TopBar DFlex'>
                <Link to='/'>
                  <i className='fas fa-long-arrow-alt-left icon'></i>
                </Link>
                <p className='mx-auto'>Classified</p>
              </div>
              <div className='ClassifiedList DBlock'>
                <ul className='ClassifiedUl DBlock'>
                  {this.state.category &&
                    this.state.category.map((item, index) => (
                      <li key={index} id={item.id} onClick={() => this.handleCategory(item.id, item.text)}>
                        {item.text} {item.sub_menu ? `(${item.sub_menu.length})` : ''} <i className='fas fa-caret-right icon'></i>
                      </li>
                    ))}
                </ul>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Classified;

const subCategory = [
  {
    id: 1,
    text: 'Baby Items',
    sub_menu: [
      {
        id: 1,
        text: 'Baby Sub Items 1',
      },
      {
        id: 2,
        text: 'Baby Sub Items 2',
      },
    ],
  },
  {
    id: 2,
    text: 'Books',
  },
  {
    id: 3,
    text: 'Business & Industrial',
  },
  {
    id: 4,
    text: 'Camaras & Imaging',
  },
  {
    id: 5,
    text: 'Clothing & Accessories',
  },
  {
    id: 6,
    text: 'Collectibles',
  },
  {
    id: 7,
    text: 'Computers & Networking',
  },
  {
    id: 8,
    text: 'DVD’s & Movies',
  },
  {
    id: 9,
    text: 'Electronics',
  },
  {
    id: 10,
    text: 'Free stuff',
  },
  {
    id: 11,
    text: 'Furniture, Home & Garden',
  },
  {
    id: 12,
    text: 'Gaming',
  },
  {
    id: 13,
    text: 'Home Appliances',
  },
  {
    id: 14,
    text: 'Jewelry & Watches',
  },
  {
    id: 15,
    text: 'Mobile Phones & PDAs',
  },
  {
    id: 16,
    text: 'Music',
  },
  {
    id: 17,
    text: 'Pets',
  },
  {
    id: 18,
    text: 'Sports Equipment',
  },
  {
    id: 19,
    text: 'Stuff Wanted',
  },
  {
    id: 20,
    text: 'Tickets & Vouchers',
  },
  {
    id: 21,
    text: 'Toys',
  },
  {
    id: 22,
    text: 'Misc',
  },
];
