import React from 'react';

const SubCategories = ({ subCategory, subCategoryHandle, anotherSubCat }) => {
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>1</span>
        <h3>
          Choose a Sub Category <small>(Basic Information about your Ad)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <ul className='SubCategoriesUl DBlock'>
          {subCategory !== null &&
            subCategory &&
            subCategory.map((item, index) => (
              <li key={index} data-id={item.id} onClick={() => subCategoryHandle(item)}>
                {item.text} <i className='fas fa-caret-right icon'></i>
              </li>
            ))}
          {anotherSubCat &&
            anotherSubCat.map((item, index) => (
              <li key={index} data-id={item.id} onClick={() => subCategoryHandle(item)}>
                {item.text} <i className='fas fa-caret-right icon'></i>
              </li>
            ))}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default SubCategories;
