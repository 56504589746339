import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MainComponent } from './MainComponent';
import '../Mobile.scss';

export class Properties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mainCom: false,
      category: subCategory,
      breadCrumb: [],
    };
  }

  handleCategory = (id, text) => {
    const filterCategory = this.state.category.find((list) => list.id === id);
    let joined = this.state.breadCrumb.concat(text);
    this.setState({
      breadCrumb: joined,
    });
    if (filterCategory.sub_menu) {
      this.setState({
        category: filterCategory.sub_menu,
      });
    } else {
      this.setState({
        mainCom: true,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className='ClassifiedWrapper DBlock'>
          {this.state.mainCom ? (
            <React.Fragment>
              <MainComponent breadCrumb={this.state.breadCrumb} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='TopBar DFlex'>
                <Link to='/'>
                  <i className='fas fa-long-arrow-alt-left icon'></i>
                </Link>
                <p className='mx-auto'>Properties</p>
              </div>
              <div className='ClassifiedList DBlock'>
                <ul className='ClassifiedUl DBlock'>
                  {this.state.category &&
                    this.state.category.map((item, index) => (
                      <li key={index} id={item.id} onClick={() => this.handleCategory(item.id, item.text)}>
                        {item.text} {item.sub_menu ? `(${item.sub_menu.length})` : ''} <i className='fas fa-caret-right icon'></i>
                      </li>
                    ))}
                </ul>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Properties;

const subCategory = [
  {
    id: 1,
    text: 'Properties for Sale ',
    img: '/assets/svg/babes.svg',
    item: 6,
    sub_menu: [
      {
        id: 1,
        text: 'Residential for Sale',
        img: '/assets/svg/babes.svg',
        sub_menu: [
          {
            id: 1,
            text: 'Villa/House for Sale',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 2,
            text: 'Apartment for Sale',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 3,
            text: 'Land for Sale',
            img: '/assets/svg/babes.svg',
          },
        ],
      },
      {
        id: 2,
        text: 'Commercial for Sale',
        img: '/assets/svg/babes.svg',
        sub_menu: [
          {
            id: 1,
            text: 'Industrial for Sale',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 2,
            text: 'Office for Sale',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 3,
            text: 'Shop for Sale',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 4,
            text: 'Staff Accomm for Sale',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 5,
            text: 'Land for Sale',
            img: '/assets/svg/babes.svg',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    text: 'Auto Accessories & Parts ',
    img: '/assets/svg/babes.svg',
    item: 7,
    sub_menu: [
      {
        id: 1,
        text: 'Residential Unit for Rent',
        img: '/assets/svg/babes.svg',
        sub_menu: [
          {
            id: 1,
            text: 'Villa/House for Rent',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 2,
            text: 'Apartment/Flat for Rent',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 3,
            text: 'Short Term',
            img: '/assets/svg/babes.svg',
          },
        ],
      },
      {
        id: 2,
        text: 'Commercial for Rent',
        img: '/assets/svg/babes.svg',
        sub_menu: [
          {
            id: 1,
            text: 'Industrial for Rent',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 2,
            text: 'Office for Rent',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 3,
            text: 'Shop for Rent',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 4,
            text: 'Staff Accomm for Rent',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 5,
            text: 'Short Term',
            img: '/assets/svg/babes.svg',
          },
        ],
      },
      {
        id: 3,
        text: 'Room for Rent (Flatmates)',
        img: '/assets/svg/babes.svg',
        sub_menu: [
          {
            id: 1,
            text: 'Apartment/Flat for Rent',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 2,
            text: 'Villa/House for Rent',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 3,
            text: 'Short Term',
            img: '/assets/svg/babes.svg',
          },
        ],
      },
    ],
  },
];
