import React from 'react';

const KeyFutures = ({ updateState }) => {
  const BG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>4</span>
        <h3>
          Key Features <small>(Basic Information about your Ad)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Managed by:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Family</option>
                <option value=''>Family 1</option>
                <option value=''>Family 2</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Offered by:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Owner</option>
                <option value=''>Owner 1</option>
                <option value=''>Owner 2</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Body Type:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Coupe</option>
                <option value=''>Coupe 1</option>
                <option value=''>Coupe 2</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Transmission:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Automatic</option>
                <option value=''>Automatic 1</option>
                <option value=''>Automatic 2</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Model Year:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>2020</option>
                <option value=''>2019</option>
                <option value=''>2018</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Kilometers:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>20.000 Miles</option>
                <option value=''> 1</option>
                <option value=''> 2</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Size of Car:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Small</option>
                <option value=''>Medium</option>
                <option value=''>Large</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Mechanical Condition:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Minor Faults, all fixed</option>
                <option value=''>Minor Faults, all fixed 1</option>
                <option value=''>Minor Faults, all fixed 2</option>
              </select>
            </div>
          </div>
        </form>
        <div className='NextBtnDiv DBlock text-center'>
          <button className='NextBtn' name='setComponent' value='AdditionalFuture' onClick={(e) => updateState(e)}>
            Next
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default KeyFutures;
