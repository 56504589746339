import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import './Jobs.scss';
import LeftSidebar from './LeftSidebar';

export default function Jobs() {
  const [seeker] = useState(true);
  return (
    <React.Fragment>
      <div className='JobsCom DBlock position-relative'>
        <div className='CompDiv DFlex'>
          <div className='LeftSideBar DBlock'>
            <LeftSidebar />
          </div>
          <div className='MainComponent DBlock mx-auto'>
            {seeker ? (
              <React.Fragment>
                <div className='JobCheckDiv DBlock text-center'>
                  <h3>
                    Job <span>Seeker</span>
                  </h3>
                  <ul className='JobSerchUl DFlex justify-content-center'>
                    <li>
                      <Link to='/job-search'>
                        <img src='/assets/svg/SearchJob.svg' alt='' /> Search Job
                      </Link>
                    </li>
                    <li>
                      <Link to='/jobs/post-cv'>
                        <img src='/assets/svg/PostCv.svg' alt='' />
                        Post CV
                      </Link>
                    </li>
                    <li>
                      <Link to='/company-search'>
                        <img src='/assets/svg/SearchCom.svg' alt='' />
                        Search Company
                      </Link>
                    </li>
                  </ul>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className='JobCheckDiv DBlock text-center'>
                  <h3>
                    Job <span>Employer</span>
                  </h3>
                  <ul className='JobSerchUl DFlex justify-content-center'>
                    <li>
                      <Link to='/candidate-search'>
                        <img src='/assets/svg/SearchCandidate.svg' alt='' /> Search Candidate
                      </Link>
                    </li>
                    <li>
                      <Link to='/jobs/post-job'>
                        <img src='/assets/svg/PostJob.svg' alt='' />
                        Post Job
                      </Link>
                    </li>
                    <li>
                      <Link to='/jobs/create-company-profile'>
                        <img src='/assets/svg/SearchCandidate.svg' alt='' />
                        Create Company Profile
                      </Link>
                    </li>
                  </ul>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
