import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import GridViewComponent from './GridViewComponent';
import { relatedItems } from './Api';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

const GreenCheckbox = withStyles({
  root: {
    color: '#808080',
    '&$checked': {
      color: '#26326C',
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

function valuetext(value) {
  return `${value}°C`;
}

export default function RightSidebar() {
  const classes = useStyles();
  const [value, setValue] = React.useState([0, 1000]);
  const [valueKM, setValueKM] = React.useState([0, 1000]);
  const [item] = React.useState(relatedItems);
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
    checkedE: false,
    checkedF: false,
    checkedG: false,
    checkedH: false,
    checkedI: false,
    checkedJ: false,
    checkedK: false,
    checkedL: false,
    checkedM: false,
    checkedN: false,
  });

  const handleCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeKM = (event, newValue) => {
    setValueKM(newValue);
  };
  const BG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };

  return (
    <React.Fragment>
      <div className='RightSideDiv DBlock'>
        <div className='RightSideSearch DBlock'>
          <form action='' className='form'>
            <div className='form-row'>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Enter Keywords' name='keyword' />
              </div>
              <div className='col-12 mb-3'>
                <div className={`w-100 ${classes.root}`}>
                  <Typography id='range-slider' gutterBottom>
                    Price Range : <span>1000 - 1000</span>
                  </Typography>
                  <Slider
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay='auto'
                    aria-labelledby='range-slider'
                    getAriaValueText={valuetext}
                  />
                </div>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Manufacturer</option>
                  <option value=''>Manufacturer 1</option>
                  <option value=''>Manufacturer 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Model</option>
                  <option value=''>Model 1</option>
                  <option value=''>Model 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Modal Year</option>
                  <option value=''>Modal Year 1</option>
                  <option value=''>Modal Year 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Doors</option>
                  <option value=''>Doors 1</option>
                  <option value=''>Doors 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Colour</option>
                  <option value=''>Colour 1</option>
                  <option value=''>Colour 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <div className={`w-100 ${classes.root}`}>
                  <Typography id='range-slider' gutterBottom>
                    Mileage Range: <span>1.000.000 - 0 km</span>
                  </Typography>
                  <Slider
                    value={valueKM}
                    onChange={handleChangeKM}
                    valueLabelDisplay='auto'
                    aria-labelledby='range-slider'
                    getAriaValueText={valuetext}
                  />
                </div>
              </div>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Warranty' disabled />
                <FormControlLabel
                  control={<GreenCheckbox checked={state.checkedA} onChange={handleCheckbox} name='checkedA' />}
                  label='Full service record '
                />
                <FormControlLabel
                  control={<GreenCheckbox checked={state.checkedB} onChange={handleCheckbox} name='checkedB' />}
                  label='Under warranty '
                />
              </div>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Location' disabled />
                <FormControlLabel
                  control={<GreenCheckbox checked={state.checkedD} onChange={handleCheckbox} name='checkedD' />}
                  label='All cities '
                />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedE} onChange={handleCheckbox} name='checkedE' />} label='Abu Dhabi' />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedF} onChange={handleCheckbox} name='checkedF' />} label='Ajman' />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedI} onChange={handleCheckbox} name='checkedI' />} label='Al Ain' />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedJ} onChange={handleCheckbox} name='checkedJ' />} label='Fujairah' />
                <FormControlLabel
                  control={<GreenCheckbox checked={state.checkedK} onChange={handleCheckbox} name='checkedK' />}
                  label='Ras al Khaimah'
                />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedL} onChange={handleCheckbox} name='checkedL' />} label='Sharjah' />
                <FormControlLabel
                  control={<GreenCheckbox checked={state.checkedM} onChange={handleCheckbox} name='checkedM' />}
                  label='Umm al Quwair'
                />
              </div>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Area' disabled />
                <input type='text' placeholder='Marina, Dubai' className='CustomInput' />
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Seller </option>
                  <option value=''>Seller 1</option>
                  <option value=''>Seller 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Size of Car' disabled />
                <ul className='SizesofCar DFlex'>
                  <li>
                    <img src='/assets/svg/SmallCar.svg' alt='' />
                    <p>Small</p>
                  </li>
                  <li>
                    <img src='/assets/svg/MediumCar.svg' alt='' />
                    <p>Medium</p>
                  </li>
                  <li>
                    <img src='/assets/svg/LargeCar.svg' alt='' />
                    <p>Large</p>
                  </li>
                </ul>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Condition</option>
                  <option value=''>Condition 1</option>
                  <option value=''>Condition 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Manage By' disabled />
                <ul className='SizesofCar DFlex'>
                  <li>
                    <img src='/assets/svg/Family.svg' alt='' />
                    <p>Family</p>
                  </li>
                  <li>
                    <img src='/assets/svg/Female.svg' alt='' />
                    <p>Female</p>
                  </li>
                  <li>
                    <img src='/assets/svg/Male.svg' alt='' />
                    <p>Male</p>
                  </li>
                  <li>
                    <img src='/assets/svg/Company.svg' alt='' />
                    <p>Company</p>
                  </li>
                </ul>
              </div>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Advanced Search' />
              </div>
            </div>
          </form>
        </div>
        <div className='RelatedSearch DBlock'>
          <h4>Related Search </h4>
          <GridViewComponent item={item} />
        </div>
      </div>
    </React.Fragment>
  );
}
