import React from 'react';

const Additional = () => {
  return (
    <div className='FormInnerDiv DBlock'>
      <h4>Additional</h4>
      <form action=''>
        <div className='form-row'>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Licenses:* </label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <textarea name='' placeholder='Drivers License, Medical Lice…'></textarea>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Additional;
