import React from 'react';
import { Link } from 'react-router-dom';

const FilterComponent = ({ removeFilter }) => {
  const selectBG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };

  return (
    <React.Fragment>
      <div className='FilterDiv DBlock'>
        <div className='TopBar DFlex'>
          <Link to='#!' onClick={removeFilter}>
            <i className='fas fa-long-arrow-alt-left icon'></i>
          </Link>
          <p>Filter</p>
        </div>
        <form action=''>
          <div className='form-row'>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>Dubai</option>
                <option value=''>Sharjah</option>
                <option value=''>USA</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>Sub Category</option>
                <option value=''>Sub Category 2</option>
                <option value=''>Sub Category 3</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>Type</option>
                <option value=''>Type 2</option>
                <option value=''>Type 3</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>Purpose</option>
                <option value=''>Purpose 2</option>
                <option value=''>Purpose 3</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>Variety</option>
                <option value=''>Variety 2</option>
                <option value=''>Variety 3</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>Area / Size</option>
                <option value=''>Area / Size 2</option>
                <option value=''>Area / Size 3</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>No. Bedrooms</option>
                <option value=''>No. Bedrooms 2</option>
                <option value=''>No. Bedrooms 3</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>No. Bathrooms</option>
                <option value=''>No. Bathrooms 2</option>
                <option value=''>No. Bathrooms 3</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>Furniture</option>
                <option value=''>Furniture 2</option>
                <option value=''>Furniture 3</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>Offered by</option>
                <option value=''>Offered by 2</option>
                <option value=''>Offered by 3</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Price (AED)</label>
              <input type='text' name='' placeholder='Price From' />
              <input type='text' name='' placeholder='Price To' />
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Ads posted </label>
              <ul className='FilterInnerUl DFlex justify-content-start'>
                <li>Any time</li>
                <li>Today</li>
                <li>Within 3 Days</li>
                <li>Within 1 Week</li>
                <li>Within 2 Weeks</li>
                <li>Within 1 Months</li>
                <li>Within 2 Months</li>
                <li>Within 6 Months</li>
              </ul>
            </div>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>Neighborhood</option>
                <option value=''>Neighborhood 1</option>
                <option value=''>Neighborhood 2</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Keywords</label>
              <input type='text' name='' placeholder='Search keywords' />
            </div>
          </div>
          <div className='FilterBtnDiv DFlex justify-content-center'>
            <button type='submit'>Search</button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default FilterComponent;
