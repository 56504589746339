import React from 'react';
import ClassifiedAnalyticsList from './ClassifiedAnalyticsList';

const ClassifiedAnalytics = ({ item, type }) => {
  return (
    <React.Fragment>
      {item.map((item, index) => (
        <ClassifiedAnalyticsList item={item} key={index} type={type} index={index} />
      ))}
    </React.Fragment>
  );
};

export default ClassifiedAnalytics;
