import React from 'react';

const KeyFutures = ({ updateState }) => {
  const BG = {
    backgroundImage: 'url(assets/svg/DownArr.svg)',
  };
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>4</span>
        <h3>
          Key Features <small>(Basic Information about your Ad)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Lifestyle: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Family</option>
                <option value=''>Family 1</option>
                <option value=''>Family 2</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Bedroom No:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>5</option>
                <option value=''>6</option>
                <option value=''>7</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Bathroom No:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>8</option>
                <option value=''>10</option>
                <option value=''>12</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Furniture.: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Fully Furnished</option>
                <option value=''>Fully Furnished 1</option>
                <option value=''>Fully Furnished 2</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Parking Lot: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Yes</option>
                <option value=''>No</option>
              </select>
            </div>
          </div>
        </form>
        <div className='NextBtnDiv DBlock text-center'>
          <button className='NextBtn' name='setComponent' value='AdditionalFuture' onClick={(e) => updateState(e)}>
            Next
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default KeyFutures;
