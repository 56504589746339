import React, { Component } from 'react';
import { candidatesItems } from './Api';
import GridViewComponent from './GridViewComponent';
import ListViewComponent from './ListViewComponent';
import SearchForm from './SearchForm';

export class MainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'list',
      items: candidatesItems,
    };
  }
  render() {
    return (
      <React.Fragment>
        <div className='MainComDiv DBlock'>
          <SearchForm />
          <div className='ItemsOuterDiv DBlock'>
            <div className='ItemText DBlock'>
              <ul className='BreadCrumb DFlex'>
                <li>Home </li>
                <li>Jobs</li>
                <li>Job Employer</li>
                <li>Search Candidate</li>
              </ul>
              <p>23,456 Results in Candidates</p>
              <div className='Tabs DFlex'>
                <ul className='FilterTab DFlex justify-content-start w-auto'>
                  <li className='active'>All Ads</li>
                  <li>Recent Ads</li>
                  <li>Popular Ads</li>
                </ul>
                <ol className='GridTab DFlex justify-content-start w-auto' id='ViewTab'>
                  <li className={this.state.view === 'list' ? 'active' : ''} onClick={() => this.setState({ view: 'list' })}>
                    <i className='fas fa-grip-lines'></i>
                  </li>
                  <li className={this.state.view === 'grid' ? 'active' : ''} onClick={() => this.setState({ view: 'grid' })}>
                    <i className='fas fa-th'></i>
                  </li>
                </ol>
              </div>
            </div>
            <div className='ItemDesc DBlock'>
              <div className='ItemsDiv DBlock'>
                <h4>Candidates you must be interested on</h4>
                {(() => {
                  switch (this.state.view) {
                    case 'list':
                      return <ListViewComponent item={this.state.items} type='classified' />;
                    case 'grid':
                      return <GridViewComponent item={this.state.items} col={4} type='classified' />;
                    default:
                      return 'No Component Found!';
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MainComponent;
