import React from 'react';

const KeyFutures = ({
  handleKeyFeature,
  keyLifeStyle,
  keyBathroom,
  keyFurniture,
  keyParkingLot,
  keyBedroom,
  furniture,
  lifeStyle,
  parkingLot,
  bedroom,
  bathroom,
}) => {
  const BG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>4</span>
        <h3>
          Key Features <small>(Basic Information about your Ad)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-12 mb-3'>
              <select name='lifeStyle' style={BG} value={lifeStyle} onChange={(e) => handleKeyFeature(e)} required>
                <option value=''>Lifestyle:*</option>
                {keyLifeStyle.map((lifeStyle, index) => (
                  <option value={lifeStyle.name} key={index}>
                    {lifeStyle.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='bedroom' style={BG} value={bedroom} onChange={(e) => handleKeyFeature(e)} required>
                <option value=''>Bedroom No:*</option>
                {keyBedroom.map((bedroom, index) => (
                  <option value={bedroom.name} key={index}>
                    {bedroom.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='bathroom' style={BG} value={bathroom} onChange={(e) => handleKeyFeature(e)} required>
                <option value=''>Bathroom No:*</option>
                {keyBathroom.map((bathroom, index) => (
                  <option value={bathroom.name} key={index}>
                    {bathroom.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='furniture' style={BG} value={furniture} onChange={(e) => handleKeyFeature(e)} required>
                <option value=''>Furniture:*</option>
                {keyFurniture.map((furniture, index) => (
                  <option value={furniture.name} key={index}>
                    {furniture.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='parkingLot' style={BG} value={parkingLot} onChange={(e) => handleKeyFeature(e)} required>
                <option value=''>Parking Lot:*</option>
                {keyParkingLot.map((parkingLot, index) => (
                  <option value={parkingLot.name} key={index}>
                    {parkingLot.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default KeyFutures;
