import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { CanvasJSChart } from 'canvasjs-react-charts';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import JobsPromotePlan from './JobsPromotePlan';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

let optionsMonth = {
  animationEnabled: true,
  axisX: {
    valueFormatString: 'MMMM',
  },
  data: [
    {
      xValueFormatString: 'MMMM',
      type: 'spline',
      dataPoints: [
        { x: new Date(2017, 0), y: 10 },
        { x: new Date(2017, 1), y: 70 },
        { x: new Date(2017, 2), y: 40 },
        { x: new Date(2017, 3), y: 80 },
      ],
    },
  ],
};

let optionsDay = {
  animationEnabled: true,
  axisX: {
    valueFormatString: 'DDDD',
  },
  data: [
    {
      xValueFormatString: 'DDDD',
      type: 'spline',
      dataPoints: [
        { x: new Date(2017, 0), y: 20 },
        { x: new Date(2017, 1), y: 60 },
        { x: new Date(2017, 2), y: 30 },
        { x: new Date(2017, 3), y: 80 },
      ],
    },
  ],
};

const JobsAnalyticsList = ({ item, index, type }) => {
  const classes = useStyles();
  let [promote, setPromote] = useState(false);
  let [chart, setChart] = useState(optionsDay);
  let [active, setActive] = useState('days');

  const leftArrow = {
    backgroundImage: 'url(assets/svg/LeftArrow.svg)',
  };
  const rightArrow = {
    backgroundImage: 'url(assets/svg/RightArrow.svg)',
  };

  return (
    <div key={index} className='MyAdsListDiv DBlock'>
      <div className='ListViewDiv DBlock'>
        <div id={item.id} className='ListView DFlex'>
          <div className='ListImg DBlock'>
            <div id={`carouselExampleInterval${type}${index}`} className='carousel slide' data-ride='carousel'>
              <div className='carousel-inner'>
                {item.img.map((img, index) => (
                  <div className={index === 0 ? 'carousel-item active' : 'carousel-item'} key={index} data-interval='2000'>
                    <Link to={`/job-search/job-detail/${item.id}`}>
                      <img src={img.images} className='d-block w-100' alt='Item_Image' />
                    </Link>
                  </div>
                ))}
              </div>
              <a className='carousel-control-prev' href={`#carouselExampleInterval${type}${index}`} role='button' data-slide='prev'>
                <span className='carousel-control-prev-icon' style={leftArrow} aria-hidden='true'></span>
                <span className='sr-only'>Previous</span>
              </a>
              <a className='carousel-control-next' href={`#carouselExampleInterval${type}${index}`} role='button' data-slide='next'>
                <span className='carousel-control-next-icon' style={rightArrow} aria-hidden='true'></span>
                <span className='sr-only'>Next</span>
              </a>
            </div>
          </div>
          <div className='ListText DBlock'>
            <div className='TextHeader DFlex'>
              <span>
                <Link to={`/job-search/job-detail/${item.id}`}>{item.title}</Link>
                <small>{item.date}</small>
              </span>
            </div>
            <div className='TextBody DBlock'>
              <ul className='TextBodyUl DBlock'>
                <li>
                  Type Of Employment:
                  <span>{item.employmentType}</span>
                </li>
                <li>
                  Salary:
                  <span>{item.salary}</span>
                </li>
                <li>
                  Field:
                  <span>{item.field}</span>
                </li>
                <li>
                  Location:
                  <span>{item.location}</span>
                </li>
              </ul>
            </div>
            <div className='TextFooter DBlock'>
              <ul className='ListIconUl DFlex w-auto justify-content-end'>
                <li>
                  <a href='#!'>
                    <i className='fas fa-share-alt icon'></i>
                  </a>
                </li>
                <li>
                  <a href='#!'>
                    <i className='far fa-heart icon heart'></i>
                  </a>
                </li>
                <li>
                  <Link className='ViewJob' to={`/job-search/job-detail/${item.id}`}>
                    View Job
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='AccListView DBlock'>
        <div className={`${classes.root} DBlock AccOuterDiv `}>
          <Accordion>
            <div className='AccTitle DFlex'>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                <div className={`${classes.heading} DFlex`}>View More</div>
              </AccordionSummary>
              <p className='Views'>
                1293 <i className='far fa-eye icon ml-1'></i>
              </p>
              <div className='AccBtnDiv DFlex justify-content-center w-auto'>
                <Link to='#!' onClick={() => setPromote(item.id)}>
                  Promote Ad
                </Link>
                <button>
                  <i className='fas fa-pencil-alt icon'></i>
                </button>
                <button>
                  <i className='fas fa-trash-alt icon'></i>
                </button>
              </div>
            </div>
            <AccordionDetails>
              <div className='AccBodyDiv py-3 DBlock table-responsive'>
                <div className='Title DFlex'>
                  <div className='TextDiv DBlock'>
                    <h3>Audeince</h3>
                    <p>This graph takes into account users who have viewed your ads.</p>
                  </div>
                  <div className='BtnsDiv DFlex justify-content-center'>
                    <button
                      onClick={(e) => {
                        setChart((chart = { ...optionsDay, ...{} }));
                        setActive((active = e.target.getAttribute('value')));
                      }}
                      value='days'
                      className={active === 'days' ? 'active' : ''}
                    >
                      Days
                    </button>
                    <button
                      onClick={(e) => {
                        setChart((chart = { ...optionsMonth, ...{} }));
                        setActive((active = e.target.getAttribute('value')));
                      }}
                      value='months'
                      className={active === 'months' ? 'active' : ''}
                    >
                      Month
                    </button>
                  </div>
                </div>
                <div className='Description DBlock'>
                  <CanvasJSChart options={chart} />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <JobsPromotePlan promote={promote} />
        </div>
      </div>
    </div>
  );
};

export default JobsAnalyticsList;
