import React from 'react';
import './App.scss';
import Desktop from './components/desktop/Desktop';
import Mobile from './components/mobile/Mobile';

function App() {
  return (
    <div className='ClaseedWrapper DBlock'>
      <Desktop />
      <Mobile />
    </div>
  );
}

export default App;
