import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { clssifiedTable } from '../../ProfileApi';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClassifiedPromotePlan from './ClassifiedPromotePlan';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const ClassifiedAdsList = ({ item, type, index }) => {
  const classes = useStyles();
  const [promote, setPromote] = useState(false);

  const leftArrow = {
    backgroundImage: 'url(/assets/svg/LeftArrow.svg)',
  };
  const rightArrow = {
    backgroundImage: 'url(/assets/svg/RightArrow.svg)',
  };
  return (
    <React.Fragment>
      <div className='MyAdsListDiv DBlock'>
        <div className='ListViewDiv DBlock'>
          <div id={item} className='ListView DFlex'>
            <div className='ListImg DBlock'>
              <div id={`carouselExampleInterval${type}${index}`} className='carousel slide' data-ride='carousel'>
                <div className='carousel-inner'>
                  {item.img.map((img, index) => (
                    <div className={index === 0 ? 'carousel-item active' : 'carousel-item'} key={index} data-interval='2000'>
                      <Link to={`/classified/detail/${item.id}`}>
                        <img src={img.images} className='d-block w-100' alt='Item_Image' />
                      </Link>
                    </div>
                  ))}
                </div>
                <a className='carousel-control-prev' href={`#carouselExampleInterval${type}${index}`} role='button' data-slide='prev'>
                  <span className='carousel-control-prev-icon' style={leftArrow} aria-hidden='true'></span>
                  <span className='sr-only'>Previous</span>
                </a>
                <a className='carousel-control-next' href={`#carouselExampleInterval${type}${index}`} role='button' data-slide='next'>
                  <span className='carousel-control-next-icon' style={rightArrow} aria-hidden='true'></span>
                  <span className='sr-only'>Next</span>
                </a>
              </div>
            </div>
            <div className='ListText DBlock'>
              <div className='TextHeader DFlex'>
                <span>
                  <Link to={`/classified/detail/${item.id}`}>{item.title}</Link>
                  <small>{item.date}</small>
                </span>
                <strong>AED {item.price}</strong>
              </div>
              <div className='TextBody DBlock'>
                <p>{item.description[0].text}</p>
              </div>
              <div className='TextFooter DFlex'>
                <ol className='ListOl DBlock'>
                  <li>Usage: {item.usage} year</li>
                  <li>
                    <img src='assets/svg/LocIcon.svg' alt='' /> <b>{item.location}</b>
                  </li>
                </ol>
                <ul className='ListIconUl DFlex justify-content-start'>
                  <li>
                    <a href={`mailto:${item.gmail}`}>
                      <i className='far fa-envelope icon'></i>
                    </a>
                  </li>
                  <li>
                    <a href={`tel:${item.call}`}>
                      <i className='fas fa-phone-alt icon'></i>
                    </a>
                  </li>
                  <li>
                    <Link to='#!'>
                      <i className='far fa-heart heart'></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='AccListView DBlock'>
          <div className={`${classes.root} DBlock AccOuterDiv `}>
            <Accordion>
              <div className='AccTitle DFlex'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                  <div className={`${classes.heading} DFlex`}>View More</div>
                </AccordionSummary>
                <p className='Views'>
                  1293 <i className='far fa-eye icon ml-1'></i>
                </p>
                <div className='AccBtnDiv DFlex justify-content-center w-auto'>
                  <Link to='#!' onClick={(e) => setPromote({ promote: true })}>
                    Promote Ad
                  </Link>
                  <button>
                    <i className='fas fa-pencil-alt icon'></i>
                  </button>
                  <button>
                    <i className='fas fa-trash-alt icon'></i>
                  </button>
                </div>
              </div>
              <AccordionDetails>
                <div className='AccBodyDiv DBlock table-responsive'>
                  <table className='table table-borderless'>
                    <thead>
                      <tr>
                        <th scope='col'>Sub Cateogry</th>
                        <th scope='col'>Details</th>
                        <th scope='col'>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clssifiedTable &&
                        clssifiedTable.map((item, index) => (
                          <tr key={index}>
                            <td>{item.subCateogry}</td>
                            <td>{item.detail}</td>
                            <td>{item.status}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </AccordionDetails>
            </Accordion>
            <ClassifiedPromotePlan promote={promote} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClassifiedAdsList;
