import React from 'react';
import { Link } from 'react-router-dom';

export default function LeftSidebar() {
  return (
    <React.Fragment>
      <ul className='LeftSidebar DBlock'>
        <li>
          <Link to='/classified' className='active'>
            <img src='/assets/svg/Classifieds.svg' alt='' />
          </Link>
        </li>
        <li>
          <Link to='/motors'>
            <img src='/assets/svg/Motors.svg' alt='' />
          </Link>
        </li>
        <li>
          <Link to='/jobs'>
            <img src='/assets/svg/Job.svg' alt='' />
          </Link>
        </li>
        <li>
          <Link to='/properties'>
            <img src='/assets/svg/Properties.svg' alt='' />
          </Link>
        </li>
        <li>
          <Link to='/community'>
            <img src='/assets/svg/Community.svg' alt='' />
          </Link>
        </li>
      </ul>
    </React.Fragment>
  );
}
