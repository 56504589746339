import React from 'react';
import LeftSidebar from '../LeftSidebar';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PersonalInfo from './PersonalInfo';
import Summary from './Summary';
import Experience from './Experience';
import Education from './Education';
import Skills from './Skills';
import Additional from './Additional';
import Preview from './Preview';
import UploadImage from './UploadImage';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Personal Info', 'Summary ', 'Experience', 'Education', 'Skills', 'Additional', 'Upload Image', 'Preview'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <PersonalInfo />;
    case 1:
      return <Summary />;
    case 2:
      return <Experience />;
    case 3:
      return <Education />;
    case 4:
      return <Skills />;
    case 5:
      return <Additional />;
    case 6:
      return <UploadImage />;
    case 7:
      return <Preview />;
    default:
      return 'Unknown step';
  }
}

const CreateCV = () => {
  const classes = useStyles();
  const [agree, setAgree] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const steps = getSteps();

  const totalSteps = () => {
    return getSteps().length;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep = isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !completed.has(i)) : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);
    if (completed.size !== totalSteps()) {
      handleNext();
    }
    if (completed.size === 6) {
      setModal({
        modal: true,
      });
    }
    setCompleted(newCompleted);
  };

  function isStepComplete(step) {
    return completed.has(step);
  }
  return (
    <React.Fragment>
      <div className='JobsCom DBlock position-relative'>
        <div className='CompDiv DFlex'>
          <div className='LeftSideBar DBlock'>
            <LeftSidebar />
          </div>
          <div className='MainComponent DBlock mx-auto shadow-none p-0'>
            <div className='CreateCVDiv DBlock'>
              <ul className='BreadCrumbUl DFlex justify-content-start'>
                <li>Home </li>
                <li>Jobs</li>
                <li>Job Seeker</li>
                <li>Create CV</li>
              </ul>
              <div className='CreateArea DBlock'>
                <h3>Create CV</h3>
                <div className='FormSteps DBlock'>
                  <div className={classes.root}>
                    <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const buttonProps = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepButton onClick={handleStep(index)} completed={isStepComplete(index)} {...buttonProps}>
                              {label}
                            </StepButton>
                          </Step>
                        );
                      })}
                    </Stepper>
                    <React.Fragment>
                      {allStepsCompleted() ? (
                        <React.Fragment>
                          <div className={`${classes.instructions} text-center DBlock my-5 Message`}>
                            All steps completed - you&apos;re completed your cv!
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                          <div className='FormBtnDiv DFlex justify-content-center'>
                            <Button disabled={activeStep === 0} onClick={handleBack} className='BackBtn'>
                              Back
                            </Button>
                            {activeStep !== steps.length &&
                              (completed.has(activeStep) ? (
                                <Typography variant='caption' className={classes.completed}>
                                  Step {activeStep + 1} already completed
                                </Typography>
                              ) : (
                                <Button
                                  variant='contained'
                                  className='SubmitBtn'
                                  data-toggle={modal ? 'modal' : ''}
                                  data-target={modal ? '#staticBackdropSubmitCV' : ''}
                                  onClick={handleComplete}
                                >
                                  {(() => {
                                    if (completedSteps() === totalSteps() - 1) {
                                      return 'Submit';
                                    } else if (completedSteps() === totalSteps() - 2) {
                                      return 'Preview';
                                    } else {
                                      return 'Complete Step';
                                    }
                                  })()}
                                </Button>
                              ))}
                          </div>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal Submited --> */}
      <div
        className='modal fade'
        id='staticBackdropSubmitCV'
        data-backdrop='static'
        data-keyboard='false'
        tabIndex='-1'
        aria-labelledby='staticBackdropSubmitCVLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-body'>
              <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
              {!agree ? (
                <React.Fragment>
                  <div className='ModalInnerDiv DBlock'>
                    <div className='ModalText DBlock'>
                      <h6>Let’s stay safe!</h6>
                      <p> Your add will not go live if it is:</p>
                      <ul className='TextUl DBlock text-left'>
                        <li>
                          <span>1</span>Prohibited item or activity that violates UAE law
                        </li>
                        <li>
                          <span>2</span>Wrong category placement
                        </li>
                        <li>
                          <span>3</span>Placed multiple times, or placed in more than one category
                        </li>
                        <li>
                          <span>4</span>Fraudulent or misleading information
                        </li>
                        <li>
                          <span>5</span>For items located outside of UAE
                        </li>
                      </ul>
                      <p>
                        For more information, read our <span>terms and conditions </span>
                      </p>
                    </div>
                    <div className='ModalBtnDiv DFlex justify-content-center'>
                      <button className='ModalBtn' onClick={() => setAgree({ agree: true })}>
                        I agree
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className='ModalInnerDiv DBlock'>
                    <div className='ModalText DBlock'>
                      <img src='/assets/svg/TickCircle.svg' className='Tick' alt='' />
                      <h6>Congratulations!</h6>
                      <p>Your CV has been successfully added!</p>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateCV;
