import React from 'react';

const AddDescription = ({ addDescriptionHandler }) => {
  const [values, setValues] = React.useState({ title: '', description: '' });

  const handleForm = (e) => {
    e.preventDefault();
    addDescriptionHandler({ values });
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>2</span>
        <h3>
          Ad Description <small>(Basic Information about your Ad)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action='' onSubmit={(e) => handleForm(e)} name='setComponent' component='MotorSpecification'>
          <div className='form-row'>
            <div className='col-lg-4 mb-3'>
              <label htmlFor='title'>Ad Title: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <input type='text' name='title' required placeholder='Name + Surname' value={values.title} onChange={(e) => handleChange(e)} />
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor='description'>Ad Description: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <textarea
                name='description'
                id=''
                required
                placeholder='Description'
                value={values.description}
                onChange={(e) => handleChange(e)}
              ></textarea>
            </div>
          </div>
          <div className='NextBtnDiv DBlock text-center'>
            <button className='NextBtn' type='submit'>
              Next
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default AddDescription;
