import React, { useState } from 'react';

const ForgotPassword = () => {
  const [forgot, setForgot] = useState(false);
  let [type, setType] = useState('password');
  const handleType = (e) => {
    switch (type) {
      case 'password':
        return setType((type = 'text'));
      case 'text':
        return setType((type = 'password'));
      default:
        return type;
    }
  };
  return (
    <React.Fragment>
      <div className='SignFormWrapper DBlock'>
        {forgot ? (
          <React.Fragment>
            <div className='SignFormOuterDiv DBlock'>
              <div className='SignFormDiv'>
                <form action='' className='form'>
                  <h3>Enter New Password </h3>
                  <div className='form-row'>
                    <div className='col-12 mb-3'>
                      <input type={type} placeholder='New Password' name='' required />
                      <button className='ShowPassBtn' onClick={(e) => handleType(e)}>
                        <i class='far fa-eye icon'></i>
                      </button>
                    </div>
                    <div className='col-12 mb-3'>
                      <button className='SingUp SignBtn'>Reset Password</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className='SignFormOuterDiv DBlock'>
              <div className='SignFormDiv'>
                <h3>Forgot Password?</h3>
                <p>Enter your email and we will send you a link to create a new password</p>
                <form action='' className='form'>
                  <div className='form-row'>
                    <div className='col-12 mb-3'>
                      <input type='email' placeholder='Enter email' name='' required />
                    </div>
                    <div className='col-12 mb-3'>
                      <button
                        className='SingUp SignBtn'
                        onClick={() => {
                          setForgot({ forgot: true });
                        }}
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;
