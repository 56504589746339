import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';

export default function Footer() {
  return (
    <React.Fragment>
      <footer className='DBlock position-relative'>
        <div className='FooterDiv DFlex position-relative'>
          <div className='Logo DBlock w-auto'>
            <img src='assets/svg/footerLogo.svg' alt='' />
            <p>© Copyright 2020 Claseed.com</p>
          </div>
          <ul className='FooterLink DFlex justify-content-center w-auto'>
            <li>
              <Link to='#'>About</Link>
            </li>
            <li>
              <Link to='#'>Advertising</Link>
            </li>
            <li>
              <Link to='#'>Terms of Use</Link>
            </li>
            <li>
              <Link to='#'>Privacy Policy</Link>
            </li>
            <li>
              <Link to='#'>Help</Link>
            </li>
            <li>
              <Link to='#'>Contact Us</Link>
            </li>
            <li>
              <Link to='#'>Blog</Link>
            </li>
            <li>
              <Link to='#'>Join Us</Link>
            </li>
          </ul>
          <ol className='SocialLink DFlex justify-content-center w-auto'>
            <li>
              <a target='blank' href='https://www.facebook.com/'>
                <i className='fab fa-facebook-f icon'></i>
              </a>
            </li>
            <li>
              <a target='blank' href='https://twitter.com/?lang=en'>
                <i className='fab fa-twitter icon'></i>
              </a>
            </li>
            <li>
              <a target='blank' href='https://www.linkedin.com/'>
                <i className='fab fa-linkedin-in icon'></i>
              </a>
            </li>
            <li>
              <a target='blank' href='https://myaccount.google.com/profile'>
                <i className='fab fa-google-plus-g icon'></i>
              </a>
            </li>
            <li>
              <a target='blank' href='https://youtube.com'>
                <i className='fab fa-youtube icon'></i>
              </a>
            </li>
            <li>
              <a target='blank' href='https://www.instagram.com/?hl=en'>
                <i className='fab fa-instagram icon'></i>
              </a>
            </li>
          </ol>
        </div>
      </footer>
    </React.Fragment>
  );
}
