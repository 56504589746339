import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MainComponent } from './MainComponent';
import '../Mobile.scss';

export class Motors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mainCom: false,
      category: subCategory,
      breadCrumb: [],
    };
  }

  handleCategory = (id, text) => {
    const filterCategory = this.state.category.find((list) => list.id === id);
    let joined = this.state.breadCrumb.concat(text);
    this.setState({
      breadCrumb: joined,
    });
    if (filterCategory.sub_menu) {
      this.setState({
        category: filterCategory.sub_menu,
      });
    } else {
      this.setState({
        mainCom: true,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className='ClassifiedWrapper DBlock'>
          {this.state.mainCom ? (
            <React.Fragment>
              <MainComponent breadCrumb={this.state.breadCrumb} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='TopBar DFlex'>
                <Link to='/'>
                  <i className='fas fa-long-arrow-alt-left icon'></i>
                </Link>
                <p className='mx-auto'>Motors</p>
              </div>
              <div className='ClassifiedList DBlock'>
                <ul className='ClassifiedUl DBlock'>
                  {this.state.category &&
                    this.state.category.map((item, index) => (
                      <li key={index} id={item.id} onClick={() => this.handleCategory(item.id, item.text)}>
                        {item.text} {item.sub_menu ? `(${item.sub_menu.length})` : ''} <i className='fas fa-caret-right icon'></i>
                      </li>
                    ))}
                </ul>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Motors;

const subCategory = [
  {
    id: 1,
    text: 'Used Cars for Sale',
    img: '/assets/svg/babes.svg',
    item: 6,
    sub_menu: [
      {
        id: 1,
        text: 'Acura',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Alfa Romeo',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Audi',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 4,
        text: 'BAIC',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 5,
        text: 'BMW',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 6,
        text: 'Bentley',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 7,
        text: 'Bizzarrini',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 8,
        text: 'Borgward',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 9,
        text: 'Brilliance',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 10,
        text: 'Bufori',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 11,
        text: 'Bugatti',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 12,
        text: 'Buick',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 13,
        text: 'CMC',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 14,
        text: 'Cadillac',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 15,
        text: 'Caterham',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 16,
        text: 'Changan',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 17,
        text: 'Chery',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 18,
        text: 'Chevrolet',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 19,
        text: 'Chrysler',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 20,
        text: 'Citroen',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 21,
        text: 'Daewoo',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 22,
        text: 'Daihatsu',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 23,
        text: 'Datsun',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 24,
        text: 'DeLorean',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 25,
        text: 'Dodge',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 26,
        text: 'Equus',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 27,
        text: 'Ferrari',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 28,
        text: 'Fiat',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 29,
        text: 'Fisker',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 30,
        text: 'Force',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 31,
        text: 'Ford',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 32,
        text: 'Foton',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 33,
        text: 'GAC Gonow',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 34,
        text: 'GMC',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 35,
        text: 'Geely',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 36,
        text: 'Grand Tiger',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 37,
        text: 'Gumpert',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 38,
        text: 'Honda',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 39,
        text: 'Hummer',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 40,
        text: 'Hyundai',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 41,
        text: 'Infiniti',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 42,
        text: 'Isuzu',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 43,
        text: 'JAC',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 44,
        text: 'JMC',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 45,
        text: 'Jaguar',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 46,
        text: 'Jeep',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 47,
        text: 'Jinbei',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 48,
        text: 'KTM',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 49,
        text: 'Kia',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 50,
        text: 'Koenigsegg',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 51,
        text: 'Lada',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 52,
        text: 'Lamborghini',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 53,
        text: 'Lancia',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 54,
        text: 'Land Rover',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 55,
        text: 'Lexus',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 56,
        text: 'Lincoln',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 57,
        text: 'Lotus',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 58,
        text: 'Luxgen',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 59,
        text: 'MG',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 60,
        text: 'MINI',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 61,
        text: 'Mahindra',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 62,
        text: 'Maserati',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 63,
        text: 'Maybach',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 64,
        text: 'Mazda',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 65,
        text: 'McLaren',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 66,
        text: 'Mercedes-Benz',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 67,
        text: 'Mercury',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 68,
        text: 'Milan',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 69,
        text: 'Mitsubishi',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 70,
        text: 'Morgan',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 71,
        text: 'Nissan',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 72,
        text: 'Opel',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 73,
        text: 'Oullim',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 74,
        text: 'Pagani',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 75,
        text: 'Peugeot',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 76,
        text: 'Pontiac',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 77,
        text: 'Porsche',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 78,
        text: 'Proton',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 79,
        text: 'Renault',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 80,
        text: 'Rolls Royce',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 81,
        text: 'Rover',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 82,
        text: 'Saab',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 83,
        text: 'Seat',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 84,
        text: 'Shenlong/Sunlong',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 85,
        text: 'Skoda',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 86,
        text: 'Smart',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 87,
        text: 'Speranza',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 88,
        text: 'Sang Yong',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 89,
        text: 'Subaru',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 90,
        text: 'Suzuki',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 91,
        text: 'TATA',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 92,
        text: 'Tesla',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 93,
        text: 'Toyota',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 94,
        text: 'UAZ',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 95,
        text: 'Volkswagen',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 96,
        text: 'Volvo',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 97,
        text: 'W Motors',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 98,
        text: 'Wiesmann',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 99,
        text: 'Others',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 2,
    text: 'Auto Accessories & Parts ',
    img: '/assets/svg/babes.svg',
    item: 7,
    sub_menu: [
      {
        id: 1,
        text: 'Motors',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Boats',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Motorcycle',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Others',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 3,
    text: 'Boats',
    img: '/assets/svg/babes.svg',
    item: 13,
    sub_menu: [
      {
        id: 1,
        text: 'Motorboats',
        img: '/assets/svg/babes.svg',
        sub_menu: [
          {
            id: 1,
            text: 'Fishing Boat',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 2,
            text: 'Outboard Dayboat',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 3,
            text: 'Pontoon/House Boat',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 4,
            text: 'Racing Boat',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 5,
            text: 'Sleeper/Mini Yacht',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 6,
            text: 'Wakeboarding/Ski Boat',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 7,
            text: 'Yacht',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 8,
            text: 'Others',
            img: '/assets/svg/babes.svg',
          },
        ],
      },
      {
        id: 2,
        text: 'Row/Paddle Boats',
        img: '/assets/svg/babes.svg',
        sub_menu: [
          {
            id: 1,
            text: 'Canoe/Row Boat',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 2,
            text: 'Paddle Boat',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 3,
            text: 'Catamaran',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 4,
            text: 'Cruiser/Day Sailor',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 5,
            text: 'DhowVoilier',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 6,
            text: 'Dinghy',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 7,
            text: 'Racer',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 8,
            text: 'Sailing Yacht',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 9,
            text: 'Others',
            img: '/assets/svg/babes.svg',
          },
        ],
      },
      {
        id: 3,
        text: 'Sailboats',
        img: '/assets/svg/babes.svg',
        sub_menu: [
          {
            id: 1,
            text: 'Catamaran',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 2,
            text: 'Cruiser/Day Sailor',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 3,
            text: 'DhowVoilier',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 4,
            text: 'Dinghy',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 5,
            text: 'Racer',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 6,
            text: 'Sailing Yacht',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 7,
            text: 'Others',
            img: '/assets/svg/babes.svg',
          },
        ],
      },
    ],
  },
  {
    id: 4,
    text: 'Heavy Vehicles',
    img: '/assets/svg/babes.svg',
    item: 11,
    sub_menu: [
      {
        id: 1,
        text: 'Buses',
        img: '/assets/svg/babes.svg',
        sub_menu: [
          {
            id: 1,
            text: 'City Bus',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 2,
            text: 'Coach',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 3,
            text: 'Coaster',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 4,
            text: 'Others',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 5,
            text: 'School Bus',
            img: '/assets/svg/babes.svg',
          },
        ],
      },
      {
        id: 2,
        text: 'Cranes',
        img: '/assets/svg/babes.svg',
        sub_menu: [
          {
            id: 1,
            text: 'All Terrain Cranes',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 2,
            text: 'Tower Cranes',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 3,
            text: 'Tractor Cranes',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 4,
            text: 'Truck Loading',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 5,
            text: 'Other',
            img: '/assets/svg/babes.svg',
          },
        ],
      },
      {
        id: 3,
        text: 'Forklifts',
        img: '/assets/svg/babes.svg',
        sub_menu: [
          {
            id: 1,
            text: 'All Terrain',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 2,
            text: 'Diesel',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 3,
            text: 'Electric',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 4,
            text: 'Others',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 5,
            text: 'Side Loader',
            img: '/assets/svg/babes.svg',
          },
        ],
      },
      {
        id: 4,
        text: 'Other Heavy Vehicles',
        img: '/assets/svg/babes.svg',
        sub_menu: [
          {
            id: 1,
            text: 'Agriculture Vehicles',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 2,
            text: 'RVs & Motorhomes',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 3,
            text: 'Site Dumpers',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 4,
            text: 'Sweepers',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 5,
            text: 'Tractors',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 6,
            text: 'Winches',
            img: '/assets/svg/babes.svg',
          },
        ],
      },
      {
        id: 5,
        text: 'Parts & Engines',
        img: '/assets/svg/babes.svg',
        sub_menu: [
          {
            id: 1,
            text: 'Bus Parts',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 2,
            text: 'Crane Parts',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 3,
            text: 'Forklift Parts',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 4,
            text: 'Other Parts',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 5,
            text: 'Tanker Parts',
            img: '/assets/svg/babes.svg',
          },
        ],
      },
      {
        id: 6,
        text: 'Tankers',
        img: '/assets/svg/babes.svg',
        sub_menu: [
          {
            id: 1,
            text: 'Fiberglass Tank',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 2,
            text: 'Fuel Tank',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 3,
            text: 'Stainless Steel Tank',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 4,
            text: 'Water Tank',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 5,
            text: 'Others',
            img: '/assets/svg/babes.svg',
          },
        ],
      },
      {
        id: 7,
        text: 'Trailers',
        img: '/assets/svg/babes.svg',
        sub_menu: [
          {
            id: 1,
            text: 'B Double',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 2,
            text: 'Curtain Sider',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 3,
            text: 'Dog Trailer',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 4,
            text: 'Dolly Trailer',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 5,
            text: 'Drop Decker',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 6,
            text: 'Extendable Trailer',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 7,
            text: 'Flat Deck',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 8,
            text: 'Low Loader',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 9,
            text: 'Refrigerated Trailer',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 10,
            text: 'Tipper',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 11,
            text: 'Others',
            img: '/assets/svg/babes.svg',
          },
        ],
      },
      {
        id: 8,
        text: 'Trucks',
        img: '/assets/svg/babes.svg',
        sub_menu: [
          {
            id: 1,
            text: 'ab-Chassis',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 2,
            text: 'Cherry Picker',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 3,
            text: 'Crane Truck',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 4,
            text: 'Curtain Sider',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 5,
            text: 'Dual Cab',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 6,
            text: 'Fire Truck',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 7,
            text: 'Prime Mover',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 8,
            text: 'Refrigerated Truck',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 9,
            text: 'Service Vehicle',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 10,
            text: 'Tipper',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 11,
            text: 'Tow & Tilt',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 12,
            text: 'Wrecking',
            img: '/assets/svg/babes.svg',
          },
          {
            id: 13,
            text: 'Others',
            img: '/assets/svg/babes.svg',
          },
        ],
      },
    ],
  },
  {
    id: 5,
    text: 'Motorcycles',
    img: '/assets/svg/babes.svg',
    item: 11,
    sub_menu: [
      {
        id: 1,
        text: 'Accessories',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Adventure / Touring',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'CafÈ racer',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 4,
        text: 'Cruiser / Chopper',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 5,
        text: 'Karting',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 6,
        text: 'Mo-Ped',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 7,
        text: 'Off-Road',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 8,
        text: 'Sport Bike',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 9,
        text: 'Standard / Commuter',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 10,
        text: 'Trailer',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 11,
        text: 'Trike',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 12,
        text: 'Others',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 6,
    text: 'Number Plates ',
    img: '/assets/svg/babes.svg',
    item: 7,
    sub_menu: [
      {
        id: 1,
        text: 'Private car',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Motorcycle',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 7,
    text: 'Wanted',
    img: '/assets/svg/babes.svg',
    item: 7,
  },
];
