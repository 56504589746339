import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import GridViewComponent from './GridViewComponent';
import { relatedItems } from './Api';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

const GreenCheckbox = withStyles({
  root: {
    color: '#808080',
    '&$checked': {
      color: '#26326C',
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

function valuetext(value) {
  return `${value}°C`;
}

export default function RightSidebar() {
  const classes = useStyles();
  const [value, setValue] = React.useState([0, 100]);
  const [item] = React.useState(relatedItems);
  const [state, setState] = React.useState({
    checkedA: false,
    checkedD: false,
    checkedE: false,
    checkedF: false,
    checkedG: false,
    checkedH: false,
    checkedI: false,
    checkedJ: false,
    checkedK: false,
    checkedL: false,
    checkedM: false,
    checkedN: false,
  });

  const handleCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const BG = {
    backgroundImage: 'url(assets/svg/DownArr.svg)',
  };

  return (
    <React.Fragment>
      <div className='RightSideDiv DBlock'>
        <div className='RightSideSearch DBlock'>
          <form action='' className='form'>
            <div className='form-row'>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Enter Keywords' name='keyword' />
              </div>
              <div className='col-12 mb-3'>
                <div className={`w-100 ${classes.root}`}>
                  <Typography id='range-slider' gutterBottom>
                    Price Range : <span>1000 - 1000</span>
                  </Typography>
                  <Slider
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay='auto'
                    aria-labelledby='range-slider'
                    getAriaValueText={valuetext}
                  />
                </div>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Baby Items</option>
                  <option value=''>Baby Items 1</option>
                  <option value=''>Baby Items 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Books</option>
                  <option value=''>Books 1</option>
                  <option value=''>Books 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Business & Industrial</option>
                  <option value=''>Business & Industrial 1</option>
                  <option value=''>Business & Industrial 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Cameras & Imaging</option>
                  <option value=''>Cameras & Imaging 1</option>
                  <option value=''>Cameras & Imaging 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Clothing & Accessories</option>
                  <option value=''>Clothing & Accessories 1</option>
                  <option value=''>Clothing & Accessories 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Collectibles</option>
                  <option value=''>Collectibles 1</option>
                  <option value=''>Collectibles 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Computers & Networking</option>
                  <option value=''>Computers & Networking 1</option>
                  <option value=''>Computers & Networking 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>DVDs & Movies</option>
                  <option value=''>DVDs & Movies 1</option>
                  <option value=''>DVDs & Movies 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Electronics</option>
                  <option value=''>Electronics 1</option>
                  <option value=''>Electronics 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Free Stuff</option>
                  <option value=''>Free Stuff 1</option>
                  <option value=''>Free Stuff 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Furniture, Home & Garden</option>
                  <option value=''>Furniture, Home & Garden 1</option>
                  <option value=''>Furniture, Home & Garden 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Gaming</option>
                  <option value=''>Gaming 1</option>
                  <option value=''>Gaming 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Home Appliances</option>
                  <option value=''>Home Appliances 1</option>
                  <option value=''>Home Appliances 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Jewelry & Watches</option>
                  <option value=''>Jewelry & Watches 1</option>
                  <option value=''>Jewelry & Watches 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Gaming</option>
                  <option value=''>Gaming 1</option>
                  <option value=''>Gaming 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Home Appliances</option>
                  <option value=''>Home Appliances 1</option>
                  <option value=''>Home Appliances 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Mobile Phones & PDAs</option>
                  <option value=''>Mobile Phones & PDAs 1</option>
                  <option value=''>Mobile Phones & PDAs 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Music</option>
                  <option value=''>Music 1</option>
                  <option value=''>Music 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Pets</option>
                  <option value=''>Pets 1</option>
                  <option value=''>Pets 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Sports Equipment</option>
                  <option value=''>Sports Equipment 1</option>
                  <option value=''>Sports Equipment 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Stuff Wanted</option>
                  <option value=''>Stuff Wanted 1</option>
                  <option value=''>Stuff Wanted 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Tickets & Vouchers</option>
                  <option value=''>Tickets & Vouchers 1</option>
                  <option value=''>Tickets & Vouchers 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Toys</option>
                  <option value=''>Toys 1</option>
                  <option value=''>Toys 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Misk</option>
                  <option value=''>Misk 1</option>
                  <option value=''>Misk 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Endorsed' disabled />
                <FormControlLabel
                  control={<GreenCheckbox checked={state.checkedA} onChange={handleCheckbox} name='checkedA' />}
                  label='Under warranty'
                />
              </div>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Location' disabled />
                <FormControlLabel
                  control={<GreenCheckbox checked={state.checkedD} onChange={handleCheckbox} name='checkedD' />}
                  label='All cities '
                />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedE} onChange={handleCheckbox} name='checkedE' />} label='Abu Dhabi' />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedF} onChange={handleCheckbox} name='checkedF' />} label='Ajman' />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedI} onChange={handleCheckbox} name='checkedI' />} label='Al Ain' />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedJ} onChange={handleCheckbox} name='checkedJ' />} label='Fujairah' />
                <FormControlLabel
                  control={<GreenCheckbox checked={state.checkedK} onChange={handleCheckbox} name='checkedK' />}
                  label='Ras al Khaimah'
                />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedL} onChange={handleCheckbox} name='checkedL' />} label='Sharjah' />
                <FormControlLabel
                  control={<GreenCheckbox checked={state.checkedM} onChange={handleCheckbox} name='checkedM' />}
                  label='Umm al Quwair'
                />
              </div>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Location' disabled />
                <input type='text' placeholder='Marina, Dubai' className='CustomInput' />
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Seller </option>
                  <option value=''>Seller 1</option>
                  <option value=''>Seller 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Condition</option>
                  <option value=''>Condition 1</option>
                  <option value=''>Condition 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Usage</option>
                  <option value=''>Usage 1</option>
                  <option value=''>Usage 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Age</option>
                  <option value=''>Age 1</option>
                  <option value=''>Age 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Advanced Search' />
              </div>
            </div>
          </form>
        </div>
        <div className='RelatedSearch DBlock'>
          <h4>Related Search </h4>
          <GridViewComponent item={item} />
        </div>
      </div>
    </React.Fragment>
  );
}
