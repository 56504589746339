import React from 'react';
import Header from './header/Header';
import Footer from './footer/Footer';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Classified from './classified/Classified';
import Home from './home/Home';
import ClassifiedDetails from './classified/classifiedDetails/ClassifiedDetails';
import Motors from './motors/Motors';
import MotorsDetails from './motors/motorsDetails/MotorsDetails';
import PlaceYourAds from './placeYourAds/PlaceYourAds';
import SignIn from './signForms/SignIn';
import SignUp from './signForms/SignUp';
import ForgotPassword from './signForms/ForgotPassword';
import Properties from './properties/Properties';
import PropertiesDetails from './properties/propertiesDetails/PropertiesDetails';
import Community from './community/Community';
import CommunityDetails from './community/communityDetails/CommunityDetails';
import Jobs from './jobs/Jobs';
import SearchJobs from './jobs/searchJob/SearchJobs';
import SearchJobsDetails from './jobs/searchJob/searchJobDetails/SearchJobsDetails';
import CreateCV from './jobs/createCV/CreateCV';
import PostCV from './jobs/postCV/PostCV';
import SearchCompany from './company/companySearch/SearchCompany';
import CompanyDetails from './company/companySearch/companyDetails/CompanyDetails';
import Candidate from './candidate/Candidate';
import CandidateDetails from './candidate/candidateDetails/CandidateDetails';
import CreateJob from './jobs/createJob/CreateJob';
import PostJob from './jobs/postJob/PostJob';
import CreateCompany from './company/createCompany/CreateCompany';
import Profile from './profile/Profile';
import EditProfile from './profile/editProfile/EditProfile';
import MyAds from './profile/myAds/MyAds';
import LikedAds from './profile/likedAds/LikedAds';
import DataAnalytics from './profile/adsAnalytics/DataAnalytics';
import { ScrollContext } from 'react-router-scroll-4';

export default function Desktop() {
  return (
    <Router>
      <ScrollContext>
        <div className='DesktopWrapper DFlex flex-column d-none d-lg-block'>
          <Header />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/classified' exact component={Classified} />
            <Route path='/classified/detail/:id' component={ClassifiedDetails} />
            <Route path='/motors' exact component={Motors} />
            <Route path='/motors/detail/:id' component={MotorsDetails} />
            <Route path='/properties' exact component={Properties} />
            <Route path='/properties/detail/:id' component={PropertiesDetails} />
            <Route path='/community' exact component={Community} />
            <Route path='/community/detail/:id' component={CommunityDetails} />
            <Route path='/jobs' exact component={Jobs} />
            <Route path='/job-search' exact component={SearchJobs} />
            <Route path='/job-search/job-detail/:id' exact component={SearchJobsDetails} />
            <Route path='/jobs/create-cv' exact component={CreateCV} />
            <Route path='/jobs/post-cv' exact component={PostCV} />
            <Route path='/jobs/create-job' exact component={CreateJob} />
            <Route path='/jobs/post-job' exact component={PostJob} />
            <Route path='/jobs/create-company-profile' exact component={CreateCompany} />
            <Route path='/company-search' exact component={SearchCompany} />
            <Route path='/company-search/company-detail/:id' exact component={CompanyDetails} />
            <Route path='/candidate-search' exact component={Candidate} />
            <Route path='/candidate-search/candidate-detail/:id' exact component={CandidateDetails} />
            <Route path='/place-your-ad' exact component={PlaceYourAds} />
            <Route path='/sign-in' exact component={SignIn} />
            <Route path='/profile' exact component={Profile} />
            <Route path='/edit-profile' exact component={EditProfile} />
            <Route path='/my-ads' exact component={MyAds} />
            <Route path='/liked-ads' exact component={LikedAds} />
            <Route path='/ads-analytics' exact component={DataAnalytics} />
            <Route path='/sign-up' exact component={SignUp} />
            <Route path='/forgot-password' exact component={ForgotPassword} />
          </Switch>
          <Footer />
        </div>
      </ScrollContext>
    </Router>
  );
}
