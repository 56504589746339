import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import GridViewComponent from './GridViewComponent';
import { relatedItems } from './Api';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

const GreenCheckbox = withStyles({
  root: {
    color: '#808080',
    '&$checked': {
      color: '#26326C',
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

function valuetext(value) {
  return `${value}°C`;
}

export default function RightSidebar() {
  const classes = useStyles();
  const [value, setValue] = React.useState([0, 100]);
  const [item] = React.useState(relatedItems);
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
    checkedE: false,
    checkedF: false,
    checkedG: false,
    checkedH: false,
    checkedI: false,
    checkedJ: false,
    checkedK: false,
  });

  const handleCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const BG = {
    backgroundImage: 'url(assets/svg/DownArr.svg)',
  };

  return (
    <React.Fragment>
      <div className='RightSideDiv DBlock'>
        <div className='RightSideSearch DBlock'>
          <form action='' className='form'>
            <div className='form-row'>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Enter Keywords' name='keyword' />
              </div>
              <div className='col-12 mb-3'>
                <div className={`w-100 ${classes.root}`}>
                  <Typography id='range-slider' gutterBottom>
                    Price Range : <span>1000 - 1000</span>
                  </Typography>
                  <Slider
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay='auto'
                    aria-labelledby='range-slider'
                    getAriaValueText={valuetext}
                  />
                </div>
              </div>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Job Type' disabled />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedA} onChange={handleCheckbox} name='checkedA' />} label='Permanent ' />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedB} onChange={handleCheckbox} name='checkedB' />} label='Temporary' />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedC} onChange={handleCheckbox} name='checkedC' />} label='Full-Time' />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedD} onChange={handleCheckbox} name='checkedD' />} label='Part-Time' />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedE} onChange={handleCheckbox} name='checkedE' />} label='Freelance' />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedF} onChange={handleCheckbox} name='checkedF' />} label='Internship' />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedG} onChange={handleCheckbox} name='checkedG' />} label='Remote Job' />
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Experience</option>
                  <option value=''>Experience 1</option>
                  <option value=''>Experience 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Education</option>
                  <option value=''>Education 1</option>
                  <option value=''>Education 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Location' disabled />
                <FormControlLabel
                  control={<GreenCheckbox checked={state.checkedD} onChange={handleCheckbox} name='checkedD' />}
                  label='All cities '
                />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedH} onChange={handleCheckbox} name='checkedH' />} label='Abu Dhabi' />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedI} onChange={handleCheckbox} name='checkedI' />} label='Ajman' />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedJ} onChange={handleCheckbox} name='checkedJ' />} label='Al Ain' />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedK} onChange={handleCheckbox} name='checkedK' />} label='Fujairah' />
                <FormControlLabel
                  control={<GreenCheckbox checked={state.checkedK} onChange={handleCheckbox} name='checkedK' />}
                  label='Ras al Khaimah'
                />
                <FormControlLabel control={<GreenCheckbox checked={state.checkedL} onChange={handleCheckbox} name='checkedL' />} label='Sharjah' />
                <FormControlLabel
                  control={<GreenCheckbox checked={state.checkedM} onChange={handleCheckbox} name='checkedM' />}
                  label='Umm al Quwair'
                />
              </div>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Area' disabled />
                <input type='text' placeholder='Marina, Dubai' className='CustomInput' />
              </div>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Career Level' disabled='' />
                <ul className='SizesofCar DFlex'>
                  <li>
                    <img src='/assets/svg/Internship.svg' alt='' />
                    <p>Intern</p>
                  </li>
                  <li>
                    <img src='/assets/svg/Begginer.svg' alt='' />
                    <p>Biguiner</p>
                  </li>
                  <li>
                    <img src='/assets/svg/MidLevel.svg' alt='' />
                    <p>Mid</p>
                  </li>
                  <li>
                    <img src='/assets/svg/Senior.svg' alt='' />
                    <p>Senior</p>
                  </li>
                </ul>
              </div>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Advanced Search' />
              </div>
            </div>
          </form>
        </div>
        <div className='RelatedSearch DBlock'>
          <h4>Related Search </h4>
          <GridViewComponent item={item} />
        </div>
      </div>
    </React.Fragment>
  );
}
