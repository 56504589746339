import React, { Component } from 'react';
import ClassifiedAds from './adsComponents/classifiedAdsComponents/ClassifiedAds';
import CommunityAds from './adsComponents/communityAdsComponents/CommunityAds';
import MotorsAds from './adsComponents/motorAdsComponents/MotorsAds';
import PropertiesAds from './adsComponents/propertiesAdsComponents/PropertiesAds';
import { Redirect, Link } from 'react-router-dom';
import { getClassifiedCategory } from '../../ApiData/GetData';

export class PlaceYourAds extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: [],
      setComponent: null,
      categoryId: null,
    };
  }

  updatedState() {
    switch (this.state.setComponent) {
      case 'Classifieds':
        return <ClassifiedAds categoryId={this.state.categoryId} />;
      case 'Motors':
        return <MotorsAds categoryId={this.state.categoryId} />;
      case 'Jobs':
        return <Redirect to='/jobs' />;
      case 'Properties':
        return <PropertiesAds categoryId={this.state.categoryId} />;
      case 'Community':
        return <CommunityAds categoryId={this.state.categoryId} />;
      default:
        return this.state.setComponent;
    }
  }

  handleState(id, name) {
    this.setState({ setComponent: `${name}`, categoryId: id });
  }

  async componentDidMount() {
    const res = await getClassifiedCategory();
    if (res) {
      this.setState({ category: res.data.data });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className='PlaceYourAdsArea DBlock'>
          <div className='container'>
            <div className='PlaceYourAdsDiv DBlock'>
              <div className='Title DBlock h-auto'>
                <h2>Hello, Start Seeding your Ad</h2>
                <p>Select the category that best suits your Ad</p>
                <form action='' className='form'>
                  <div className='form-group'>
                    <input type='text' placeholder='I am Looking for...' name='' id='' required />
                    <button className='SubBtn' type='submit'>
                      <i className='fas fa-search icon'></i>
                    </button>
                  </div>
                </form>
              </div>
              <div className='Desc DBlock h-auto mb-5'>
                <ul className='DescUl DFlex position-relative'>
                  <li className='DropDown'>
                    <Link to='/place-your-ad/classified-ad'>
                      <img src='assets/svg/Classifieds.svg' alt='' />
                      <p>Classifieds</p>
                    </Link>
                  </li>
                  <li className='DropDown'>
                    <Link to='/place-your-ad/motors-ad'>
                      <img src='assets/svg/Motors.svg' alt='' />
                      <p>Motors</p>
                    </Link>
                  </li>
                  <li className='DropDown'>
                    <Link to='/jobs'>
                      <img src='assets/svg/Job.svg' alt='' />
                      <p>Jobs</p>
                    </Link>
                  </li>
                  <li className='DropDown'>
                    <Link to='/place-your-ad/properties-ad'>
                      <img src='assets/svg/Properties.svg' alt='' />
                      <p>Properties </p>
                    </Link>
                  </li>
                  <li className='DropDown'>
                    <Link to='/place-your-ad/community-ad'>
                      <img src='assets/svg/Community.svg' alt='' />
                      <p>Community</p>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PlaceYourAds;
