export const classifiedItems = [
  {
    id: 1,
    img: [
      { id: 1, images: '/assets/images/Claseed1.png' },
      { id: 2, images: '/assets/images/Claseed1.png' },
      { id: 3, images: '/assets/images/Claseed1.png' },
      { id: 4, images: '/assets/images/Claseed1.png' },
    ],
    title: 'Macbook Pro',
    date: '10/12/2018',
    price: 500.0,
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    age: '6 months',
    condition: 'Perfect inside and out',
    warranty: 'Does not apply',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
  {
    id: 2,
    img: [
      { id: 1, images: '/assets/images/Claseed2.png' },
      { id: 2, images: '/assets/images/Claseed2.png' },
      { id: 3, images: '/assets/images/Claseed2.png' },
      { id: 4, images: '/assets/images/Claseed2.png' },
    ],
    title: 'Stroller & Car Seats',
    date: '10/12/2018',
    price: 500.0,
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    age: '6 months',
    condition: 'Perfect inside and out',
    warranty: 'Does not apply',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
];

export const communityItems = [
  {
    id: 1,
    img: [
      { id: 1, images: '/assets/images/Community1.png' },
      { id: 2, images: '/assets/images/Community1.png' },
      { id: 3, images: '/assets/images/Community1.png' },
      { id: 4, images: '/assets/images/Community1.png' },
    ],
    title: 'Body Builder',
    date: '10/12/2018',
    price: 500.0,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
  {
    id: 2,
    img: [
      { id: 1, images: '/assets/images/Community2.png' },
      { id: 2, images: '/assets/images/Community2.png' },
      { id: 3, images: '/assets/images/Community2.png' },
      { id: 4, images: '/assets/images/Community2.png' },
    ],
    title: 'Andersen Builder',
    date: '10/12/2018',
    price: 500.0,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
];

export const motorsItems = [
  {
    id: 1,
    img: [
      { id: 1, images: '/assets/images/Motors1.png' },
      { id: 2, images: '/assets/images/Motors1.png' },
      { id: 3, images: '/assets/images/Motors1.png' },
      { id: 4, images: '/assets/images/Motors1.png' },
    ],
    title: 'Jeep - Jeep',
    date: '10/12/2018',
    price: '1,080,500',
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    motorsSpecification: [
      {
        manufacturer: 'Toyota',
        model: 'Sedan',
        doors: 4,
        color: 'red',
        seats: 5,
        fuel: 'Pertrol',
        horsePower: '1000 bhp',
        bodyCondition: 'Wear & tear, all repaired',
      },
    ],
    keyFutures: [
      {
        managedBy: 'Family',
        seller: 'Seller Type',
        body: 'Coupe',
        transmission: 'Automatic',
        ModelYear: 2020,
        Mileage: '20.000 Miles',
        size: 'Medium',
        mechanicalCondition: 'Mechanical Condition',
      },
    ],
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    description: [
      {
        id: 1,
        text:
          'Astonishing and elegantly designed townhouse located in the most sought-after gates residential gated community in Townsquare by Nshama.…',
      },

      {
        id: 2,
        text: 'Located Marina, Dubai, is close to the wide range of shops, bars and restaurants. Travel Links include and numerous bus routes.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
  },
  {
    id: 2,
    img: [
      { id: 1, images: '/assets/images/Motors2.png' },
      { id: 2, images: '/assets/images/Motors2.png' },
      { id: 3, images: '/assets/images/Motors2.png' },
      { id: 4, images: '/assets/images/Motors2.png' },
    ],
    title: 'Luxury Cars',
    date: '10/12/2018',
    price: '1,080,500',
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    motorsSpecification: [
      {
        manufacturer: 'Toyota',
        model: 'Sedan',
        doors: 4,
        color: 'red',
        seats: 5,
        fuel: 'Pertrol',
        horsePower: '1000 bhp',
        bodyCondition: 'Wear & tear, all repaired',
      },
    ],
    keyFutures: [
      {
        managedBy: 'Family',
        seller: 'Seller Type',
        body: 'Coupe',
        transmission: 'Automatic',
        ModelYear: 2020,
        Mileage: '20.000 Miles',
        size: 'Medium',
        mechanicalCondition: 'Mechanical Condition',
      },
    ],
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    description: [
      {
        id: 1,
        text:
          'Astonishing and elegantly designed townhouse located in the most sought-after gates residential gated community in Townsquare by Nshama.…',
      },

      {
        id: 2,
        text: 'Located Marina, Dubai, is close to the wide range of shops, bars and restaurants. Travel Links include and numerous bus routes.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
  },
];

export const jobsItems = [
  {
    id: 1,
    img: [
      { id: 1, images: '/assets/images/Job1.png' },
      { id: 2, images: '/assets/images/Job1.png' },
      { id: 3, images: '/assets/images/Job1.png' },
      { id: 4, images: '/assets/images/Job1.png' },
    ],
    title: 'Senior Digital Specialist (UI/UX)',
    name: 'Company Name',
    date: '10/12/2018',
    Amount: 500.0,
    location: 'Country, City',
    gmail: 'email@email.ae',
    call: '+971 00 00 000 000',
    employmentType: 'Full Time',
    contract: 'Permanent',
    salary: '5.000.00 AED',
    field: 'Design',
    startDate: '00/00/0000',
    salaryType: 'Per Month',
    description: [
      {
        id: 1,
        text:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat...',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
    ],
  },
  {
    id: 2,
    img: [
      { id: 1, images: '/assets/images/Job2.png' },
      { id: 2, images: '/assets/images/Job2.png' },
      { id: 3, images: '/assets/images/Job2.png' },
      { id: 4, images: '/assets/images/Job2.png' },
    ],
    title: 'Senior Digital Specialist (UI/UX)',
    name: 'Company Name',
    date: '10/12/2018',
    Amount: 500.0,
    location: 'Country, City',
    gmail: 'email@email.ae',
    call: '+971 00 00 000 000',
    employmentType: 'Full Time',
    contract: 'Permanent',
    salary: '5.000.00 AED',
    field: 'Design',
    startDate: '00/00/0000',
    salaryType: 'Per Month',
    description: [
      {
        id: 1,
        text:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat...',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
    ],
  },
];

export const propertiesItems = [
  {
    id: 1,
    img: [
      { id: 1, images: '/assets/images/Properties1.png' },
      { id: 2, images: '/assets/images/Properties1.png' },
      { id: 3, images: '/assets/images/Properties1.png' },
      { id: 4, images: '/assets/images/Properties1.png' },
    ],
    title: 'Mini Cooper',
    date: '10/12/2018',
    price: '1,080,500',
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    propertiesSpecification: [
      {
        propertyType: 'Residential',
        variety: 'Villa',
        area: ' 2,650 (SqFt)',
        offeredBy: 'Owner',
      },
    ],
    keyFutures: [
      {
        lifeStyle: 'Family',
        bedrooms: 4,
        bathrooms: 5,
        furniture: 'Fully Furnished',
        parking: 'Yes',
      },
    ],
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    description: [
      {
        id: 1,
        text:
          'Astonishing and elegantly designed townhouse located in the most sought-after gates residential gated community in Townsquare by Nshama.…',
      },
      {
        id: 2,
        text: 'Located Marina, Dubai, is close to the wide range of shops, bars and restaurants. Travel Links include and numerous bus routes.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
  },
  {
    id: 2,
    img: [
      { id: 1, images: '/assets/images/Properties2.png' },
      { id: 2, images: '/assets/images/Properties2.png' },
      { id: 3, images: '/assets/images/Properties2.png' },
      { id: 4, images: '/assets/images/Properties2.png' },
    ],
    title: '2 Bedroom Flat For Sale',
    date: '10/12/2018',
    price: '1,080,500',
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    propertiesSpecification: [
      {
        propertyType: 'Residential',
        variety: 'Villa',
        area: ' 2,650 (SqFt)',
        offeredBy: 'Owner',
      },
    ],
    keyFutures: [
      {
        lifeStyle: 'Family',
        bedrooms: 4,
        bathrooms: 5,
        furniture: 'Fully Furnished',
        parking: 'Yes',
      },
    ],
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    description: [
      {
        id: 1,
        text:
          'Astonishing and elegantly designed townhouse located in the most sought-after gates residential gated community in Townsquare by Nshama.…',
      },
      {
        id: 2,
        text: 'Located Marina, Dubai, is close to the wide range of shops, bars and restaurants. Travel Links include and numerous bus routes.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
  },
];

export const candidatesItems = [
  {
    id: 1,
    img: [
      { id: 1, images: '/assets/images/Candidate1.jpg' },
      { id: 2, images: '/assets/images/Candidate1.jpg' },
      { id: 3, images: '/assets/images/Candidate1.jpg' },
      { id: 4, images: '/assets/images/Candidate1.jpg' },
    ],
    name: 'Josh Andersan',
    title: 'Senior Digital Specialist (UI/UX)',
    date: '10/12/2018',
    age: 25,
    gender: 'Male',
    nationality: 'American',
    residence: 'Dubai',
    email: 'info@gmail.com',
    linkedin: 'info@gmail.com',
    totalExperience: 10,
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea comm odo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    experience: [
      {
        id: 1,
        jobTitle: 'Digital Specialist',
        company: 'Google',
        date: '9/8/2020',
      },
    ],
    education: [
      {
        id: 1,
        degree: 'Master’s Degree',
        school: 'Murdoch University Dubai',
        date: '9/8/2020',
      },
    ],
    license: ['Drivers License', 'Drivers License'],
    skills: ['Adobe Photoshop', 'Adobe Illustratur', 'Adobe XD', 'Dreamviewer', 'Adobe Acrobode'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
  {
    id: 2,
    img: [
      { id: 1, images: '/assets/images/Candidate2.jpg' },
      { id: 2, images: '/assets/images/Candidate2.jpg' },
      { id: 3, images: '/assets/images/Candidate2.jpg' },
      { id: 4, images: '/assets/images/Candidate2.jpg' },
    ],
    name: 'Johshwa',
    title: 'Senior junior Designer (UI/UX)',
    date: '10/12/2018',
    age: 25,
    gender: 'Male',
    nationality: 'American',
    residence: 'Dubai',
    email: 'info@gmail.com',
    linkedin: 'info@gmail.com',
    totalExperience: 10,
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea comm odo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    experience: [
      {
        jobTitle: 'junior Designer',
        company: 'Google',
        date: '9/8/2020',
      },
    ],
    education: [
      {
        degree: 'Master’s Degree',
        school: 'Murdoch University Dubai',
        date: '9/8/2020',
      },
    ],
    license: ['Drivers License', 'Drivers License'],
    skills: ['Adobe Photoshop', 'Adobe Illustratur', 'Adobe XD', 'Dreamviewer', 'Adobe Acrobode'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
];

export const companyItems = [
  {
    id: 1,
    img: [
      { id: 1, images: '/assets/images/Company1.png' },
      { id: 2, images: '/assets/images/Company1.png' },
      { id: 3, images: '/assets/images/Company1.png' },
      { id: 4, images: '/assets/images/Company1.png' },
    ],
    coverImg: '/assets/images/CoverImg.png',
    title: 'Citi Bank',
    date: '10/12/2018',
    industry: 'Banking',
    tradeLicence: 123456789,
    established: 1234,
    Employees: 10.0,
    call: '+91 (31) 476 4998',
    location: 'Dubai, UAE',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    description: [
      {
        id: 1,
        text:
          "Citi's mission is to serve as a trusted partner to our clients by responsibly providing financial services that enable growth and economic progress. Our core activities are safeguarding assets, lending money, making payments and accessing the capital markets on behalf of our clients. ",
      },
      {
        id: 2,
        text:
          "We have 200 years of experience helping our clients meet the world's toughest challenges and embrace its greatest opportunities. We are Citi, the global bank – an institution connecting millions of people across hundreds of countries and cities.",
      },
      {
        id: 3,
        text: 'For information on Citi’s commitment to privacy, visit on.citi/privacy.',
      },
    ],
  },
  {
    id: 2,
    img: [
      { id: 1, images: '/assets/images/Company2.png' },
      { id: 2, images: '/assets/images/Company2.png' },
      { id: 3, images: '/assets/images/Company2.png' },
      { id: 4, images: '/assets/images/Company2.png' },
    ],
    coverImg: '/assets/images/CoverImg.png',
    title: 'National Bank',
    date: '10/12/2018',
    industry: 'Banking',
    tradeLicence: 123456789,
    established: 1234,
    Employees: 10.0,
    call: '+91 (31) 476 4998',
    location: 'Dubai, UAE',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    description: [
      {
        id: 1,
        text:
          "Citi's mission is to serve as a trusted partner to our clients by responsibly providing financial services that enable growth and economic progress. Our core activities are safeguarding assets, lending money, making payments and accessing the capital markets on behalf of our clients. ",
      },
      {
        id: 2,
        text:
          "We have 200 years of experience helping our clients meet the world's toughest challenges and embrace its greatest opportunities. We are Citi, the global bank – an institution connecting millions of people across hundreds of countries and cities.",
      },
      {
        id: 3,
        text: 'For information on Citi’s commitment to privacy, visit on.citi/privacy.',
      },
    ],
  },
];

export const jobTable = [
  {
    id: 1,
    name: 'Alice Brown',
    experience: '2 years',
    nationality: 'Indian',
    age: 34,
    gender: 'Male',
    phone: '00 971 00 00 000 000',
  },
  {
    id: 2,
    name: 'John Ibrahim',
    experience: '3 years',
    nationality: 'USA',
    age: 34,
    gender: 'Male',
    phone: '00 971 00 00 000 000',
  },
  {
    id: 3,
    name: 'David',
    experience: '4 years',
    nationality: 'UK',
    age: 34,
    gender: 'Male',
    phone: '00 971 00 00 000 000',
  },
  {
    id: 4,
    name: 'Alice Brown',
    experience: '2 years',
    nationality: 'Indian',
    age: 34,
    gender: 'Male',
    phone: '00 971 00 00 000 000',
  },
  {
    id: 5,
    name: 'John Ibrahim',
    experience: '3 years',
    nationality: 'USA',
    age: 34,
    gender: 'Male',
    phone: '00 971 00 00 000 000',
  },
  {
    id: 6,
    name: 'David',
    experience: '4 years',
    nationality: 'UK',
    age: 34,
    gender: 'Male',
    phone: '00 971 00 00 000 000',
  },
  {
    id: 7,
    name: 'Alice Brown',
    experience: '2 years',
    nationality: 'Indian',
    age: 34,
    gender: 'Male',
    phone: '00 971 00 00 000 000',
  },
  {
    id: 8,
    name: 'John Ibrahim',
    experience: '3 years',
    nationality: 'USA',
    age: 34,
    gender: 'Male',
    phone: '00 971 00 00 000 000',
  },
  {
    id: 9,
    name: 'David',
    experience: '4 years',
    nationality: 'UK',
    age: 34,
    gender: 'Male',
    phone: '00 971 00 00 000 000',
  },
  {
    id: 10,
    name: 'Alice Brown',
    experience: '2 years',
    nationality: 'Indian',
    age: 34,
    gender: 'Male',
    phone: '00 971 00 00 000 000',
  },
  {
    id: 11,
    name: 'John Ibrahim',
    experience: '3 years',
    nationality: 'USA',
    age: 34,
    gender: 'Male',
    phone: '00 971 00 00 000 000',
  },
  {
    id: 12,
    name: 'David',
    experience: '4 years',
    nationality: 'UK',
    age: 34,
    gender: 'Male',
    phone: '00 971 00 00 000 000',
  },
];

export const clssifiedTable = [
  {
    id: 1,
    subCateogry: 'Baby Items',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 2,
    subCateogry: 'Man Items',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 3,
    subCateogry: 'Women Items',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 4,
    subCateogry: 'Baby Items',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 5,
    subCateogry: 'Man Items',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 6,
    subCateogry: 'Women Items',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
];

export const motorTable = [
  {
    id: 1,
    subCateogry: 'Used Car For Sale',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 2,
    subCateogry: 'Man Car',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 3,
    subCateogry: 'Women Car',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 4,
    subCateogry: 'Used Car For Sale',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 5,
    subCateogry: 'Man Car',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 6,
    subCateogry: 'Women Car',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
];

export const propertiesTable = [
  {
    id: 1,
    subCateogry: 'Residence For Sale',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 2,
    subCateogry: 'Commerical for sale',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 3,
    subCateogry: 'Both for sale',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 4,
    subCateogry: 'Residence For Sale',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 5,
    subCateogry: 'Commerical for sale',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 6,
    subCateogry: 'Both for sale',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
];

export const communityTable = [
  {
    id: 1,
    subCateogry: 'Services',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 2,
    subCateogry: 'Service',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 3,
    subCateogry: 'Services',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 4,
    subCateogry: 'Services',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 5,
    subCateogry: 'Service',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
  {
    id: 6,
    subCateogry: 'Services',
    detail: 'Detail about the add, few words only is fine',
    status: 'Indian',
  },
];
