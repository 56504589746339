import { Link } from 'react-router-dom';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { jobTable } from '../../ProfileApi';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const JobsAds = ({ item, type }) => {
  const classes = useStyles();

  const leftArrow = {
    backgroundImage: 'url(assets/svg/LeftArrow.svg)',
  };
  const rightArrow = {
    backgroundImage: 'url(assets/svg/RightArrow.svg)',
  };
  return (
    <React.Fragment>
      {item.map((item, index) => (
        <div key={index} className='MyAdsListDiv DBlock'>
          <div className='ListViewDiv DBlock'>
            <div id={item.id} className='ListView DFlex'>
              <div className='ListImg DBlock'>
                <div id={`carouselExampleInterval${type}${index}`} className='carousel slide' data-ride='carousel'>
                  <div className='carousel-inner'>
                    {item.img.map((img, index) => (
                      <div className={index === 0 ? 'carousel-item active' : 'carousel-item'} key={index} data-interval='2000'>
                        <Link to={`/job-search/job-detail/${item.id}`}>
                          <img src={img.images} className='d-block w-100' alt='Item_Image' />
                        </Link>
                      </div>
                    ))}
                  </div>
                  <a className='carousel-control-prev' href={`#carouselExampleInterval${type}${index}`} role='button' data-slide='prev'>
                    <span className='carousel-control-prev-icon' style={leftArrow} aria-hidden='true'></span>
                    <span className='sr-only'>Previous</span>
                  </a>
                  <a className='carousel-control-next' href={`#carouselExampleInterval${type}${index}`} role='button' data-slide='next'>
                    <span className='carousel-control-next-icon' style={rightArrow} aria-hidden='true'></span>
                    <span className='sr-only'>Next</span>
                  </a>
                </div>
              </div>
              <div className='ListText DBlock'>
                <div className='TextHeader DFlex'>
                  <span>
                    <Link to={`/job-search/job-detail/${item.id}`}>{item.title}</Link>
                    <small>{item.date}</small>
                  </span>
                </div>
                <div className='TextBody DBlock'>
                  <ul className='TextBodyUl DBlock'>
                    <li>
                      Type Of Employment:
                      <span>{item.employmentType}</span>
                    </li>
                    <li>
                      Salary:
                      <span>{item.salary}</span>
                    </li>
                    <li>
                      Field:
                      <span>{item.field}</span>
                    </li>
                    <li>
                      Location:
                      <span>{item.location}</span>
                    </li>
                  </ul>
                </div>
                <div className='TextFooter DBlock'>
                  <ul className='ListIconUl DFlex w-auto justify-content-end'>
                    <li>
                      <a href='#!'>
                        <i className='fas fa-share-alt icon'></i>
                      </a>
                    </li>
                    <li>
                      <a href='#!'>
                        <i className='far fa-heart icon heart'></i>
                      </a>
                    </li>
                    <li>
                      <Link className='ViewJob' to={`/job-search/job-detail/${item.id}`}>
                        View Job
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='AccListView DBlock'>
            <div className={`${classes.root} DBlock AccOuterDiv `}>
              <Accordion>
                <div className='AccTitle DFlex'>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                    <div className={`${classes.heading} DFlex`}>View More</div>
                  </AccordionSummary>
                  <p className='Views'>
                    1293 <i className='far fa-eye icon ml-1'></i>
                  </p>
                  <div className='AccBtnDiv DFlex justify-content-center w-auto'>
                    <Link to='#!'>Promote Ad</Link>
                    <button>
                      <i className='fas fa-pencil-alt icon'></i>
                    </button>
                    <button>
                      <i className='fas fa-trash-alt icon'></i>
                    </button>
                  </div>
                </div>
                <AccordionDetails>
                  <div className='AccBodyDiv DBlock table-responsive'>
                    <table className='table table-borderless'>
                      <thead>
                        <tr>
                          <th scope='col'>Name</th>
                          <th scope='col'>Experience</th>
                          <th scope='col'>Nationality</th>
                          <th scope='col'>Age</th>
                          <th scope='col'>Gender</th>
                          <th scope='col'>Phone Number</th>
                          <th scope='col'>CV</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobTable &&
                          jobTable.map((item, index) => (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>{item.experience}</td>
                              <td>{item.nationality}</td>
                              <td>{item.age}</td>
                              <td>{item.gender}</td>
                              <td>{item.phone}</td>
                              <td>
                                <button id={item.id}>
                                  <i className='fas fa-download'></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default JobsAds;
