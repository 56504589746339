import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import GridViewComponent from './GridViewComponent';
import { relatedItems } from './Api';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

function valuetext(value) {
  return `${value}°C`;
}

export default function RightSidebar() {
  const classes = useStyles();
  const [value, setValue] = React.useState([0, 100]);
  const [item] = React.useState(relatedItems);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const BG = {
    backgroundImage: 'url(assets/svg/DownArr.svg)',
  };

  return (
    <React.Fragment>
      <div className='RightSideDiv DBlock'>
        <div className='RightSideSearch DBlock'>
          <form action='' className='form'>
            <div className='form-row'>
              <div className='col-12 mb-3'>
                <input type='text' placeholder='Enter Keywords' name='keyword' />
              </div>
              <div className='col-12 mb-3'>
                <div className={`w-100 ${classes.root}`}>
                  <Typography id='range-slider' gutterBottom>
                    Price Range : <span>1000 - 1000</span>
                  </Typography>
                  <Slider
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay='auto'
                    aria-labelledby='range-slider'
                    getAriaValueText={valuetext}
                  />
                </div>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Dubai</option>
                  <option value=''>Dubai 1</option>
                  <option value=''>Dubai 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Neighborhood</option>
                  <option value=''>Neighborhood 1</option>
                  <option value=''>Neighborhood 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Sub Category</option>
                  <option value=''>Sub Category 1</option>
                  <option value=''>Sub Category 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>All Category</option>
                  <option value=''>All Category 1</option>
                  <option value=''>All Category 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Clothing & Accessories</option>
                  <option value=''>Clothing & Accessories 1</option>
                  <option value=''>Clothing & Accessories 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Ad Posted</option>
                  <option value=''>Ad Posted 1</option>
                  <option value=''>Ad Posted 2</option>
                </select>
              </div>
              <div className='col-12 mb-3'>
                <select name='' style={BG}>
                  <option value=''>Offered by</option>
                  <option value=''>Offered by 1</option>
                  <option value=''>Offered by 2</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div className='RelatedSearch DBlock'>
          <h4>Related Search </h4>
          <GridViewComponent item={item} />
        </div>
      </div>
    </React.Fragment>
  );
}
