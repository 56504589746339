export const previewApi = [
  {
    id: 1,
    img: [
      { id: 1, images: '/assets/images/Motors1.png' },
      { id: 2, images: '/assets/images/Motors1.png' },
      { id: 3, images: '/assets/images/Motors1.png' },
      { id: 4, images: '/assets/images/Motors1.png' },
    ],
    title: 'Jeep - Jeep',
    date: '10/12/2018',
    price: '1,080,500',
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    motorsSpecification: [
      {
        manufacturer: 'Toyota',
        model: 'Sedan',
        doors: 4,
        color: 'red',
        seats: 5,
        fuel: 'Pertrol',
        horsePower: '1000 bhp',
        bodyCondition: 'Wear & tear, all repaired',
      },
    ],
    keyFutures: [
      {
        managedBy: 'Family',
        seller: 'Seller Type',
        body: 'Coupe',
        transmission: 'Automatic',
        ModelYear: 2020,
        Mileage: '20.000 Miles',
        size: 'Medium',
        mechanicalCondition: 'Mechanical Condition',
      },
    ],
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    description: [
      {
        id: 1,
        text:
          'Astonishing and elegantly designed townhouse located in the most sought-after gates residential gated community in Townsquare by Nshama.…',
      },

      {
        id: 2,
        text: 'Located Marina, Dubai, is close to the wide range of shops, bars and restaurants. Travel Links include and numerous bus routes.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
  },
];
