import React, { useState } from 'react';
import ClassifiedAds from './ClassifiedAds';
import CommunityAds from './CommunityAds';
import JobsAds from './JobsAds';
import MotorsAds from './MotorsAds';
import PropertiesAds from './PropertiesAds';
import { classifiedItems } from '../ProfileApi';
import { communityItems } from '../ProfileApi';
import { motorsItems } from '../ProfileApi';
import { jobsItems } from '../ProfileApi';
import { propertiesItems } from '../ProfileApi';

const MyAds = () => {
  let [adsCategory, setAdsCategory] = useState('job');
  const [classifiedItem, setClassifiedItem] = useState(classifiedItems);
  const [motorsItem, setMotorsItem] = useState(motorsItems);
  const [jobsItem, setJobsItem] = useState(jobsItems);
  const [communityItem, setCommunityItem] = useState(communityItems);
  const [propertiesItem, setPropertiesItem] = useState(propertiesItems);

  const handleAds = (e) => {
    setAdsCategory((adsCategory = e.target.value));
    console.log(adsCategory);
  };

  const previewAds = () => {
    switch (adsCategory) {
      case 'jobs':
        return <JobsAds item={jobsItem} type='jobs' />;
      case 'motors':
        return <MotorsAds item={motorsItem} type='motors' />;
      case 'classifieds':
        return <ClassifiedAds item={classifiedItem} type='classified' />;
      case 'properties':
        return <PropertiesAds item={propertiesItem} type='property' />;
      case 'community':
        return <CommunityAds item={communityItem} type='community' />;
      default:
        return <JobsAds item={jobsItem} type='jobs' />;
    }
  };
  return (
    <React.Fragment>
      <div className='ProfileWrapper DBlock'>
        <div className='SelectCategory DFlex justify-content-start text-center'>
          <label htmlFor='selectAdsCategory'>Category</label>
          <select name='selectAdsCategory' className='bg-transparent' id='selectAdsCategory' onChange={(e) => handleAds(e)}>
            <option value='jobs'>Job Employer</option>
            <option value='classifieds'>Classifieds</option>
            <option value='motors'>Motors</option>
            <option value='properties'>Properties</option>
            <option value='community'>Community</option>
          </select>
        </div>
        <div className='MainProfileDiv DBlock'>{previewAds()}</div>
      </div>
    </React.Fragment>
  );
};

export default MyAds;
