import React, { useState } from 'react';

const MotorsPromotePlan = ({ promote }) => {
  var [planStep, setPlanStep] = useState('first');

  const promotePlan = () => {
    switch (planStep) {
      case 'first':
        return <FirstPlan planStep={planStep} setPlanStep={setPlanStep} />;
      case 'second':
        return <SecondPlan planStep={planStep} setPlanStep={setPlanStep} />;
      case 'third':
        return <ThirdPlan />;
      default:
        return <FirstPlan planStep={planStep} setPlanStep={setPlanStep} />;
    }
  };

  return (
    <React.Fragment>
      {promote ? (
        <React.Fragment>
          <div className='PromoteDiv DBlock'>{promotePlan()}</div>
        </React.Fragment>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default MotorsPromotePlan;

const FirstPlan = ({ planStep, setPlanStep }) => (
  <React.Fragment>
    <div className='row w-100'>
      <div className='col-md-4'>
        <div className='PlanDiv DBlock'>
          <h3>Basic Plan</h3>
          <ul className='PlanUl DBlock'>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
          </ul>
          <button className='PlanBtn' onClick={() => setPlanStep((planStep = 'second'))}>
            100 AED
          </button>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='PlanDiv DBlock'>
          <h3>Advance Plan</h3>
          <ul className='PlanUl DBlock'>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
          </ul>
          <button className='PlanBtn' onClick={() => setPlanStep((planStep = 'second'))}>
            150 AED
          </button>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='PlanDiv DBlock'>
          <h3>Premium Plan</h3>
          <ul className='PlanUl DBlock'>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
          </ul>
          <button className='PlanBtn' onClick={() => setPlanStep((planStep = 'second'))}>
            200 AED
          </button>
        </div>
      </div>
    </div>
  </React.Fragment>
);

const SecondPlan = ({ planStep, setPlanStep }) => (
  <React.Fragment>
    <div className='row w-100'>
      <div className='col-md-8'>
        <div className='PlanForm DBlock'>
          <h3>Choose Payment Method</h3>
          <ul className='PlanFormPayment DFlex'>
            <li className='active'>
              <img src='/assets/images/Visa.png' alt='' />
            </li>
            <li>
              <img src='/assets/images/MasterCard.png' alt='' />
            </li>
            <li>
              <img src='/assets/images/Paypal.png' alt='' />
            </li>
          </ul>
          <form action='' className='form'>
            <div className='form-row'>
              <div className='col-12 mb-3'>
                <label htmlFor=''>Card Number</label>
                <input type='text' placeholder='**** **** **** ****' name='' />
              </div>
              <div className='col-12 col-lg-6 mb-3'>
                <label htmlFor=''>Expire date</label>
                <input type='text' placeholder='MM / YY' name='' />
              </div>
              <div className='col-12 col-lg-6 mb-3'>
                <label htmlFor=''>CVV</label>
                <input type='text' placeholder='CODE' name='' />
              </div>
              <div className='col-12 mb-3'>
                <button className='PlanSubBtn' type='submit' onClick={() => setPlanStep((planStep = 'third'))}>
                  Promote Ad
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='PlanDiv DBlock'>
          <h3>Basic Plan</h3>
          <ul className='PlanUl DBlock'>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
          </ul>
          <button className='PlanBtn'>100 AED</button>
        </div>
      </div>
    </div>
  </React.Fragment>
);

const ThirdPlan = () => (
  <React.Fragment>
    <div className='row w-100'>
      <div className='col-md-8'>
        <div className='PlanForm DBlock'>
          <h3>Choose Payment Method</h3>
          <ul className='PlanFormPayment DFlex'>
            <li>
              <img src='/assets/images/Visa.png' alt='' />
            </li>
            <li>
              <img src='/assets/images/MasterCard.png' alt='' />
            </li>
            <li>
              <img src='/assets/images/Paypal.png' alt='' />
            </li>
          </ul>
          <div className='SuccessFullPlanMsg DBlock text-center'>
            <img src='/assets/images/FilledTick.png' alt='' />
            <h6>Congratulations!</h6>
            <p> Your transaction was done successfully, Your Ad has been promoted!</p>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='PlanDiv DBlock'>
          <h3>Basic Plan</h3>
          <ul className='PlanUl DBlock'>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
            <li>Basic Plan</li>
          </ul>
          <button className='PlanBtn'>100 AED</button>
        </div>
      </div>
    </div>
  </React.Fragment>
);
