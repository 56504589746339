import React from 'react';

const UploadImage = () => {
  const BG = {
    backgroundImage: 'url(assets/svg/DownArr.svg)',
  };
  return (
    <React.Fragment>
      <div className='FormInnerDiv DBlock'>
        <h4>Upload Image</h4>
        <form action=''>
          <div className='form-row mb-5'>
            <div className='col-md-5 col-12 mb-3'>
              <label htmlFor=''>Upload your Image:</label>
              <span>(format allowed: jpg - png)</span>
            </div>
            <div className='col-md-7 col-12 mb-3'>
              <div className='UploadImgDiv DBlock'>
                <input type='file' name='uploadImg' className='UploadImg' accept='image/x-png,image/gif,image/jpeg' />
                <label htmlFor='uploadImg' style={BG}>
                  Max Size 1 MB
                </label>
              </div>
            </div>
          </div>
        </form>
        <div className='UploadImgDiv DBlock text-center'>
          <div className='ImgDiv DBlock float-none'>
            <span className='CrossBtn'>
              <i className='far fa-times-circle icon'></i>
            </span>
            <img src='/assets/images/Company1.png' alt='' />
          </div>
          <div className='progress'>
            <span className='CrossBtn'>
              <i className='far fa-times-circle icon'></i>
            </span>
            <div className='progress-bar w-50' role='progressbar' aria-valuenow='25' aria-valuemin='0' aria-valuemax='100'></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UploadImage;
