import React, { Component } from 'react';
import AddDescription from './AddDescription';
import AdditionalFuture from './AdditionalFuture';
import KeyFutures from './KeyFutures';
import MapLocation from './MapLocation';
import MotorSpecification from './MotorSpecification';
import SubCategories from './SubCategories';
import TimeSlots from './TimeSlots';
import UploadImage from './UploadImage';
import WhatsappPermit from './WhatsappPermit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import PreviewComponent from './PreviewComponent';
import { previewApi } from './PreviewApi';
import { Link } from 'react-router-dom';

export class MotorsAds extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setComponent: 'SubCategories',
      subCategory: subCategory,
      additionalFutures: additionalFutures,
      checkedA: false,
      checkedB: false,
      preview: false,
      previewData: previewApi,
      agree: false,
    };
  }

  subCategoryHandle = (id) => {
    this.setState({
      setComponent: 'AddDescription',
    });
  };

  updateState = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  previewForm = (e) => {
    this.setState({
      ...this.state,
      setComponent: null,
      preview: true,
    });
  };

  checkState() {
    switch (this.state.setComponent) {
      case 'SubCategories':
        return <SubCategories subCategory={this.state.subCategory} subCategoryHandle={this.subCategoryHandle} />;
      case 'AddDescription':
        return <AddDescription updateState={this.updateState} />;
      case 'MotorSpecification':
        return <MotorSpecification updateState={this.updateState} />;
      case 'KeyFutures':
        return <KeyFutures updateState={this.updateState} />;
      case 'AdditionalFuture':
        return <AdditionalFuture additionalFutures={this.state.additionalFutures} updateState={this.updateState} />;
      case 'UploadImage':
        return <UploadImage updateState={this.updateState} />;
      case 'MapLocation':
        return <MapLocation updateState={this.updateState} />;
      case 'TimeSlots':
        return <TimeSlots updateState={this.updateState} />;
      case 'WhatsappPermit':
        return <WhatsappPermit updateState={this.updateState} />;
      case 'AdsFeature':
        return (
          <React.Fragment>
            <div className='FormHeading DFlex justify-content-start'>
              <span className='CustomSpan'>10</span>
              <h3>
                <FormControlLabel
                  labelPlacement='start'
                  control={
                    <Switch
                      checked={this.state.checkedA}
                      onChange={(e) => this.setState({ ...this.state, [e.target.name]: e.target.checked })}
                      name='checkedA'
                      color='primary'
                    />
                  }
                  label='Do you want to make your Ad as Feature Ad?'
                />
              </h3>
              <div className='NextBtnDiv DBlock text-center'>
                <button className='NextBtn' name='setComponent' value='TermsAndCondition' onClick={(e) => this.updateState(e)}>
                  Next
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      case 'TermsAndCondition':
        return (
          <React.Fragment>
            <div className='FormHeading DFlex justify-content-start'>
              <span className='CustomSpan'>11</span>
              <h3>
                <FormControlLabel
                  labelPlacement='start'
                  control={
                    <Switch
                      checked={this.state.checkedB}
                      onChange={(e) => this.setState({ ...this.state, [e.target.name]: e.target.checked })}
                      name='checkedB'
                      color='primary'
                    />
                  }
                  label='Agree the Terms and Conditions'
                />
              </h3>
              <div className='NextBtnDiv DBlock text-center'>
                <button className='NextBtn' onClick={this.previewForm}>
                  Preview your Ad
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      default:
        return this.state.setComponent;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className='PlaceAdsWrapper DBlock'>
          <div className='Title DBlock'>
            <h5>Place Your Ad </h5>
            <ul className='BreadCrumb DFlex justify-content-start'>
              <li>Home </li>
              <li>Place Your Ad</li>
              <li>Motors</li>
              <li>Used Cars for Sale</li>
            </ul>
          </div>
          <div className='AdsFormOuterDiv DBlock'>{this.state.setComponent !== null && this.checkState()}</div>
          <div className='AdsFormOuterDiv DBlock'>{this.state.preview && <PreviewComponent previewData={this.state.previewData} />}</div>
          {this.state.preview && (
            <React.Fragment>
              <div className='BtnDiv DFlex justify-content-center'>
                <button className='GoBack' onClick={(e) => this.setState({ setComponent: 'SubCategories', preview: false })}>
                  Go Back
                </button>
                <button className='Submit' data-toggle='modal' data-target='#staticBackdropSubmit'>
                  Submit
                </button>
              </div>
            </React.Fragment>
          )}
          {/* <!-- Modal Submited --> */}
          <div
            className='modal fade'
            id='staticBackdropSubmit'
            data-backdrop='static'
            data-keyboard='false'
            tabIndex='-1'
            aria-labelledby='staticBackdropSubmitLabel'
            aria-hidden='true'
          >
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-body'>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                  {!this.state.agree ? (
                    <React.Fragment>
                      <div className='ModalInnerDiv DBlock'>
                        <div className='ModalText DBlock'>
                          <h6>Let’s stay safe!</h6>
                          <p> Your add will not go live if it is:</p>
                          <ul className='TextUl DBlock text-left'>
                            <li>
                              <span>1</span>Prohibited item or activity that violates UAE law
                            </li>
                            <li>
                              <span>2</span>Wrong category placement
                            </li>
                            <li>
                              <span>3</span>Placed multiple times, or placed in more than one category
                            </li>
                            <li>
                              <span>4</span>Fraudulent or misleading information
                            </li>
                            <li>
                              <span>5</span>For items located outside of UAE
                            </li>
                          </ul>
                          <p>
                            For more information, read our <span>terms and conditions </span>
                          </p>
                        </div>
                        <div className='ModalBtnDiv DFlex justify-content-center'>
                          <button className='ModalBtn' onClick={() => this.setState({ agree: true })}>
                            I agree
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className='ModalInnerDiv DBlock'>
                        <div className='ModalText DBlock'>
                          <h6>Congratulations!</h6>
                          <p>Your Add has been submitted successfully!</p>
                          <img src='/assets/svg/TickCircle.svg' className='Tick' alt='' />
                        </div>
                        <div className='ModalBtnDiv DFlex justify-content-center'>
                          <Link to='#!' className='ModalBtn'>
                            View Ad research
                          </Link>
                          <Link to='#!'>Place another add</Link>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MotorsAds;

const subCategory = [
  {
    id: 1,
    text: 'Used Cars for Sale',
  },
  {
    id: 2,
    text: 'Auto Accessories & Parts',
  },
  {
    id: 3,
    text: 'Boats',
  },
  {
    id: 4,
    text: 'Heavy Vehicles',
  },
  {
    id: 5,
    text: 'Motorcycles',
  },
  {
    id: 6,
    text: 'Number Plates',
  },
  {
    id: 7,
    text: 'Wanted',
  },
];

const additionalFutures = [
  {
    id: 1,
    label: 'Leather Seats',
    value: 'Leather Seats',
  },
  {
    id: 2,
    label: 'Bluetooh System',
    value: 'Bluetooh System',
  },
  {
    id: 3,
    label: 'DVD player',
    value: 'DVD player',
  },
  {
    id: 4,
    label: 'Sunroof',
    value: 'Sunroof',
  },
  {
    id: 5,
    label: 'Spoiler ',
    value: 'Spoiler ',
  },
  {
    id: 6,
    label: 'Keyless Entry ',
    value: 'Keyless Entry ',
  },
  {
    id: 7,
    label: 'Premiun Paints',
    value: 'Premiun Paints',
  },
  {
    id: 8,
    label: 'Parking Sensor',
    value: 'Parking Sensor',
  },
  {
    id: 9,
    label: 'Navigation System',
    value: 'Navigation System',
  },
  {
    id: 10,
    label: 'Air Conditioning',
    value: 'Air Conditioning',
  },
  {
    id: 11,
    label: 'Climate Control',
    value: 'Climate Control',
  },
  {
    id: 12,
    label: 'Premium Sound System',
    value: 'Premium Sound System',
  },
  {
    id: 13,
    label: '4 Wheel Drive',
    value: '4 Wheel Drive',
  },
  {
    id: 14,
    label: 'Brush Guard',
    value: 'Brush Guard',
  },
  {
    id: 15,
    label: 'Heated Seats',
    value: 'Heated Seats',
  },
  {
    id: 16,
    label: 'Alarm/Anti- Theft System',
    value: 'Alarm/Anti- Theft System',
  },
  {
    id: 17,
    label: 'CD player',
    value: 'CD player',
  },
  {
    id: 18,
    label: 'Moonroof',
    value: 'Moonroof',
  },
  {
    id: 19,
    label: 'All Wheel Steering',
    value: 'All Wheel Steering',
  },
  {
    id: 20,
    label: 'Dual Exhaust',
    value: 'Dual Exhaust',
  },
  {
    id: 21,
    label: 'Off-Road Kit',
    value: 'Off-Road Kit',
  },
  {
    id: 22,
    label: 'Anti-Lock Brakes/ABS',
    value: 'Anti-Lock Brakes/ABS',
  },
  {
    id: 23,
    label: 'Front Airbags',
    value: 'Front Airbags',
  },
  {
    id: 24,
    label: 'Performance Tyres',
    value: 'Performance Tyres',
  },
  {
    id: 25,
    label: 'Power Mirrors',
    value: 'Power Mirrors',
  },
  {
    id: 26,
    label: 'Body Kit',
    value: 'Body Kit',
  },
  {
    id: 27,
    label: 'Heat',
    value: 'Heat',
  },
  {
    id: 28,
    label: 'Power Steering',
    value: 'Power Steering',
  },
  {
    id: 29,
    label: 'Cassette Player',
    value: 'Cassette Player',
  },
  {
    id: 30,
    label: 'Keyless Start',
    value: 'Keyless Start',
  },
  {
    id: 31,
    label: 'AM/FM Radio',
    value: 'AM/FM Radio',
  },
  {
    id: 32,
    label: 'Cruise Control',
    value: 'Cruise Control',
  },
  {
    id: 33,
    label: 'N20 System',
    value: 'N20 System',
  },
  {
    id: 34,
    label: 'Aux Audio In ',
    value: 'Aux Audio In ',
  },
  {
    id: 35,
    label: 'Fog Lights',
    value: 'Fog Lights',
  },
  {
    id: 36,
    label: 'Off Road Tyre’s',
    value: 'Off Road Tyre’s',
  },
  {
    id: 37,
    label: 'Power Locks',
    value: 'Power Locks',
  },
  {
    id: 38,
    label: 'Premium Lights',
    value: 'Premium Lights',
  },
  {
    id: 39,
    label: 'Rear Wheel Drive',
    value: 'Rear Wheel Drive',
  },
  {
    id: 40,
    label: 'Power Seats',
    value: 'Power Seats',
  },
  {
    id: 41,
    label: 'Premium Paint',
    value: 'Premium Paint',
  },
  {
    id: 42,
    label: 'Satellite Radio',
    value: 'Satellite Radio',
  },
];
