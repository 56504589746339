import React from 'react';

const SubCategories = ({ subCategory, subCategoryHandle }) => {
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>1</span>
        <h3>
          Choose a Sub category <small>(Basic Information about your Ad)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <ul className='SubCategoriesUl DBlock'>
          {subCategory &&
            subCategory.map((category, index) => (
              <li key={index} data-id={category.id} onClick={(e) => subCategoryHandle(category.id)}>
                {category.text} <i className='fas fa-caret-right icon'></i>
              </li>
            ))}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default SubCategories;
