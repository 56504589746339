import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { companyItems } from '../Api';

function MainDetailsComponent(props) {
  let { id } = useParams();
  const detailItem = companyItems.findIndex((item) => item.id === parseInt(id));

  return (
    <React.Fragment>
      <Link to='/company-search' className='GoBack'>
        Back to Company listings
      </Link>
      {detailItem !== -1 ? (
        <div className='DetailsComponent DBlock'>
          <div className='Title DFlex'>
            <h3>{companyItems[detailItem].title}</h3>
            <Link className='ViewJob' to={`/job-search`}>
              Open Jobs
            </Link>
          </div>
          <ul className='BreadCrumbUl DFlex justify-content-start'>
            <li>Home </li>
            <li>Jobs</li>
            <li>Find a Company</li>
            <li>Citi Bank</li>
          </ul>
          <div className='CompanyImgDiv DBlock'>
            <div className='MainImg'>
              <img src={companyItems[detailItem].img[0].images} alt='' />
            </div>
            <img src={companyItems[detailItem].coverImg} alt='' />
          </div>
          <div className='DetailsListDiv DBlock'>
            <div className='row mb-4'>
              <div className='col-md-6'>
                <div className='DetailsListItem DBlock'>
                  <h6>Overview:</h6>
                  <ul className='ListDetailsUl DBlock'>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Industry:
                      </span>
                      <b>{companyItems[detailItem].industry}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Trade License No.:
                      </span>
                      <b>{companyItems[detailItem].tradeLicence}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Established Since:
                      </span>
                      <b>{companyItems[detailItem].established}</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Number of Employees
                      </span>
                      <b>{companyItems[detailItem].Employees} + Employees</b>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Location
                      </span>
                      <b>{companyItems[detailItem].location}</b>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='row mb-4'>
              <div className='col-md-6'>
                <div className='DetailsListItem DBlock'>
                  <h6>Specialties:</h6>
                  <ul className='ListDetailsUl DBlock'>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Credit Cards
                      </span>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Cash Management
                      </span>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Capital Markets
                      </span>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Investment
                      </span>
                    </li>
                    <li>
                      <span>
                        <img src='/assets/svg/setting.svg' alt='' />
                        Consumer Lending
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='Description DBlock DBlock'>
            <h5>About Us</h5>
            {companyItems[detailItem].description.map((text, index) => (
              <p key={index}>{text.text}</p>
            ))}
          </div>
          <div className='MoreBankDiv DBlock'>
            <h5>More from City Bank:</h5>
            <div className='row'>
              <div className='col-md-6'>
                <div className='MoreBank DFlex'>
                  <div className='ImgDiv DBlock'>
                    <img src='/assets/images/MoreBank1.png' alt='' className='' />
                  </div>
                  <div className='TextDiv DBlock'>
                    <p>Citigold</p>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='MoreBank DFlex'>
                  <div className='ImgDiv DBlock'>
                    <img src='/assets/images/MoreBank2.png' alt='' className='' />
                  </div>
                  <div className='TextDiv DBlock'>
                    <p>Citi Private Bank</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='NotFound DBlock'>
          <h3>Opps Company is not found!</h3>
        </div>
      )}
    </React.Fragment>
  );
}

export default MainDetailsComponent;
