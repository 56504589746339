import React from 'react';

const PersonalInfo = () => {
  const BG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };

  return (
    <div className='FormInnerDiv DBlock'>
      <h4>Personal Info</h4>
      <form action=''>
        <div className='form-row'>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Title name of CV: * </label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='text' placeholder='CV 1' required />
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>First Name: * </label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='text' placeholder='First Name' required />
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Last Name: * </label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='text' placeholder='Last Name' required />
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Age: *</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='text' placeholder='Age' required />
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Gender: *</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <select name='' id='' style={BG}>
              <option value='male'>Male</option>
              <option value='female'>Female</option>
            </select>
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Email: *</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='email' placeholder='Email' required />
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Phone: *</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='text' placeholder='Phone Number' required />
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Nationality: *</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='text' placeholder='Nationality' required />
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Residency: *</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='text' placeholder='Residency' required />
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Linkedin Profile:</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='text' placeholder='LinkedIn' required />
          </div>
        </div>
      </form>
    </div>
  );
};

export default PersonalInfo;
