import React from 'react';
import CompanyAnalyticsList from './CompanyAnalyticsList';

const CompanyAnalytics = ({ item, type }) => {
  return (
    <React.Fragment>
      {item.map((item, index) => (
        <CompanyAnalyticsList key={index} item={item} index={index} type={type} />
      ))}
    </React.Fragment>
  );
};

export default CompanyAnalytics;
