import React from 'react';

const PropertiesSpecification = ({ updateState }) => {
  const BG = {
    backgroundImage: 'url(assets/svg/DownArr.svg)',
  };
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>3</span>
        <h3>
          Property Specification <small>(Property technical specifications detail)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Price:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <input type='text' placeholder='10.000.00 AED' />
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Property Type:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Residential</option>
                <option value=''>Residential 1</option>
                <option value=''>Residential 2</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Variety:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Villa</option>
                <option value=''>Villa 1</option>
                <option value=''>Villa 2</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Area/Size: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <input type='text' placeholder=' ' />
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Offered by: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Owner</option>
                <option value=''>Owner 1</option>
                <option value=''>Owner 2</option>
              </select>
            </div>
          </div>
        </form>
        <div className='NextBtnDiv DBlock text-center'>
          <button className='NextBtn' name='setComponent' value='KeyFutures' onClick={(e) => updateState(e)}>
            Next
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PropertiesSpecification;
