export const classifiedList = [
  {
    id: 1,
    text: 'Baby Items',
    img: '/assets/svg/babes.svg',
    item: 6,
    subItem: [
      {
        id: 1,
        text: 'Baby Gear',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Baby Toys',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Feeding',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 4,
        text: 'Nursery Furniture & Accessories',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 5,
        text: 'Safety & Health',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 6,
        text: 'Strollers & Car Seat',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 2,
    text: 'Books ',
    img: '/assets/svg/babes.svg',
    item: 7,
    subItem: [
      {
        id: 1,
        text: 'All Books',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Digital',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Book Accessories',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 3,
    text: 'Business & Industrial',
    img: '/assets/svg/babes.svg',
    item: 13,
    subItem: [
      {
        id: 1,
        text: 'Agriculture & Forestry',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Businesses for Sale',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Commercial Printing & Copy Machines',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 4,
        text: 'Construction',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 5,
        text: 'Electrical Equipment',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 6,
        text: 'Food & Beverage',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 7,
        text: 'Healthcare & Lab',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 8,
        text: 'Industrial Supplies',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 9,
        text: 'Manufacturing',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 10,
        text: 'Packing & Shipping',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 11,
        text: 'Retail & Services',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 12,
        text: 'Binoculars/Telescopes',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 13,
        text: 'Camcorder Accessories',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 14,
        text: 'Camcorders',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 15,
        text: 'Camera Accessories',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 16,
        text: 'Camara Drones',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 17,
        text: 'Digital Cameras',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 18,
        text: 'Digital Photo Frames',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 19,
        text: 'Film Cameras',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 20,
        text: 'Lenses, Filters & Lighting',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 21,
        text: 'Professional Equipment',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 22,
        text: 'Tripods & Stands',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 23,
        text: 'Others',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 4,
    text: 'Cameras & Imaging',
    img: '/assets/svg/babes.svg',
    item: 11,
  },
  {
    id: 5,
    text: 'Clothing & Accessories',
    img: '/assets/svg/babes.svg',
    item: 11,
    subItem: [
      {
        id: 1,
        text: 'Clothing',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Costumes & Uniforms',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Fragrances',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 4,
        text: 'Handbags, Bags & Wallets',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 5,
        text: 'Luggage',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 6,
        text: 'Men Accessories',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 7,
        text: 'Shoes/Footwear',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 8,
        text: 'Vintage & Highend Clothing',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 9,
        text: 'Wedding Apparel',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 10,
        text: 'Women Accessories',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 11,
        text: 'Others',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 6,
    text: 'Collectibles ',
    img: '/assets/svg/babes.svg',
    item: 7,
    subItem: [
      {
        id: 1,
        text: 'Art',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Decorations',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Memorabilia',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 4,
        text: 'Pens & Writing Instruments',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 5,
        text: 'Others',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 7,
    text: 'Computers & Networking',
    img: '/assets/svg/babes.svg',
    item: 7,
    subItem: [
      {
        id: 1,
        text: 'Accessories',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Computer Components',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Computers',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 4,
        text: 'Networking & Communication',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 5,
        text: 'Software',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 6,
        text: 'Monitors, Printers & Other Peripherals',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 7,
        text: 'Other',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 8,
    text: 'DVDs & Movies',
    img: '/assets/svg/babes.svg',
    item: 4,
    subItem: [
      {
        id: 1,
        text: 'DVD',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Digital',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'VHS',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 4,
        text: 'Other Formats',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 9,
    text: 'Electronics',
    img: '/assets/svg/babes.svg',
    item: 10,
    subItem: [
      {
        id: 1,
        text: 'All Electronics',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Others',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 10,
    text: 'Free Stuff',
    img: '/assets/svg/babes.svg',
    item: 21,
    subItem: [
      {
        id: 1,
        text: 'Baby Items',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Books',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Business & Industrial',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 4,
        text: 'Cameras & Imaging',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 5,
        text: 'Clothing & Accessories',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 6,
        text: 'Collectibles',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 7,
        text: 'Computers & Networking',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 8,
        text: 'Electronics',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 9,
        text: 'Furniture, Home & Garden',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 10,
        text: 'Gaming',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 11,
        text: 'Home Appliances',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 12,
        text: 'Jewelry & Watches',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 13,
        text: 'Mobile Phones & PDAs',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 14,
        text: 'Music',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 15,
        text: 'Sports Equipment',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 16,
        text: 'Tickets & Vouchers',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 17,
        text: 'Others',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 11,
    text: 'Furniture, Home & Garden',
    img: '/assets/svg/babes.svg',
    item: 7,
    subItem: [
      {
        id: 1,
        text: 'Curtains & Blinds',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Furniture',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Garden & Outdoor',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 4,
        text: 'Home Accessories',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 5,
        text: 'Lighting & Fans',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 6,
        text: 'Rugs & Carpets',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 7,
        text: 'Tools & Home Improvement',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 12,
    text: 'Gaming',
    img: '/assets/svg/babes.svg',
    item: 4,
    subItem: [
      {
        id: 1,
        text: 'Gaming Accessories',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Video Games',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Others',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 13,
    text: 'Home Appliances',
    img: '/assets/svg/babes.svg',
    item: 5,
    subItem: [
      {
        id: 1,
        text: 'Outdoor Appliances',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Indoor Appliances',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Bathroom Appliances',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 4,
        text: 'Kitchen Appliances',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 5,
        text: 'Kitchen Appliances',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 14,
    text: 'Jewelry & Watches',
    img: '/assets/svg/babes.svg',
    item: 5,
    subItem: [
      {
        id: 1,
        text: 'Diamonds & Gems',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Men Jewelry',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Watches',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 4,
        text: 'Women Jewelry',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 5,
        text: 'Others',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 15,
    text: 'Mobile Phones & PDAs',
    img: '/assets/svg/babes.svg',
    item: 4,
    subItem: [
      {
        id: 1,
        text: 'Accessories',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Mobile Phones',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'PDAs',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 16,
    text: 'Music',
    img: '/assets/svg/babes.svg',
    item: 5,
    subItem: [
      {
        id: 1,
        text: 'CDs',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Casettes',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Digital',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 4,
        text: 'Vinyl',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 5,
        text: 'Guitars',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 6,
        text: 'Percussion',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 7,
        text: 'Pianos, Keyboards & Organs',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 8,
        text: 'String Instruments',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 9,
        text: 'Wind Instruments',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 10,
        text: 'Other',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 17,
    text: 'Pets',
    img: '/assets/svg/babes.svg',
    item: 3,
    subItem: [
      {
        id: 1,
        text: 'Cat / Dog / Fish',

        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Pet Accessories',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 18,
    text: 'Sports Equipment',
    img: '/assets/svg/babes.svg',
    item: 10,
    subItem: [
      {
        id: 1,
        text: 'Camping & Hiking',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Cycling',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Exercise Equipment',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 4,
        text: 'Golf',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 5,
        text: 'Indoor Sports',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 6,
        text: 'Team Sports',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 7,
        text: 'Tennis & Racquet Sports',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 8,
        text: 'Water Sports',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 9,
        text: 'Winter Sports',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 10,
        text: 'Snooker / Billiard ',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 11,
        text: 'Other Sports',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 19,
    text: 'Stuff Wanted ',
    img: '/assets/svg/babes.svg',
    item: 24,
    subItem: [
      {
        id: 1,
        text: 'Concerts',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Events',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Movies',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 4,
        text: 'Sporting Events',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 5,
        text: 'Theater',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 6,
        text: 'Travel',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 7,
        text: 'Others',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 20,
    text: 'Tickets & Vouchers',
    img: '/assets/svg/babes.svg',
    item: 7,
    subItem: [
      {
        id: 1,
        text: 'Action Figures & Toy Vehicles',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 2,
        text: 'Classic & Vintage Toys',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 3,
        text: 'Dolls & Stuffed Animals',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 4,
        text: 'Educational Toys',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 5,
        text: 'Electronic & Remote-Control Toys',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 6,
        text: 'Games & Puzzles',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 7,
        text: 'Hobbies',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 8,
        text: 'Outdoor Toys & Structures',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 9,
        text: 'Pretend Play & Preschool Toys',
        img: '/assets/svg/babes.svg',
      },
      {
        id: 10,
        text: 'Others',
        img: '/assets/svg/babes.svg',
      },
    ],
  },
  {
    id: 21,
    text: 'Toys ',
    img: '/assets/svg/babes.svg',
    item: 10,
  },
  {
    id: 22,
    text: 'Misc',
    img: '/assets/svg/babes.svg',
    item: 24,
  },
];

export const classifiedItems = [
  {
    id: 1,
    img: [
      { id: 1, images: '/assets/images/Claseed1.png' },
      { id: 2, images: '/assets/images/Claseed1.png' },
      { id: 3, images: '/assets/images/Claseed1.png' },
      { id: 4, images: '/assets/images/Claseed1.png' },
    ],
    title: 'Macbook Pro',
    date: '10/12/2018',
    price: 500.0,
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    age: '6 months',
    condition: 'Perfect inside and out',
    warranty: 'Does not apply',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
  {
    id: 2,
    img: [
      { id: 1, images: '/assets/images/Claseed2.png' },
      { id: 2, images: '/assets/images/Claseed2.png' },
      { id: 3, images: '/assets/images/Claseed2.png' },
      { id: 4, images: '/assets/images/Claseed2.png' },
    ],
    title: 'Stroller & Car Seats',
    date: '10/12/2018',
    price: 500.0,
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    age: '6 months',
    condition: 'Perfect inside and out',
    warranty: 'Does not apply',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
  {
    id: 3,
    img: [
      { id: 1, images: '/assets/images/Claseed1.png' },
      { id: 2, images: '/assets/images/Claseed1.png' },
      { id: 3, images: '/assets/images/Claseed1.png' },
      { id: 4, images: '/assets/images/Claseed1.png' },
    ],
    title: 'Macbook Pro',
    date: '10/12/2018',
    price: 500.0,
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    age: '6 months',
    condition: 'Perfect inside and out',
    warranty: 'Does not apply',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
  {
    id: 4,
    img: [
      { id: 1, images: '/assets/images/Claseed2.png' },
      { id: 2, images: '/assets/images/Claseed2.png' },
      { id: 3, images: '/assets/images/Claseed2.png' },
      { id: 4, images: '/assets/images/Claseed2.png' },
    ],
    title: 'Stroller & Car Seats',
    date: '10/12/2018',
    price: 500.0,
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    age: '6 months',
    condition: 'Perfect inside and out',
    warranty: 'Does not apply',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
  {
    id: 5,
    img: [
      { id: 1, images: '/assets/images/Claseed1.png' },
      { id: 2, images: '/assets/images/Claseed1.png' },
      { id: 3, images: '/assets/images/Claseed1.png' },
      { id: 4, images: '/assets/images/Claseed1.png' },
    ],
    title: 'Macbook Pro',
    date: '10/12/2018',
    price: 500.0,
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    age: '6 months',
    condition: 'Perfect inside and out',
    warranty: 'Does not apply',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
  {
    id: 6,
    img: [
      { id: 1, images: '/assets/images/Claseed1.png' },
      { id: 2, images: '/assets/images/Claseed1.png' },
      { id: 3, images: '/assets/images/Claseed1.png' },
      { id: 4, images: '/assets/images/Claseed1.png' },
    ],
    title: 'Stroller & Car Seats',
    date: '10/12/2018',
    price: 500.0,
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    age: '6 months',
    condition: 'Perfect inside and out',
    warranty: 'Does not apply',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
];

export const relatedItems = [
  {
    id: 1,
    img: [
      { id: 1, images: '/assets/images/Claseed1.png' },
      { id: 2, images: '/assets/images/Claseed1.png' },
      { id: 3, images: '/assets/images/Claseed1.png' },
      { id: 4, images: '/assets/images/Claseed1.png' },
    ],
    title: 'Macbook Pro',
    date: '10/12/2018',
    price: 500.0,
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    age: '6 months',
    condition: 'Perfect inside and out',
    warranty: 'Does not apply',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
];

export const classifiedTrends = [
  {
    id: 1,
    img: [
      { id: 1, images: '/assets/images/Claseed1.png' },
      { id: 2, images: '/assets/images/Claseed1.png' },
      { id: 3, images: '/assets/images/Claseed1.png' },
      { id: 4, images: '/assets/images/Claseed1.png' },
    ],
    title: 'Macbook Pro',
    date: '10/12/2018',
    price: 500.0,
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    age: '6 months',
    condition: 'Perfect inside and out',
    warranty: 'Does not apply',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
  {
    id: 2,
    img: [
      { id: 1, images: '/assets/images/Claseed2.png' },
      { id: 2, images: '/assets/images/Claseed2.png' },
      { id: 3, images: '/assets/images/Claseed2.png' },
      { id: 4, images: '/assets/images/Claseed2.png' },
    ],
    title: 'Stroller & Car Seats',
    date: '10/12/2018',
    price: 500.0,
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    age: '6 months',
    condition: 'Perfect inside and out',
    warranty: 'Does not apply',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
  {
    id: 3,
    img: [
      { id: 1, images: '/assets/images/Claseed1.png' },
      { id: 2, images: '/assets/images/Claseed1.png' },
      { id: 3, images: '/assets/images/Claseed1.png' },
      { id: 4, images: '/assets/images/Claseed1.png' },
    ],
    title: 'Macbook Pro',
    date: '10/12/2018',
    price: 500.0,
    usage: 1,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    age: '6 months',
    condition: 'Perfect inside and out',
    warranty: 'Does not apply',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
];
