export const candidatesItems = [
  {
    id: 1,
    img: [
      { id: 1, images: '/assets/images/Candidate1.jpg' },
      { id: 2, images: '/assets/images/Candidate1.jpg' },
      { id: 3, images: '/assets/images/Candidate1.jpg' },
      { id: 4, images: '/assets/images/Candidate1.jpg' },
    ],
    name: 'Josh Andersan',
    title: 'Senior Digital Specialist (UI/UX)',
    date: '10/12/2018',
    age: 25,
    gender: 'Male',
    nationality: 'American',
    residence: 'Dubai',
    email: 'info@gmail.com',
    linkedin: 'info@gmail.com',
    totalExperience: 10,
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea comm odo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    experience: [
      {
        id: 1,
        jobTitle: 'Digital Specialist',
        company: 'Google',
        date: '9/8/2020',
      },
    ],
    education: [
      {
        id: 1,
        degree: 'Master’s Degree',
        school: 'Murdoch University Dubai',
        date: '9/8/2020',
      },
    ],
    license: ['Drivers License', 'Drivers License'],
    skills: ['Adobe Photoshop', 'Adobe Illustratur', 'Adobe XD', 'Dreamviewer', 'Adobe Acrobode'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
  {
    id: 2,
    img: [
      { id: 1, images: '/assets/images/Candidate2.jpg' },
      { id: 2, images: '/assets/images/Candidate2.jpg' },
      { id: 3, images: '/assets/images/Candidate2.jpg' },
      { id: 4, images: '/assets/images/Candidate2.jpg' },
    ],
    name: 'Johshwa',
    title: 'Senior junior Designer (UI/UX)',
    date: '10/12/2018',
    age: 25,
    gender: 'Male',
    nationality: 'American',
    residence: 'Dubai',
    email: 'info@gmail.com',
    linkedin: 'info@gmail.com',
    totalExperience: 10,
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea comm odo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    experience: [
      {
        jobTitle: 'junior Designer',
        company: 'Google',
        date: '9/8/2020',
      },
    ],
    education: [
      {
        degree: 'Master’s Degree',
        school: 'Murdoch University Dubai',
        date: '9/8/2020',
      },
    ],
    license: ['Drivers License', 'Drivers License'],
    skills: ['Adobe Photoshop', 'Adobe Illustratur', 'Adobe XD', 'Dreamviewer', 'Adobe Acrobode'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
  {
    id: 3,
    img: [
      { id: 1, images: '/assets/images/Candidate3.jpg' },
      { id: 2, images: '/assets/images/Candidate3.jpg' },
      { id: 3, images: '/assets/images/Candidate3.jpg' },
      { id: 4, images: '/assets/images/Candidate3.jpg' },
    ],
    name: 'Kevin Smith',
    title: 'Senior Designer (UI/UX)',
    date: '10/12/2018',
    age: 25,
    gender: 'Male',
    nationality: 'American',
    residence: 'Dubai',
    email: 'info@gmail.com',
    linkedin: 'info@gmail.com',
    totalExperience: 10,
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea comm odo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    experience: [
      {
        jobTitle: 'Designer',
        company: 'Google',
        date: '9/8/2020',
      },
    ],
    education: [
      {
        degree: 'Master’s Degree',
        school: 'Murdoch University Dubai',
        date: '9/8/2020',
      },
    ],
    license: ['Drivers License', 'Drivers License'],
    skills: ['Adobe Photoshop', 'Adobe Illustratur', 'Adobe XD', 'Dreamviewer', 'Adobe Acrobode'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
  {
    id: 4,
    img: [
      { id: 1, images: '/assets/images/Candidate1.jpg' },
      { id: 2, images: '/assets/images/Candidate1.jpg' },
      { id: 3, images: '/assets/images/Candidate1.jpg' },
      { id: 4, images: '/assets/images/Candidate1.jpg' },
    ],
    name: 'Josh Andersan',
    title: 'Senior Digital Specialist (UI/UX)',
    date: '10/12/2018',
    age: 25,
    gender: 'Male',
    nationality: 'American',
    residence: 'Dubai',
    email: 'info@gmail.com',
    linkedin: 'info@gmail.com',
    totalExperience: 10,
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea comm odo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    experience: [
      {
        jobTitle: 'Digital Specialist',
        company: 'Google',
        date: '9/8/2020',
      },
    ],
    education: [
      {
        degree: 'Master’s Degree',
        school: 'Murdoch University Dubai',
        date: '9/8/2020',
      },
    ],
    license: ['Drivers License', 'Drivers License'],
    skills: ['Adobe Photoshop', 'Adobe Illustratur', 'Adobe XD', 'Dreamviewer', 'Adobe Acrobode'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
  {
    id: 5,
    img: [
      { id: 1, images: '/assets/images/Candidate2.jpg' },
      { id: 2, images: '/assets/images/Candidate2.jpg' },
      { id: 3, images: '/assets/images/Candidate2.jpg' },
      { id: 4, images: '/assets/images/Candidate2.jpg' },
    ],
    name: 'Johshwa',
    title: 'Senior junior Designer (UI/UX)',
    date: '10/12/2018',
    age: 25,
    gender: 'Male',
    nationality: 'American',
    residence: 'Dubai',
    email: 'info@gmail.com',
    linkedin: 'info@gmail.com',
    totalExperience: 10,
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea comm odo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    experience: [
      {
        jobTitle: 'junior Designer',
        company: 'Google',
        date: '9/8/2020',
      },
    ],
    education: [
      {
        degree: 'Master’s Degree',
        school: 'Murdoch University Dubai',
        date: '9/8/2020',
      },
    ],
    license: ['Drivers License', 'Drivers License'],
    skills: ['Adobe Photoshop', 'Adobe Illustratur', 'Adobe XD', 'Dreamviewer', 'Adobe Acrobode'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
  {
    id: 6,
    img: [
      { id: 1, images: '/assets/images/Candidate3.jpg' },
      { id: 2, images: '/assets/images/Candidate3.jpg' },
      { id: 3, images: '/assets/images/Candidate3.jpg' },
      { id: 4, images: '/assets/images/Candidate3.jpg' },
    ],
    name: 'Kevin Smith',
    title: 'Senior Designer (UI/UX)',
    date: '10/12/2018',
    age: 25,
    gender: 'Male',
    nationality: 'American',
    residence: 'Dubai',
    email: 'info@gmail.com',
    linkedin: 'info@gmail.com',
    totalExperience: 10,
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea comm odo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    experience: [
      {
        jobTitle: 'Designer',
        company: 'Google',
        date: '9/8/2020',
      },
    ],
    education: [
      {
        degree: 'Master’s Degree',
        school: 'Murdoch University Dubai',
        date: '9/8/2020',
      },
    ],
    license: ['Drivers License', 'Drivers License'],
    skills: ['Adobe Photoshop', 'Adobe Illustratur', 'Adobe XD', 'Dreamviewer', 'Adobe Acrobode'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
];

export const relatedItems = [
  {
    id: 1,
    img: [
      { id: 1, images: '/assets/images/Candidate1.jpg' },
      { id: 2, images: '/assets/images/Candidate1.jpg' },
      { id: 3, images: '/assets/images/Candidate1.jpg' },
      { id: 4, images: '/assets/images/Candidate1.jpg' },
    ],
    name: 'Josh Andersan',
    title: 'Senior Digital Specialist (UI/UX)',
    date: '10/12/2018',
    age: 25,
    gender: 'Male',
    nationality: 'American',
    residence: 'Dubai',
    email: 'info@gmail.com',
    linkedin: 'info@gmail.com',
    totalExperience: 10,
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea comm odo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    experience: [
      {
        jobTitle: 'Digital Specialist',
        company: 'Google',
        date: '9/8/2020',
      },
    ],
    education: [
      {
        degree: 'Master’s Degree',
        school: 'Murdoch University Dubai',
        date: '9/8/2020',
      },
    ],
    license: ['Drivers License', 'Drivers License'],
    skills: ['Adobe Photoshop', 'Adobe Illustratur', 'Adobe XD', 'Dreamviewer', 'Adobe Acrobode'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
];
