import React, { Component } from 'react';
import AddDescription from './AddDescription';
import KeyFutures from './KeyFutures';
import MapLocation from './MapLocation';
import TimeSlots from './TimeSlots';
import UploadImage from './UploadImage';
import WhatsappPermit from './WhatsappPermit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import PreviewComponent from './PreviewComponent';
import { previewApi } from './PreviewApi';
import { Link } from 'react-router-dom';
import ClassifiedSpecification from './ClassifiedSpecification';
import { getClassifiedWarranty, getClassifiedAge, getClassifiedUsage, getClassifiedCondition } from '../../../../ApiData/GetData';

export class ClassifiedMainComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: false,
      previewData: previewApi,
      agree: false,
      anotherSubCat: null,
      specificationWarranty: [
        { id: 1, name: 'Warranty 1' },
        { id: 2, name: 'Warranty 2' },
      ],
      keyFeatureAge: [
        { id: 1, name: 'Age 1' },
        { id: 2, name: 'Age 2' },
      ],
      keyFeatureUsage: [
        { id: 1, name: 'Usage 1' },
        { id: 2, name: 'Usage 2' },
      ],
      keyFeatureCondition: [
        { id: 1, name: 'Condition 1' },
        { id: 2, name: 'Condition 2' },
      ],
      form: {
        category: this.props.breadCrumb,
        title: '',
        description: '',
        price: '',
        warranty: '',
        age: '',
        usage: '',
        condition: '',
        whatsapp: null,
        featureAds: false,
        terms: false,
        availability: {
          morning: [6, 10],
          afternoon: [13, 14],
          evening: [18, 20],
        },
      },
    };
  }

  async componentDidMount() {
    const res2 = await getClassifiedWarranty();
    const res3 = await getClassifiedAge();
    const res4 = await getClassifiedUsage();
    const res5 = await getClassifiedCondition();
    if (res2) {
      this.setState({ specificationWarranty: res2.data });
    }
    if (res3) {
      this.setState({ keyFeatureAge: res3.data });
    }
    if (res4) {
      this.setState({ keyFeatureUsage: res4.data });
    }
    if (res5) {
      this.setState({ keyFeatureCondition: res5.data });
    }
  }

  addDescriptionHandler = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSpecification = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleKeyFeature = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleFeatureAds = (e) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        featureAds: e.target.checked,
      },
    });
  };

  handleTerms = (e) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        terms: e.target.checked,
      },
    });
  };

  updateTimeSlots = (morning, afternoon, evening) => {
    if (morning !== null) {
      if (afternoon !== null) {
        if (evening !== null) {
          this.setState({
            ...this.state,
            form: {
              ...this.state.form,
              availability: {
                ...this.state.form.availability,
                morning,
                afternoon,
                evening,
              },
            },
          });
        } else {
          this.setState({
            ...this.state,
            form: {
              ...this.state.form,
              availability: {
                ...this.state.form.availability,
                morning,
                afternoon,
              },
            },
          });
        }
      } else {
        this.setState({
          ...this.state,
          form: {
            ...this.state.form,
            availability: {
              ...this.state.form.availability,
              morning,
            },
          },
        });
      }
    } else {
      this.setState({
        ...this.state,
      });
    }
  };

  updateState = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  setWhatsapp = (e) => {
    console.log(e);
    if (e !== undefined) {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          whatsapp: e,
        },
      });
    }
  };

  previewForm = (e) => {
    this.setState({
      ...this.state,
      setComponent: null,
      preview: true,
    });
  };

  backForm = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      preview: false,
    });
  };

  render() {
    const BG = {
      backgroundImage: 'url(/assets/svg/DownArr.svg)',
    };
    return (
      <React.Fragment>
        <div className='PlaceAdsWrapper DBlock'>
          {!this.state.preview ? (
            <React.Fragment>
              <div className='TopBar DFlex'>
                <Link to='/place-your-ad/classified-ad' onClick={() => this.props.handleBack()}>
                  <i className='fas fa-long-arrow-alt-left icon'></i>
                </Link>
                <p className='mx-auto'>Place Your Ad</p>
              </div>
              <div className='Title DBlock'>
                <ul className='BreadCrumb DFlex justify-content-start'>
                  <li>Home </li>
                  <li>Place Your Ad</li>
                  <li>Classifieds </li>
                  {this.props.breadCrumb && this.props.breadCrumb.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
              </div>
              <div className='AdsFormOuterDiv DBlock'>
                <div className='FormHeading DFlex justify-content-start'>
                  <span className='CustomSpan'>1</span>
                  <h3>Sub category</h3>
                </div>
                <div className='FormDescription DBlock'>
                  <form action=''>
                    <div className='form-row'>
                      <div className='col-12 mb-3'>
                        <select name='age' style={BG}>
                          <option>{this.state.form.category[this.state.form.category.length - 1]}</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                <AddDescription
                  addDescriptionHandler={this.addDescriptionHandler}
                  title={this.state.form.title}
                  description={this.state.form.description}
                />
                <ClassifiedSpecification
                  specificationWarranty={this.state.specificationWarranty}
                  handleSpecification={this.handleSpecification}
                  price={this.state.form.price}
                  warranty={this.state.form.warranty}
                />
                <KeyFutures
                  keyFeatureAge={this.state.keyFeatureAge}
                  keyFeatureUsage={this.state.keyFeatureUsage}
                  keyFeatureCondition={this.state.keyFeatureCondition}
                  handleKeyFeature={this.handleKeyFeature}
                  age={this.state.form.age}
                  usage={this.state.form.usage}
                  condition={this.state.form.condition}
                />
                <UploadImage updateState={this.updateState} />
                <MapLocation updateState={this.updateState} />
                <TimeSlots
                  updateTimeSlots={this.updateTimeSlots}
                  morning={this.state.form.availability.morning}
                  afternoon={this.state.form.availability.afternoon}
                  evening={this.state.form.availability.evening}
                />
                <WhatsappPermit setWhatsapp={this.setWhatsapp} whatsapp={this.state.form.whatsapp} />
                <form action='' className='DBlock'>
                  <div className='FormHeading DFlex justify-content-start'>
                    <span className='CustomSpan'>9</span>
                    <h3>
                      <FormControlLabel
                        labelPlacement='start'
                        control={
                          <Switch checked={this.state.featureAds} onChange={(e) => this.handleFeatureAds(e)} name='featureAds' color='primary' />
                        }
                        label='Do you want to make your Ad as Feature Ad?'
                      />
                    </h3>
                  </div>
                </form>
                <div className='FormHeading DFlex justify-content-start'>
                  <span className='CustomSpan'>10</span>
                  <h3>
                    <FormControlLabel
                      labelPlacement='start'
                      control={<Switch checked={this.state.form.terms} onChange={(e) => this.handleTerms(e)} name='terms' color='primary' />}
                      label='Agree the Terms and Conditions'
                    />
                  </h3>
                  <div className='NextBtnDiv DBlock text-center'>
                    <button className='NextBtn' onClick={this.previewForm}>
                      Preview your Ad
                    </button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='AdsFormOuterDiv DBlock p-0'>
                <PreviewComponent previewData={this.state.previewData} backForm={this.backForm} />
              </div>
            </React.Fragment>
          )}
          {/* <!-- Modal Submited --> */}
          <div
            className='modal fade'
            id='staticBackdropSubmit'
            data-backdrop='static'
            data-keyboard='false'
            tabIndex='-1'
            aria-labelledby='staticBackdropSubmitLabel'
            aria-hidden='true'
          >
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-body'>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                  {!this.state.agree ? (
                    <React.Fragment>
                      <div className='ModalInnerDiv DBlock'>
                        <div className='ModalText DBlock'>
                          <h6>Let’s stay safe!</h6>
                          <p> Your add will not go live if it is:</p>
                          <ul className='TextUl DBlock text-left'>
                            <li>
                              <span>1</span>Prohibited item or activity that violates UAE law
                            </li>
                            <li>
                              <span>2</span>Wrong category placement
                            </li>
                            <li>
                              <span>3</span>Placed multiple times, or placed in more than one category
                            </li>
                            <li>
                              <span>4</span>Fraudulent or misleading information
                            </li>
                            <li>
                              <span>5</span>For items located outside of UAE
                            </li>
                          </ul>
                          <p>
                            For more information, read our <span>terms and conditions </span>
                          </p>
                        </div>
                        <div className='ModalBtnDiv DFlex justify-content-center'>
                          <button className='ModalBtn' onClick={() => this.setState({ agree: true })}>
                            I agree
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className='ModalInnerDiv DBlock'>
                        <div className='ModalText DBlock'>
                          <h6>Congratulations!</h6>
                          <p>Your Add has been submitted successfully!</p>
                          <img src='/assets/svg/TickCircle.svg' className='Tick' alt='' />
                        </div>
                        <div className='ModalBtnDiv DFlex justify-content-center'>
                          <Link to='/place-your-ad' data-dismiss='modal' className='ModalBtn'>
                            View Ad research
                          </Link>
                          <Link to='/place-your-ad/classified-ad' data-dismiss='modal'>
                            Place another add
                          </Link>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ClassifiedMainComponent;
