import React, { Component } from 'react';
import { companyItems } from './Api';
import GridViewComponent from './GridViewComponent';
import ListViewComponent from './ListViewComponent';

export class MainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'list',
      items: companyItems,
    };
  }
  sortList(id) {
    const sortItem = this.state.list.find((list) => list.id === id);
    if (sortItem.subItem) {
      this.setState({
        list: sortItem.subItem,
      });
    } else {
      return this.state.list;
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className='MainComDiv DBlock'>
          <div className='ItemsOuterDiv DBlock'>
            <div className='ItemText DBlock'>
              <ul className='BreadCrumb DFlex'>
                <li>Home </li>
                <li>Jobs </li>
                <li>Job Seeker </li>
                <li>Search by Company</li>
              </ul>
              <p>23,456 Results in Companies</p>
              <div className='Tabs DFlex'>
                <ul className='FilterTab DFlex justify-content-start w-auto'>
                  <li className='active'>All Ads</li>
                  <li>Recent Ads</li>
                  <li>Popular Ads</li>
                </ul>
                <ol className='GridTab DFlex justify-content-start w-auto' id='ViewTab'>
                  <li className={this.state.view === 'list' ? 'active' : ''} onClick={() => this.setState({ view: 'list' })}>
                    <i className='fas fa-grip-lines'></i>
                  </li>
                  <li className={this.state.view === 'grid' ? 'active' : ''} onClick={() => this.setState({ view: 'grid' })}>
                    <i className='fas fa-th'></i>
                  </li>
                </ol>
              </div>
            </div>
            <div className='ItemDesc DBlock'>
              <div className='ItemsDiv DBlock'>
                <h4>Companies you might be interest on</h4>
                {(() => {
                  switch (this.state.view) {
                    case 'list':
                      return <ListViewComponent item={this.state.items} type='company' />;
                    case 'grid':
                      return <GridViewComponent item={this.state.items} col={4} type='company' />;
                    default:
                      return 'No Component Found!';
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MainComponent;
