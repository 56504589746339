import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Link } from 'react-router-dom';

const SignIn = () => {
  let [type, setType] = useState('password');
  const [radio] = useState('sign-in');
  const handleType = (e) => {
    switch (type) {
      case 'password':
        return setType((type = 'text'));
      case 'text':
        return setType((type = 'password'));
      default:
        return type;
    }
  };
  return (
    <React.Fragment>
      <div className='SignFormWrapper DBlock'>
        <div className='SignFormOuterDiv DBlock'>
          <div className='SignInfo DBlock'>
            <h3>Log in</h3>
            <div className='InfoBtn DFlex justify-content-center'>
              <button className='Google'>Google</button>
              <button className='Facebook'>Facebook</button>
            </div>
          </div>
          <div className='SignFormDiv'>
            <h3>Sign up</h3>
            <form action='' className='form'>
              <div className='form-row'>
                <div className='col-12 mb-3'>
                  <input type='text' placeholder='User Name or Email ' name='' required />
                </div>
                <div className='col-12 mb-3'>
                  <input type={type} placeholder='Enter Password' name='' required />
                  <button className='ShowPassBtn' onClick={(e) => handleType(e)}>
                    <i class='far fa-eye icon'></i>
                  </button>
                </div>
                <div className='col-12 mb-3'>
                  <FormControlLabel value={radio} control={<Radio />} label='Keep me signed in' />
                </div>
                <div className='col-12 mb-3'>
                  <button className='SingUp SignBtn'>Sign in</button>
                </div>
              </div>
            </form>
          </div>
          <Link to='forgot-password'>Forgot Password</Link>
          <p>
            Already have an account? <Link to='/sign-up'>Sign up</Link>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
