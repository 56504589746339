import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import MobileHeader from './header/Header';
import Footer from './footer/Footer';
import Home from './home/Home';
import SignIn from './signForms/SignIn';
import SignUp from './signForms/SignUp';
import ForgotPassword from './signForms/ForgotPassword';
import { Classified } from './classified/Classified';
import ClassifiedDetails from './classified/classifiedDetails/ClassifiedDetails';
import { Motors } from './motors/Motors';
import MotorsDetails from './motors/motorsDetails/MotorsDetails';
import Properties from './properties/Properties';
import PropertiesDetails from './properties/propertiesDetails/PropertiesDetails';
import Community from './community/Community';
import CommunityDetails from './community/communityDetails/CommunityDetails';
import { PlaceYourAds } from './placeYourAds/PlaceYourAds';
import ClassifiedAds from './placeYourAds/adsComponents/classifiedAdsComponents/ClassifiedAds';
import { CommunityAds } from './placeYourAds/adsComponents/communityAdsComponents/CommunityAds';
import { MotorsAds } from './placeYourAds/adsComponents/motorAdsComponents/MotorsAds';
import { PropertiesAds } from './placeYourAds/adsComponents/propertiesAdsComponents/PropertiesAds';
import MyAds from './profile/myAds/MyAds';
import LikedAds from './profile/likedAds/LikedAds';
import DataAnalytics from './profile/adsAnalytics/DataAnalytics';
import Profile from './profile/Profile';
import EditProfile from './profile/editProfile/EditProfile';

export default function Mobile() {
  return (
    <div className='MobileWrapper DBlock d-block d-lg-none'>
      <Router>
        <ScrollContext>
          <React.Fragment>
            <MobileHeader />
            <Switch>
              <Home path='/' exact component={Home} />
              <Route path='/sign-in' exact component={SignIn} />
              <Route path='/sign-up' exact component={SignUp} />
              <Route path='/forgot-password' exact component={ForgotPassword} />
              <Route path='/classified' exact component={Classified} />
              <Route path='/classified/detail/:id' exact component={ClassifiedDetails} />
              <Route path='/forgot-password' exact component={ForgotPassword} />
              <Route path='/motors' exact component={Motors} />
              <Route path='/motors/detail/:id' exact component={MotorsDetails} />
              <Route path='/properties' exact component={Properties} />
              <Route path='/properties/detail/:id' exact component={PropertiesDetails} />
              <Route path='/community' exact component={Community} />
              <Route path='/community/detail/:id' exact component={CommunityDetails} />
              <Route path='/place-your-ad' exact component={PlaceYourAds} />
              <Route path='/place-your-ad/classified-ad' exact component={ClassifiedAds} />
              <Route path='/place-your-ad/community-ad' exact component={CommunityAds} />
              <Route path='/place-your-ad/motors-ad' exact component={MotorsAds} />
              <Route path='/place-your-ad/properties-ad' exact component={PropertiesAds} />
              <Route path='/place-your-ad/properties-ad' exact component={PropertiesAds} />
              <Route path='/my-ads' exact component={MyAds} />
              <Route path='/liked-ads' exact component={LikedAds} />
              <Route path='/ads-analytics' exact component={DataAnalytics} />
              <Route path='/profile' exact component={Profile} />
              <Route path='/edit-profile' exact component={EditProfile} />
            </Switch>
            <Footer />
          </React.Fragment>
        </ScrollContext>
      </Router>
    </div>
  );
}
