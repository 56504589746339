import React from 'react';

const SearchForm = () => {
  const selectBG = {
    backgroundImage: 'url(/assets/svg/UpDownArr.svg)',
  };

  const inputBG = {
    backgroundImage: 'url(/assets/svg/LocationIcon.svg)',
  };
  return (
    <div className='SearchCom DBlock'>
      <h3>
        Find your next <span>Candidate</span>
      </h3>
      <div className='SearchDiv DBlock'>
        <form action='' className='form'>
          <div className='form-row'>
            <div className='col-lg-3 mb-3'>
              <select name='' style={selectBG}>
                <option value=''>All types</option>
                <option value=''>All types 1</option>
                <option value=''>All types 2</option>
              </select>
            </div>
            <div className='col-lg-3 mb-3'>
              <select name='' style={selectBG}>
                <option value=''>Price</option>
                <option value=''>Price 1</option>
                <option value=''>Price 2</option>
              </select>
            </div>
            <div className='col-lg-3 mb-3'>
              <input type='text' placeholder='Your Location' style={inputBG} />
            </div>
            <div className='col-lg-3 mb-3'>
              <button className='SearchBtn' type='submit'>
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchForm;
