import React from 'react';
import './Properties.scss';
import LeftSidebar from './LeftSidebar';
import MainComponent from './MainComponent';
import RightSidebar from './RightSidebar';

export default function Properties() {
  return (
    <React.Fragment>
      <div className='PropertiesCom DBlock position-relative'>
        <div className='CompDiv DFlex'>
          <div className='LeftSideBar DBlock'>
            <LeftSidebar />
          </div>
          <div className='MainComponent DBlock'>
            <MainComponent />
          </div>
          <div className='RightSideBar DBlock'>
            <RightSidebar />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
