import React from 'react';
import CandidateAnalyticsList from './CandidateAnalyticsList';

const CandidateAnalytics = ({ item, type }) => {
  return (
    <React.Fragment>
      {item.map((item, index) => (
        <CandidateAnalyticsList item={item} key={index} index={index} type={type} />
      ))}
    </React.Fragment>
  );
};

export default CandidateAnalytics;
