import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export class AdditionalFuture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewNum: 12,
      fullView: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className='FormHeading DFlex justify-content-start'>
          <span className='CustomSpan'>5</span>
          <h3>
            Additional Features <small>(Car technical specifications detail)</small>
          </h3>
        </div>
        <div className='FormDescription DBlock'>
          <ul className='AddFutureUl DFlex justify-content-start'>
            {this.props.additionalFutures &&
              this.props.additionalFutures.slice(0, this.state.viewNum).map((future, index) => (
                <li key={index} data-id={future.id}>
                  <FormControlLabel
                    value={future.value}
                    control={<Radio />}
                    label={future.label}
                    onChange={(e) => this.props.handleAdditionalFutures(e)}
                  />
                </li>
              ))}
          </ul>
          <div className='ViewBtn DBlock'>
            {this.state.fullView ? (
              <button onClick={() => this.setState({ viewNum: 12, fullView: false })}>Show less</button>
            ) : (
              <button onClick={() => this.setState({ viewNum: this.props.additionalFutures.length, fullView: true })}>Show all</button>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AdditionalFuture;
