import React, { Component } from 'react';
import AddDescription from './AddDescription';
import AdditionalFuture from './AdditionalFuture';
import KeyFutures from './KeyFutures';
import MapLocation from './MapLocation';
import TimeSlots from './TimeSlots';
import UploadImage from './UploadImage';
import WhatsappPermit from './WhatsappPermit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import PreviewComponent from './PreviewComponent';
import { previewApi } from './PreviewApi';
import { Link } from 'react-router-dom';
import MotorsSpecification from './MotorsSpecification';
import { getClassifiedWarranty, getClassifiedAge, getClassifiedUsage, getClassifiedCondition } from '../../../../ApiData/GetData';

export class MotorsMainComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: false,
      previewData: previewApi,
      additionalFutures: additionalFutures,
      agree: false,
      anotherSubCat: null,
      specManufacturer: [
        { id: 1, name: 'Manufacturer 1' },
        { id: 2, name: 'Manufacturer 2' },
      ],
      specModal: [
        { id: 1, name: 'Modal 1' },
        { id: 2, name: 'Modal 2' },
      ],
      specDoor: [
        { id: 1, name: 'Door 1' },
        { id: 2, name: 'Door 2' },
      ],
      specColor: [
        { id: 1, name: 'Red' },
        { id: 2, name: 'Green' },
        { id: 2, name: 'Blue' },
      ],
      specFuelType: [
        { id: 1, name: 'Petrol' },
        { id: 2, name: 'Gas' },
      ],
      specHorsepower: [
        { id: 1, name: '1000 bhp' },
        { id: 2, name: '2000 bhp' },
      ],
      specBodyCondition: [
        { id: 1, name: 'No accidents' },
        { id: 2, name: 'Few faults' },
      ],
      keyManageBy: [
        { id: 1, name: 'Family' },
        { id: 2, name: 'Friends' },
      ],
      keyOfferedBy: [
        { id: 1, name: 'Owner 1' },
        { id: 2, name: 'Owner 2' },
      ],
      keyBodyType: [
        { id: 1, name: 'Coupe 1' },
        { id: 2, name: 'Coupe 2' },
      ],
      keyTransmission: [
        { id: 1, name: 'Automatic' },
        { id: 2, name: 'Manual' },
      ],
      keyModalYear: [
        { id: 1, name: 2018 },
        { id: 2, name: 2020 },
      ],
      keyKilometers: [
        { id: 1, name: '10.000 Miles' },
        { id: 2, name: '20.000 Miles' },
      ],
      keyCarSize: [
        { id: 1, name: 'Small' },
        { id: 2, name: 'large' },
      ],
      keyMechanicalCondition: [
        { id: 1, name: 'Minor Faults' },
        { id: 2, name: 'All fixed' },
      ],
      form: {
        category: this.props.breadCrumb,
        title: '',
        description: '',
        bodyCondition: '',
        additionalFuture: [],
        horsepower: '',
        fuelType: '',
        color: '',
        door: '',
        modal: '',
        manufacturer: '',
        price: '',
        manageBy: '',
        offeredBy: '',
        bodyType: '',
        transmission: '',
        modalYear: '',
        kilometers: '',
        carSize: '',
        mechanicalCondition: '',
        whatsapp: null,
        featureAds: false,
        terms: false,
        availability: {
          morning: [6, 10],
          afternoon: [13, 14],
          evening: [18, 20],
        },
      },
    };
  }

  async componentDidMount() {
    const res2 = await getClassifiedWarranty();
    const res3 = await getClassifiedAge();
    const res4 = await getClassifiedUsage();
    const res5 = await getClassifiedCondition();
    if (res2) {
      this.setState({ specificationWarranty: res2.data });
    }
    if (res3) {
      this.setState({ keyFeatureAge: res3.data });
    }
    if (res4) {
      this.setState({ keyFeatureUsage: res4.data });
    }
    if (res5) {
      this.setState({ keyFeatureCondition: res5.data });
    }
  }

  addDescriptionHandler = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleAdditionalFutures = (e) => {
    let additional = this.state.form.additionalFuture.concat(e.target.value);
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        additionalFuture: additional,
      },
    });
  };

  handleSpecification = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleKeyFeature = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleFeatureAds = (e) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        featureAds: e.target.checked,
      },
    });
  };

  handleTerms = (e) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        terms: e.target.checked,
      },
    });
  };

  updateTimeSlots = (morning, afternoon, evening) => {
    if (morning !== null) {
      if (afternoon !== null) {
        if (evening !== null) {
          this.setState({
            ...this.state,
            form: {
              ...this.state.form,
              availability: {
                ...this.state.form.availability,
                morning,
                afternoon,
                evening,
              },
            },
          });
        } else {
          this.setState({
            ...this.state,
            form: {
              ...this.state.form,
              availability: {
                ...this.state.form.availability,
                morning,
                afternoon,
              },
            },
          });
        }
      } else {
        this.setState({
          ...this.state,
          form: {
            ...this.state.form,
            availability: {
              ...this.state.form.availability,
              morning,
            },
          },
        });
      }
    } else {
      this.setState({
        ...this.state,
      });
    }
  };

  updateState = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  setWhatsapp = (e) => {
    console.log(e);
    if (e !== undefined) {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          whatsapp: e,
        },
      });
    }
  };

  previewForm = (e) => {
    this.setState({
      ...this.state,
      setComponent: null,
      preview: true,
    });
  };

  backForm = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      preview: false,
    });
  };

  render() {
    const BG = {
      backgroundImage: 'url(/assets/svg/DownArr.svg)',
    };
    return (
      <React.Fragment>
        <div className='PlaceAdsWrapper DBlock'>
          {!this.state.preview ? (
            <React.Fragment>
              <div className='TopBar DFlex'>
                <Link to='/place-your-ad/classified-ad' onClick={() => this.props.handleBack()}>
                  <i className='fas fa-long-arrow-alt-left icon'></i>
                </Link>
                <p className='mx-auto'>Place Your Ad</p>
              </div>
              <div className='Title DBlock'>
                <ul className='BreadCrumb DFlex justify-content-start'>
                  <li>Home </li>
                  <li>Place Your Ad</li>
                  <li>Motors </li>
                  {this.props.breadCrumb && this.props.breadCrumb.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
              </div>
              <div className='AdsFormOuterDiv DBlock'>
                <div className='FormHeading DFlex justify-content-start'>
                  <span className='CustomSpan'>1</span>
                  <h3>Sub category</h3>
                </div>
                <div className='FormDescription DBlock'>
                  <form action=''>
                    <div className='form-row'>
                      <div className='col-12 mb-3'>
                        <select name='age' style={BG}>
                          <option>{this.state.form.category[this.state.form.category.length - 1]}</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                <AddDescription
                  addDescriptionHandler={this.addDescriptionHandler}
                  title={this.state.form.title}
                  description={this.state.form.description}
                />
                <MotorsSpecification
                  specManufacturer={this.state.specManufacturer}
                  specModal={this.state.specModal}
                  specDoor={this.state.specDoor}
                  specColor={this.state.specColor}
                  specFuelType={this.state.specFuelType}
                  specHorsepower={this.state.specHorsepower}
                  specBodyCondition={this.state.specBodyCondition}
                  handleSpecification={this.handleSpecification}
                  bodyCondition={this.state.form.bodyCondition}
                  horsepower={this.state.form.horsepower}
                  fuelType={this.state.form.fuelType}
                  color={this.state.form.color}
                  door={this.state.form.door}
                  modal={this.state.form.modal}
                  manufacturer={this.state.form.manufacturer}
                  price={this.state.form.price}
                />
                <KeyFutures
                  keyManageBy={this.state.keyManageBy}
                  keyOfferedBy={this.state.keyOfferedBy}
                  keyBodyType={this.state.keyBodyType}
                  keyTransmission={this.state.keyTransmission}
                  keyModalYear={this.state.keyModalYear}
                  keyKilometers={this.state.keyKilometers}
                  keyCarSize={this.state.keyCarSize}
                  keyMechanicalCondition={this.state.keyMechanicalCondition}
                  handleKeyFeature={this.handleKeyFeature}
                  manageBy={this.state.form.manageBy}
                  offeredBy={this.state.form.offeredBy}
                  bodyType={this.state.form.bodyType}
                  transmission={this.state.form.transmission}
                  modalYear={this.state.form.modalYear}
                  kilometers={this.state.form.kilometers}
                  carSize={this.state.form.carSize}
                  mechanicalCondition={this.state.form.mechanicalCondition}
                />
                <AdditionalFuture
                  additionalFutures={this.state.additionalFutures}
                  handleAdditionalFutures={this.handleAdditionalFutures}
                  updateState={this.updateState}
                />
                <UploadImage updateState={this.updateState} />
                <MapLocation updateState={this.updateState} />
                <TimeSlots
                  updateTimeSlots={this.updateTimeSlots}
                  morning={this.state.form.availability.morning}
                  afternoon={this.state.form.availability.afternoon}
                  evening={this.state.form.availability.evening}
                />
                <WhatsappPermit setWhatsapp={this.setWhatsapp} whatsapp={this.state.form.whatsapp} />
                <form action='' className='DBlock'>
                  <div className='FormHeading DFlex justify-content-start'>
                    <span className='CustomSpan'>9</span>
                    <h3>
                      <FormControlLabel
                        labelPlacement='start'
                        control={
                          <Switch checked={this.state.featureAds} onChange={(e) => this.handleFeatureAds(e)} name='featureAds' color='primary' />
                        }
                        label='Do you want to make your Ad as Feature Ad?'
                      />
                    </h3>
                  </div>
                </form>
                <div className='FormHeading DFlex justify-content-start'>
                  <span className='CustomSpan'>10</span>
                  <h3>
                    <FormControlLabel
                      labelPlacement='start'
                      control={<Switch checked={this.state.form.terms} onChange={(e) => this.handleTerms(e)} name='terms' color='primary' />}
                      label='Agree the Terms and Conditions'
                    />
                  </h3>
                  <div className='NextBtnDiv DBlock text-center'>
                    <button className='NextBtn' onClick={this.previewForm}>
                      Preview your Ad
                    </button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='AdsFormOuterDiv DBlock p-0'>
                <PreviewComponent previewData={this.state.previewData} backForm={this.backForm} />
              </div>
            </React.Fragment>
          )}
          {/* <!-- Modal Submited --> */}
          <div
            className='modal fade'
            id='staticBackdropSubmit'
            data-backdrop='static'
            data-keyboard='false'
            tabIndex='-1'
            aria-labelledby='staticBackdropSubmitLabel'
            aria-hidden='true'
          >
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-body'>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                  {!this.state.agree ? (
                    <React.Fragment>
                      <div className='ModalInnerDiv DBlock'>
                        <div className='ModalText DBlock'>
                          <h6>Let’s stay safe!</h6>
                          <p> Your add will not go live if it is:</p>
                          <ul className='TextUl DBlock text-left'>
                            <li>
                              <span>1</span>Prohibited item or activity that violates UAE law
                            </li>
                            <li>
                              <span>2</span>Wrong category placement
                            </li>
                            <li>
                              <span>3</span>Placed multiple times, or placed in more than one category
                            </li>
                            <li>
                              <span>4</span>Fraudulent or misleading information
                            </li>
                            <li>
                              <span>5</span>For items located outside of UAE
                            </li>
                          </ul>
                          <p>
                            For more information, read our <span>terms and conditions </span>
                          </p>
                        </div>
                        <div className='ModalBtnDiv DFlex justify-content-center'>
                          <button className='ModalBtn' onClick={() => this.setState({ agree: true })}>
                            I agree
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className='ModalInnerDiv DBlock'>
                        <div className='ModalText DBlock'>
                          <h6>Congratulations!</h6>
                          <p>Your Add has been submitted successfully!</p>
                          <img src='/assets/svg/TickCircle.svg' className='Tick' alt='' />
                        </div>
                        <div className='ModalBtnDiv DFlex justify-content-center'>
                          <Link to='/place-your-ad' data-dismiss='modal' className='ModalBtn'>
                            View Ad research
                          </Link>
                          <Link to='/place-your-ad/classified-ad' data-dismiss='modal'>
                            Place another add
                          </Link>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MotorsMainComponent;

const additionalFutures = [
  {
    id: 1,
    label: 'Leather Seats',
    value: 'Leather Seats',
  },
  {
    id: 2,
    label: 'Bluetooh System',
    value: 'Bluetooh System',
  },
  {
    id: 3,
    label: 'DVD player',
    value: 'DVD player',
  },
  {
    id: 4,
    label: 'Sunroof',
    value: 'Sunroof',
  },
  {
    id: 5,
    label: 'Spoiler ',
    value: 'Spoiler ',
  },
  {
    id: 6,
    label: 'Keyless Entry ',
    value: 'Keyless Entry ',
  },
  {
    id: 7,
    label: 'Premiun Paints',
    value: 'Premiun Paints',
  },
  {
    id: 8,
    label: 'Parking Sensor',
    value: 'Parking Sensor',
  },
  {
    id: 9,
    label: 'Navigation System',
    value: 'Navigation System',
  },
  {
    id: 10,
    label: 'Air Conditioning',
    value: 'Air Conditioning',
  },
  {
    id: 11,
    label: 'Climate Control',
    value: 'Climate Control',
  },
  {
    id: 12,
    label: 'Premium Sound System',
    value: 'Premium Sound System',
  },
  {
    id: 13,
    label: '4 Wheel Drive',
    value: '4 Wheel Drive',
  },
  {
    id: 14,
    label: 'Brush Guard',
    value: 'Brush Guard',
  },
  {
    id: 15,
    label: 'Heated Seats',
    value: 'Heated Seats',
  },
  {
    id: 16,
    label: 'Alarm/Anti- Theft System',
    value: 'Alarm/Anti- Theft System',
  },
  {
    id: 17,
    label: 'CD player',
    value: 'CD player',
  },
  {
    id: 18,
    label: 'Moonroof',
    value: 'Moonroof',
  },
  {
    id: 19,
    label: 'All Wheel Steering',
    value: 'All Wheel Steering',
  },
  {
    id: 20,
    label: 'Dual Exhaust',
    value: 'Dual Exhaust',
  },
  {
    id: 21,
    label: 'Off-Road Kit',
    value: 'Off-Road Kit',
  },
  {
    id: 22,
    label: 'Anti-Lock Brakes/ABS',
    value: 'Anti-Lock Brakes/ABS',
  },
  {
    id: 23,
    label: 'Front Airbags',
    value: 'Front Airbags',
  },
  {
    id: 24,
    label: 'Performance Tyres',
    value: 'Performance Tyres',
  },
  {
    id: 25,
    label: 'Power Mirrors',
    value: 'Power Mirrors',
  },
  {
    id: 26,
    label: 'Body Kit',
    value: 'Body Kit',
  },
  {
    id: 27,
    label: 'Heat',
    value: 'Heat',
  },
  {
    id: 28,
    label: 'Power Steering',
    value: 'Power Steering',
  },
  {
    id: 29,
    label: 'Cassette Player',
    value: 'Cassette Player',
  },
  {
    id: 30,
    label: 'Keyless Start',
    value: 'Keyless Start',
  },
  {
    id: 31,
    label: 'AM/FM Radio',
    value: 'AM/FM Radio',
  },
  {
    id: 32,
    label: 'Cruise Control',
    value: 'Cruise Control',
  },
  {
    id: 33,
    label: 'N20 System',
    value: 'N20 System',
  },
  {
    id: 34,
    label: 'Aux Audio In ',
    value: 'Aux Audio In ',
  },
  {
    id: 35,
    label: 'Fog Lights',
    value: 'Fog Lights',
  },
  {
    id: 36,
    label: 'Off Road Tyre’s',
    value: 'Off Road Tyre’s',
  },
  {
    id: 37,
    label: 'Power Locks',
    value: 'Power Locks',
  },
  {
    id: 38,
    label: 'Premium Lights',
    value: 'Premium Lights',
  },
  {
    id: 39,
    label: 'Rear Wheel Drive',
    value: 'Rear Wheel Drive',
  },
  {
    id: 40,
    label: 'Power Seats',
    value: 'Power Seats',
  },
  {
    id: 41,
    label: 'Premium Paint',
    value: 'Premium Paint',
  },
  {
    id: 42,
    label: 'Satellite Radio',
    value: 'Satellite Radio',
  },
];
