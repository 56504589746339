export const previewApi = [
  {
    id: 1,
    img: [
      { id: 1, images: '/assets/images/Community1.png' },
      { id: 2, images: '/assets/images/Community1.png' },
      { id: 3, images: '/assets/images/Community1.png' },
      { id: 4, images: '/assets/images/Community1.png' },
    ],
    title: 'Body Builder',
    date: '10/12/2018',
    price: 500.0,
    location: 'Satwa, Dubai, UAE',
    gmail: 'info@gmail.com',
    call: '+91 (31) 476 4998',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    locationUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4714147521145!2d55.2592203153808!3d25.187320038281154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d097e4d24d%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2s!4v1597826682708!5m2!1sen!2s',
    station: ['DMCC', 'Tram Station', 'IBN Battuta'],
    zone: ['Mosque', 'Restaurant', 'Bar', 'Pharmacy', 'Supermarket'],
    description: [
      {
        id: 1,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers…',
      },
      {
        id: 2,
        text:
          'Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers. Baby carrier in perfect condition, this is one of the best carriers for babies, safe and comfy.Original box available.The model is ONE. Please whatsapp for details. No silly offers.',
      },
      {
        id: 3,
        text:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
      },
    ],
  },
];
