import React, { Component } from 'react';
import { communityItems, communityList, communityTrends } from './Api';
import GridViewComponent from './GridViewComponent';
import ListViewComponent from './ListViewComponent';

const selectBG = {
  backgroundImage: 'url(/assets/svg/UpDownArr.svg)',
};

const inputBG = {
  backgroundImage: 'url(/assets/svg/LocationIcon.svg)',
};

export class MainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'list',
      list: communityList,
      items: communityItems,
      itemsTrend: communityTrends,
    };
  }
  sortList(id) {
    const sortItem = this.state.list.find((list) => list.id === id);
    if (sortItem.subItem) {
      this.setState({
        list: sortItem.subItem,
      });
      console.log('Updated');
    } else {
      console.log('Not Updated');
      return this.state.list;
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className='MainComDiv DBlock'>
          <div className='SearchCom DBlock'>
            <h3>
              Join Our <span>Community</span>
            </h3>
            <div className='SearchDiv DBlock'>
              <form action='' className='form'>
                <div className='form-row'>
                  <div className='col-lg-3 mb-3'>
                    <select name='' style={selectBG}>
                      <option value=''>All types</option>
                      <option value=''>All types 1</option>
                      <option value=''>All types 2</option>
                    </select>
                  </div>
                  <div className='col-lg-3 mb-3'>
                    <select name='' style={selectBG}>
                      <option value=''>Price</option>
                      <option value=''>Price 1</option>
                      <option value=''>Price 2</option>
                    </select>
                  </div>
                  <div className='col-lg-3 mb-3'>
                    <input type='text' placeholder='Your Location' style={inputBG} />
                  </div>
                  <div className='col-lg-3 mb-3'>
                    <button className='SearchBtn' type='submit'>
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className='ListItemsDiv DBlock'>
            <ul className='ListItemUl DFlex justify-content-start'>
              {this.state.list &&
                this.state.list.length > 0 &&
                this.state.list.map((list, index) => (
                  <li key={index} id={list.id} onClick={() => this.sortList(list.id)}>
                    <img src={list.img} alt='' />
                    {list.text} {!list.item ? '' : `(${list.item})`}
                  </li>
                ))}
            </ul>
          </div>
          <div className='ItemsOuterDiv DBlock'>
            <div className='ItemText DBlock'>
              <ul className='BreadCrumb DFlex'>
                <li>Home </li>
                <li>Community</li>
              </ul>
              <p>23,456 Results in Community</p>
              <div className='Tabs DFlex'>
                <ul className='FilterTab DFlex justify-content-start w-auto'>
                  <li className='active'>All Ads</li>
                  <li>Recent Ads</li>
                  <li>Popular Ads</li>
                </ul>
                <ol className='GridTab DFlex justify-content-start w-auto' id='ViewTab'>
                  <li className={this.state.view === 'list' ? 'active' : ''} onClick={() => this.setState({ view: 'list' })}>
                    <i className='fas fa-grip-lines'></i>
                  </li>
                  <li className={this.state.view === 'grid' ? 'active' : ''} onClick={() => this.setState({ view: 'grid' })}>
                    <i className='fas fa-th'></i>
                  </li>
                </ol>
              </div>
            </div>
            <div className='ItemDesc DBlock'>
              <div className='ItemsDiv DBlock'>
                <h4>Most Popular Villas</h4>
                {(() => {
                  switch (this.state.view) {
                    case 'list':
                      return <ListViewComponent item={this.state.items} type='villas' />;
                    case 'grid':
                      return <GridViewComponent item={this.state.items} col={4} type='villas' />;
                    default:
                      return 'No Component Found!';
                  }
                })()}
              </div>
              <div className='ItemsDiv DBlock'>
                <h4>Trending in Business Centres</h4>
                {(() => {
                  switch (this.state.view) {
                    case 'list':
                      return <ListViewComponent item={this.state.itemsTrend} type='trend' />;
                    case 'grid':
                      return <GridViewComponent item={this.state.itemsTrend} col={4} type='trend' />;
                    default:
                      return 'No Component Found!';
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MainComponent;
