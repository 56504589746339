import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Link } from 'react-router-dom';
import './Form.scss';

const SignUp = () => {
  let [type, setType] = useState('password');
  const [radio] = useState('sign-in');
  const handleType = (e) => {
    switch (type) {
      case 'password':
        return setType((type = 'text'));
      case 'text':
        return setType((type = 'password'));
      default:
        return type;
    }
  };
  return (
    <React.Fragment>
      <div className='SignFormWrapper DBlock'>
        <div className='SignFormOuterDiv DBlock'>
          <div className='SignInfo DBlock'>
            <h3>Log in</h3>
            <div className='InfoBtn DFlex justify-content-center'>
              <button className='Google'>Google</button>
              <button className='Facebook'>Facebook</button>
            </div>
          </div>
          <div className='SignFormDiv DBlock'>
            <h3>Sign up</h3>
            <form action='' className='form'>
              <div className='form-row'>
                <div className='col-12 mb-3'>
                  <input type='text' placeholder='Full Name' name='' required />
                </div>
                <div className='col-12 mb-3'>
                  <input type='email' placeholder='Email' name='' required />
                </div>
                <div className='col-12 mb-3'>
                  <div className='PasswordDiv DBlock position-relative'>
                    <input type={type} placeholder='Password' name='' required />
                    <button className='ShowPassBtn' type='button' onClick={(e) => handleType(e)}>
                      <i className='far fa-eye icon'></i>
                    </button>
                  </div>
                </div>
                <div className='col-12 mb-3'>
                  <FormControlLabel value={radio} control={<Radio />} label='Keep me signed in' />
                </div>
                <div className='col-12 mb-3'>
                  <button className='SingUp SignBtn' type='submit'>
                    Sign up
                  </button>
                </div>
              </div>
            </form>
            <p>
              Already have an account? <Link to='/sign-in'>Sign in</Link>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignUp;
