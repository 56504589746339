import { Link } from 'react-router-dom';
import React from 'react';

const ClassifiedAds = ({ item, type, col = 2 }) => {
  const leftArrow = {
    backgroundImage: 'url(/assets/svg/LeftArrow.svg)',
  };
  const rightArrow = {
    backgroundImage: 'url(/assets/svg/RightArrow.svg)',
  };
  return (
    <React.Fragment>
      <div className='ListViewDiv DBlock'>
        {item.map((item, index) => (
          <div key={index} id={item} className='ListView DFlex'>
            <div className='ListImg DBlock'>
              <div id={`carouselExampleInterval${type}${index}`} className='carousel slide' data-ride='carousel'>
                <div className='carousel-inner'>
                  {item.img.map((img, index) => (
                    <div className={index === 0 ? 'carousel-item active' : 'carousel-item'} key={index} data-interval='2000'>
                      <Link to={`/classified/detail/${item.id}`}>
                        <img src={img.images} className='d-block w-100' alt='Item_Image' />
                      </Link>
                    </div>
                  ))}
                </div>
                <a className='carousel-control-prev' href={`#carouselExampleInterval${type}${index}`} role='button' data-slide='prev'>
                  <span className='carousel-control-prev-icon' style={leftArrow} aria-hidden='true'></span>
                  <span className='sr-only'>Previous</span>
                </a>
                <a className='carousel-control-next' href={`#carouselExampleInterval${type}${index}`} role='button' data-slide='next'>
                  <span className='carousel-control-next-icon' style={rightArrow} aria-hidden='true'></span>
                  <span className='sr-only'>Next</span>
                </a>
              </div>
            </div>
            <div className='ListText DBlock'>
              <div className='TextHeader DFlex'>
                <span>
                  <Link to={`/classified/detail/${item.id}`}>{item.title}</Link>
                  <small>{item.date}</small>
                </span>
                <strong>AED {item.price}</strong>
              </div>
              <div className='TextBody DBlock'>
                <p>{item.description[0].text}</p>
              </div>
              <div className='TextFooter DFlex'>
                <ol className='ListOl DBlock'>
                  <li>Usage: {item.usage} year</li>
                  <li>
                    <img src='assets/svg/LocIcon.svg' alt='' /> <b>{item.location}</b>
                  </li>
                </ol>
                <ul className='ListIconUl DFlex justify-content-start'>
                  <li>
                    <a href={`mailto:${item.gmail}`}>
                      <i className='far fa-envelope icon'></i>
                    </a>
                  </li>
                  <li>
                    <a href={`tel:${item.call}`}>
                      <i className='fas fa-phone-alt icon'></i>
                    </a>
                  </li>
                  <li>
                    <Link to='#!'>
                      <i className='far fa-heart heart'></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default ClassifiedAds;
