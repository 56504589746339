import React, { Component } from 'react';
import AddDescription from './AddDescription';
import KeyFutures from './KeyFutures';
import MapLocation from './MapLocation';
import SubCategories from './SubCategories';
import TimeSlots from './TimeSlots';
import UploadImage from './UploadImage';
import WhatsappPermit from './WhatsappPermit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import PreviewComponent from './PreviewComponent';
import { previewApi } from './PreviewApi';
import { Link } from 'react-router-dom';
import ClassifiedSpecification from './ClassifiedSpecification';
import {
  getClassifiedCategory,
  getClassifiedWarranty,
  getClassifiedAge,
  getClassifiedUsage,
  getClassifiedCondition,
} from '../../../../ApiData/GetData';

export class ClassifiedAds extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setComponent: 'SubCategories',
      subCategory: subCategory,
      preview: false,
      previewData: previewApi,
      agree: false,
      anotherSubCat: null,
      specificationWarranty: [
        { id: 1, name: 'Warranty 1' },
        { id: 2, name: 'Warranty 2' },
      ],
      keyFeatureAge: [
        { id: 1, name: 'Age 1' },
        { id: 2, name: 'Age 2' },
      ],
      keyFeatureUsage: [
        { id: 1, name: 'Usage 1' },
        { id: 2, name: 'Usage 2' },
      ],
      keyFeatureCondition: [
        { id: 1, name: 'Condition 1' },
        { id: 2, name: 'Condition 2' },
      ],
      form: {
        category: '',
        title: '',
        description: '',
        price: '',
        warranty: '',
        age: '',
        usage: '',
        condition: '',
        whatsapp: null,
        featureAds: false,
        terms: false,
        availability: {
          morning: null,
          afternoon: null,
          evening: null,
        },
      },
    };
  }

  // async componentDidMount() {
  //   const res1 = await getClassifiedCategory();
  //   const res2 = await getClassifiedWarranty();
  //   const res3 = await getClassifiedAge();
  //   const res4 = await getClassifiedUsage();
  //   const res5 = await getClassifiedCondition();
  //   if (res1) {
  //     this.setState({ subCategory: res1.data.data.find((catId) => catId.id === this.props.categoryId) });
  //   }
  //   if (res2) {
  //     this.setState({ specificationWarranty: res2.data });
  //   }
  //   if (res3) {
  //     this.setState({ keyFeatureAge: res3.data });
  //   }
  //   if (res4) {
  //     this.setState({ keyFeatureUsage: res4.data });
  //   }
  //   if (res5) {
  //     this.setState({ keyFeatureCondition: res5.data });
  //   }
  // }

  subCategoryHandle = (item) => {
    if (item.sub_menu && item.sub_menu.length > 0) {
      this.setState({
        anotherSubCat: item.sub_menu,
        subCategory: null,
      });
    } else {
      this.setState({
        setComponent: 'AddDescription',
        form: {
          ...this.state.form,
          category: item.name,
        },
      });
    }
  };

  addDescriptionHandler = ({ values }) => {
    this.setState({
      setComponent: 'MotorSpecification',
      form: {
        ...this.state.form,
        title: values.title,
        description: values.description,
      },
    });
  };

  handleSpecification = ({ values }) => {
    this.setState({
      setComponent: 'KeyFutures',
      form: {
        ...this.state.form,
        price: values.price,
        warranty: values.warranty,
      },
    });
  };

  handleKeyFeature = ({ values }) => {
    this.setState({
      setComponent: 'MapLocation',
      form: {
        ...this.state.form,
        age: values.age,
        usage: values.usage,
        condition: values.condition,
      },
    });
  };

  handleFeatureAds = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        featureAds: e.target.checked,
      },
    });
  };

  handleTerms = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        terms: e.target.checked,
      },
    });
  };

  featureSubmit = (e) => {
    this.setState({
      setComponent: 'TermsAndCondition',
    });
  };

  updateTimeSlots = (morning = null, afternoon = null, evening = null) => {
    console.log('Morning ' + morning);
    console.log('Afternoon ' + afternoon);
    console.log('Evening ' + evening);
    if (morning !== null) {
      if (afternoon !== null) {
        if (evening !== null) {
          this.setState({
            ...this.state,
            setComponent: 'WhatsappPermit',
            form: {
              ...this.state.form,
              availability: {
                ...this.state.form.availability,
                morning,
                afternoon,
                evening,
              },
            },
          });
        } else {
          this.setState({
            ...this.state,
            setComponent: 'WhatsappPermit',
            form: {
              ...this.state.form,
              availability: {
                ...this.state.form.availability,
                morning,
                afternoon,
              },
            },
          });
        }
      } else {
        this.setState({
          ...this.state,
          setComponent: 'WhatsappPermit',
          form: {
            ...this.state.form,
            availability: {
              ...this.state.form.availability,
              morning,
            },
          },
        });
      }
    } else {
      this.setState({
        ...this.state,
        setComponent: 'WhatsappPermit',
      });
    }
  };

  updateState = (e, number = null) => {
    if (number) {
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
        form: {
          ...this.state.form,
          whatsapp: number,
        },
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
      });
    }
  };

  previewForm = (e) => {
    this.setState({
      ...this.state,
      setComponent: null,
      preview: true,
    });
  };

  checkState() {
    switch (this.state.setComponent) {
      case 'SubCategories':
        return (
          <SubCategories subCategory={this.state.subCategory} anotherSubCat={this.state.anotherSubCat} subCategoryHandle={this.subCategoryHandle} />
        );
      case 'AddDescription':
        return <AddDescription addDescriptionHandler={this.addDescriptionHandler} />;
      case 'MotorSpecification':
        return <ClassifiedSpecification specificationWarranty={this.state.specificationWarranty} handleSpecification={this.handleSpecification} />;
      case 'KeyFutures':
        return (
          <KeyFutures
            keyFeatureAge={this.state.keyFeatureAge}
            keyFeatureUsage={this.state.keyFeatureUsage}
            keyFeatureCondition={this.state.keyFeatureCondition}
            handleKeyFeature={this.handleKeyFeature}
          />
        );
      case 'UploadImage':
        return <UploadImage updateState={this.updateState} />;
      case 'MapLocation':
        return <MapLocation updateState={this.updateState} />;
      case 'TimeSlots':
        return <TimeSlots updateTimeSlots={this.updateTimeSlots} />;
      case 'WhatsappPermit':
        return <WhatsappPermit updateState={this.updateState} />;
      case 'AdsFeature':
        return (
          <React.Fragment>
            <form action='' className='DBlock' onSubmit={(e) => this.featureSubmit(e)}>
              <div className='FormHeading DFlex justify-content-start'>
                <span className='CustomSpan'>9</span>
                <h3>
                  <FormControlLabel
                    labelPlacement='start'
                    control={<Switch checked={this.state.featureAds} onChange={(e) => this.handleFeatureAds(e)} name='featureAds' color='primary' />}
                    label='Do you want to make your Ad as Feature Ad?'
                  />
                </h3>
                <div className='NextBtnDiv DBlock text-center'>
                  <button className='NextBtn' type='submit'>
                    Next
                  </button>
                </div>
              </div>
            </form>
          </React.Fragment>
        );
      case 'TermsAndCondition':
        return (
          <React.Fragment>
            <div className='FormHeading DFlex justify-content-start'>
              <span className='CustomSpan'>10</span>
              <h3>
                <FormControlLabel
                  labelPlacement='start'
                  control={<Switch checked={this.state.form.terms} onChange={(e) => this.handleTerms(e)} name='terms' color='primary' />}
                  label='Agree the Terms and Conditions'
                />
              </h3>
              <div className='NextBtnDiv DBlock text-center'>
                <button className='NextBtn' onClick={this.previewForm}>
                  Preview your Ad
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      default:
        return this.state.setComponent;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className='PlaceAdsWrapper DBlock'>
          <div className='Title DBlock'>
            <h5>Place Your Ad </h5>
            <ul className='BreadCrumb DFlex justify-content-start'>
              <li>Home </li>
              <li>Place Your Ad</li>
              <li>Classifieds </li>
              <li>Baby Items </li>
            </ul>
          </div>
          <div className='AdsFormOuterDiv DBlock'>{this.state.setComponent !== null && this.checkState()}</div>
          <div className='AdsFormOuterDiv DBlock'>{this.state.preview && <PreviewComponent previewData={this.state.previewData} />}</div>
          {this.state.preview && (
            <React.Fragment>
              <div className='BtnDiv DFlex justify-content-center'>
                <button className='GoBack' onClick={(e) => this.setState({ setComponent: 'SubCategories', preview: false })}>
                  Go Back
                </button>
                <button className='Submit' data-toggle='modal' data-target='#staticBackdropSubmit'>
                  Submit
                </button>
              </div>
            </React.Fragment>
          )}
          {/* <!-- Modal Submited --> */}
          <div
            className='modal fade'
            id='staticBackdropSubmit'
            data-backdrop='static'
            data-keyboard='false'
            tabIndex='-1'
            aria-labelledby='staticBackdropSubmitLabel'
            aria-hidden='true'
          >
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-body'>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                  {!this.state.agree ? (
                    <React.Fragment>
                      <div className='ModalInnerDiv DBlock'>
                        <div className='ModalText DBlock'>
                          <h6>Let’s stay safe!</h6>
                          <p> Your add will not go live if it is:</p>
                          <ul className='TextUl DBlock text-left'>
                            <li>
                              <span>1</span>Prohibited item or activity that violates UAE law
                            </li>
                            <li>
                              <span>2</span>Wrong category placement
                            </li>
                            <li>
                              <span>3</span>Placed multiple times, or placed in more than one category
                            </li>
                            <li>
                              <span>4</span>Fraudulent or misleading information
                            </li>
                            <li>
                              <span>5</span>For items located outside of UAE
                            </li>
                          </ul>
                          <p>
                            For more information, read our <span>terms and conditions </span>
                          </p>
                        </div>
                        <div className='ModalBtnDiv DFlex justify-content-center'>
                          <button className='ModalBtn' onClick={() => this.setState({ agree: true })}>
                            I agree
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className='ModalInnerDiv DBlock'>
                        <div className='ModalText DBlock'>
                          <h6>Congratulations!</h6>
                          <p>Your Add has been submitted successfully!</p>
                          <img src='/assets/svg/TickCircle.svg' className='Tick' alt='' />
                        </div>
                        <div className='ModalBtnDiv DFlex justify-content-center'>
                          <Link to='#!' className='ModalBtn'>
                            View Ad research
                          </Link>
                          <Link to='#!'>Place another add</Link>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ClassifiedAds;

const subCategory = [
  {
    id: 1,
    text: 'Baby Items',
    sub_menu: [
      {
        id: 1,
        text: 'Baby Sub Items 1',
      },
      {
        id: 2,
        text: 'Baby Sub Items 2',
      },
    ],
  },
  {
    id: 2,
    text: 'Books',
  },
  {
    id: 3,
    text: 'Business & Industrial',
  },
  {
    id: 4,
    text: 'Camaras & Imaging',
  },
  {
    id: 5,
    text: 'Clothing & Accessories',
  },
  {
    id: 6,
    text: 'Collectibles',
  },
  {
    id: 7,
    text: 'Computers & Networking',
  },
  {
    id: 8,
    text: 'DVD’s & Movies',
  },
  {
    id: 9,
    text: 'Electronics',
  },
  {
    id: 10,
    text: 'Free stuff',
  },
  {
    id: 11,
    text: 'Furniture, Home & Garden',
  },
  {
    id: 12,
    text: 'Gaming',
  },
  {
    id: 13,
    text: 'Home Appliances',
  },
  {
    id: 14,
    text: 'Jewelry & Watches',
  },
  {
    id: 15,
    text: 'Mobile Phones & PDAs',
  },
  {
    id: 16,
    text: 'Music',
  },
  {
    id: 17,
    text: 'Pets',
  },
  {
    id: 18,
    text: 'Sports Equipment',
  },
  {
    id: 19,
    text: 'Stuff Wanted',
  },
  {
    id: 20,
    text: 'Tickets & Vouchers',
  },
  {
    id: 21,
    text: 'Toys',
  },
  {
    id: 22,
    text: 'Misc',
  },
];
