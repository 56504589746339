import React, { Component } from 'react';
import AddDescription from './AddDescription';
import KeyFutures from './KeyFutures';
import MapLocation from './MapLocation';
import TimeSlots from './TimeSlots';
import UploadImage from './UploadImage';
import WhatsappPermit from './WhatsappPermit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import PreviewComponent from './PreviewComponent';
import { previewApi } from './PreviewApi';
import { Link } from 'react-router-dom';
import PropertiesSpecification from './PropertiesSpecification';
import { AdditionalFuture } from './AdditionalFuture';

export class PropertiesMainComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: false,
      previewData: previewApi,
      additionalFutures: additionalFutures,
      agree: false,
      anotherSubCat: null,
      propType: [
        { id: 1, name: 'Residential 1' },
        { id: 2, name: 'Residential 2' },
      ],
      propVariety: [
        { id: 1, name: 'Villa 1' },
        { id: 2, name: 'Villa 2' },
      ],
      propOfferedBy: [
        { id: 1, name: 'Owner 1' },
        { id: 2, name: 'Owner 2' },
      ],
      keyLifeStyle: [
        { id: 1, name: 'Family 1' },
        { id: 2, name: 'Family 2' },
      ],
      keyBathroom: [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
      ],
      keyFurniture: [
        { id: 1, name: 'Fully Furnished ' },
        { id: 2, name: 'Half Furnished ' },
      ],
      keyParkingLot: [
        { id: 1, name: 'Yes' },
        { id: 2, name: 'No' },
      ],
      keyBedroom: [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
      ],
      form: {
        category: this.props.breadCrumb,
        title: '',
        description: '',
        amenities: [],
        price: '',
        type: '',
        variety: '',
        size: '',
        offeredBy: '',
        furniture: '',
        lifeStyle: '',
        parkingLot: '',
        bedroom: '',
        bathroom: '',
        whatsapp: null,
        featureAds: false,
        availability: {
          morning: [6, 10],
          afternoon: [13, 14],
          evening: [18, 20],
        },
      },
    };
  }

  addDescriptionHandler = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSpecification = (e) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleKeyFeature = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleFeatureAds = (e) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        featureAds: e.target.checked,
      },
    });
  };

  handleTerms = (e) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        terms: e.target.checked,
      },
    });
  };

  updateTimeSlots = (morning, afternoon, evening) => {
    if (morning !== null) {
      if (afternoon !== null) {
        if (evening !== null) {
          this.setState({
            ...this.state,
            form: {
              ...this.state.form,
              availability: {
                ...this.state.form.availability,
                morning,
                afternoon,
                evening,
              },
            },
          });
        } else {
          this.setState({
            ...this.state,
            form: {
              ...this.state.form,
              availability: {
                ...this.state.form.availability,
                morning,
                afternoon,
              },
            },
          });
        }
      } else {
        this.setState({
          ...this.state,
          form: {
            ...this.state.form,
            availability: {
              ...this.state.form.availability,
              morning,
            },
          },
        });
      }
    } else {
      this.setState({
        ...this.state,
      });
    }
  };

  updateState = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  handleAdditionalFutures = (e) => {
    let additional = this.state.form.amenities.concat(e.target.value);
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        amenities: additional,
      },
    });
  };

  setWhatsapp = (e) => {
    if (e !== undefined) {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          whatsapp: e,
        },
      });
    }
  };

  previewForm = (e) => {
    this.setState({
      ...this.state,
      setComponent: null,
      preview: true,
    });
  };

  backForm = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      preview: false,
    });
  };

  render() {
    const BG = {
      backgroundImage: 'url(/assets/svg/DownArr.svg)',
    };
    return (
      <React.Fragment>
        <div className='PlaceAdsWrapper DBlock'>
          {!this.state.preview ? (
            <React.Fragment>
              <div className='TopBar DFlex'>
                <Link to='/place-your-ad/classified-ad' onClick={() => this.props.handleBack()}>
                  <i className='fas fa-long-arrow-alt-left icon'></i>
                </Link>
                <p className='mx-auto'>Place Your Ad</p>
              </div>
              <div className='Title DBlock'>
                <ul className='BreadCrumb DFlex justify-content-start'>
                  <li>Home </li>
                  <li>Place Your Ad</li>
                  <li>Classifieds </li>
                  {this.props.breadCrumb && this.props.breadCrumb.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
              </div>
              <div className='AdsFormOuterDiv DBlock'>
                <div className='FormHeading DFlex justify-content-start'>
                  <span className='CustomSpan'>1</span>
                  <h3>Sub category</h3>
                </div>
                <div className='FormDescription DBlock'>
                  <form action=''>
                    <div className='form-row'>
                      <div className='col-12 mb-3'>
                        <select name='age' style={BG}>
                          <option>{this.state.form.category[this.state.form.category.length - 1]}</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                <AddDescription
                  addDescriptionHandler={this.addDescriptionHandler}
                  title={this.state.form.title}
                  description={this.state.form.description}
                />
                <PropertiesSpecification
                  propType={this.state.propType}
                  propVariety={this.state.propVariety}
                  propOfferedBy={this.state.propOfferedBy}
                  handleSpecification={this.handleSpecification}
                  price={this.state.form.price}
                  type={this.state.form.type}
                  variety={this.state.form.variety}
                  size={this.state.form.size}
                  offeredBy={this.state.form.offeredBy}
                />
                <KeyFutures
                  keyLifeStyle={this.state.keyLifeStyle}
                  keyBathroom={this.state.keyBathroom}
                  keyFurniture={this.state.keyFurniture}
                  keyParkingLot={this.state.keyParkingLot}
                  keyBedroom={this.state.keyBedroom}
                  handleKeyFeature={this.handleKeyFeature}
                  furniture={this.state.form.age}
                  lifeStyle={this.state.form.age}
                  parkingLot={this.state.form.age}
                  bedroom={this.state.form.age}
                  bathroom={this.state.form.age}
                />
                <AdditionalFuture additionalFutures={this.state.additionalFutures} handleAdditionalFutures={this.handleAdditionalFutures} />
                <UploadImage updateState={this.updateState} />
                <MapLocation updateState={this.updateState} />
                <TimeSlots
                  updateTimeSlots={this.updateTimeSlots}
                  morning={this.state.form.availability.morning}
                  afternoon={this.state.form.availability.afternoon}
                  evening={this.state.form.availability.evening}
                />
                <WhatsappPermit setWhatsapp={this.setWhatsapp} whatsapp={this.state.form.whatsapp} />
                <form action='' className='DBlock'>
                  <div className='FormHeading DFlex justify-content-start'>
                    <span className='CustomSpan'>9</span>
                    <h3>
                      <FormControlLabel
                        labelPlacement='start'
                        control={
                          <Switch checked={this.state.featureAds} onChange={(e) => this.handleFeatureAds(e)} name='featureAds' color='primary' />
                        }
                        label='Do you want to make your Ad as Feature Ad?'
                      />
                    </h3>
                    <div className='NextBtnDiv DBlock text-center'>
                      <button className='NextBtn' onClick={this.previewForm}>
                        Preview your Ad
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='AdsFormOuterDiv DBlock p-0'>
                <PreviewComponent previewData={this.state.previewData} backForm={this.backForm} />
              </div>
            </React.Fragment>
          )}
          {/* <!-- Modal Submited --> */}
          <div
            className='modal fade'
            id='staticBackdropSubmit'
            data-backdrop='static'
            data-keyboard='false'
            tabIndex='-1'
            aria-labelledby='staticBackdropSubmitLabel'
            aria-hidden='true'
          >
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-body'>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                  {!this.state.agree ? (
                    <React.Fragment>
                      <div className='ModalInnerDiv DBlock'>
                        <div className='ModalText DBlock'>
                          <h6>Let’s stay safe!</h6>
                          <p> Your add will not go live if it is:</p>
                          <ul className='TextUl DBlock text-left'>
                            <li>
                              <span>1</span>Prohibited item or activity that violates UAE law
                            </li>
                            <li>
                              <span>2</span>Wrong category placement
                            </li>
                            <li>
                              <span>3</span>Placed multiple times, or placed in more than one category
                            </li>
                            <li>
                              <span>4</span>Fraudulent or misleading information
                            </li>
                            <li>
                              <span>5</span>For items located outside of UAE
                            </li>
                          </ul>
                          <p>
                            For more information, read our <span>terms and conditions </span>
                          </p>
                        </div>
                        <div className='ModalBtnDiv DFlex justify-content-center'>
                          <button className='ModalBtn' onClick={() => this.setState({ agree: true })}>
                            I agree
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className='ModalInnerDiv DBlock'>
                        <div className='ModalText DBlock'>
                          <h6>Congratulations!</h6>
                          <p>Your Add has been submitted successfully!</p>
                          <img src='/assets/svg/TickCircle.svg' className='Tick' alt='' />
                        </div>
                        <div className='ModalBtnDiv DFlex justify-content-center'>
                          <Link to='/place-your-ad' data-dismiss='modal' className='ModalBtn'>
                            View Ad research
                          </Link>
                          <Link to='/place-your-ad/classified-ad' data-dismiss='modal'>
                            Place another add
                          </Link>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PropertiesMainComponent;

const additionalFutures = [
  {
    id: 1,
    label: 'Balcony',
    value: 'Balcony',
  },
  {
    id: 2,
    label: 'Built in Kitchen Appliances',
    value: 'Built in Kitchen Appliances',
  },
  {
    id: 3,
    label: 'Builtin Wardrobs',
    value: 'Builtin Wardrobs',
  },
  {
    id: 4,
    label: 'Central A/C & Heating ',
    value: 'Central A/C & Heating ',
  },
  {
    id: 5,
    label: 'Concierge Service  ',
    value: 'Concierge Service  ',
  },
  {
    id: 6,
    label: 'Covered Parking',
    value: 'Covered Parking',
  },
  {
    id: 7,
    label: 'Maids Room',
    value: 'Maids Room',
  },
  {
    id: 8,
    label: 'Maid Service',
    value: 'Maid Service',
  },
  {
    id: 9,
    label: 'Pets Allowed',
    value: 'Pets Allowed',
  },
  {
    id: 10,
    label: 'Private Garden',
    value: 'Private Garden',
  },
  {
    id: 11,
    label: 'Private Gym',
    value: 'Private Gym',
  },
  {
    id: 12,
    label: 'Private Jacuzzi',
    value: 'Private Jacuzzi',
  },
  {
    id: 13,
    label: 'Private Pool',
    value: 'Private Pool',
  },
  {
    id: 14,
    label: 'Security 24/7',
    value: 'Security 24/7',
  },
  {
    id: 15,
    label: 'Shared Gym',
    value: 'Shared Gym',
  },
  {
    id: 16,
    label: 'Shared Pool',
    value: 'Shared Pool',
  },
  {
    id: 17,
    label: 'Shared Spa',
    value: 'Shared Spa',
  },
  {
    id: 18,
    label: 'Study',
    value: 'Study',
  },
  {
    id: 19,
    label: 'View of Landmark',
    value: 'View of Landmark',
  },
  {
    id: 20,
    label: 'View fo Water',
    value: 'View fo Water',
  },
  {
    id: 21,
    label: 'Walk-in Closet',
    value: 'Walk-in Closet',
  },
];
