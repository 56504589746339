import React from 'react';

const KeySpecification = ({ updateState }) => {
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>3</span>
        <h3>
          Key Specification <small>(key specifications detail)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Price:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <input type='text' placeholder='10.000.00 AED' />
            </div>
          </div>
        </form>
        <div className='NextBtnDiv DBlock text-center'>
          <button className='NextBtn' name='setComponent' value='UploadImage' onClick={(e) => updateState(e)}>
            Next
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default KeySpecification;
