import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export class AdditionalFuture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      futures: props.additionalFutures,
      viewNum: 12,
      fullView: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className='FormHeading DFlex justify-content-start'>
          <span className='CustomSpan'>5</span>
          <h3>Amenities</h3>
        </div>
        <div className='FormDescription DBlock'>
          <ul className='AddFutureUl DFlex justify-content-start'>
            {this.state.futures &&
              this.state.futures.slice(0, this.state.viewNum).map((future, index) => (
                <li key={index} data-id={future.id}>
                  <FormControlLabel value={future.value} control={<Radio />} label={future.label} />
                </li>
              ))}
          </ul>
          <div className='ViewBtn DBlock'>
            {this.state.fullView ? (
              <button onClick={() => this.setState({ viewNum: 12, fullView: false })}>Show less</button>
            ) : (
              <button onClick={() => this.setState({ viewNum: this.state.futures.length, fullView: true })}>Show all</button>
            )}
          </div>
          <div className='NextBtnDiv DBlock text-center'>
            <button className='NextBtn' name='setComponent' value='UploadImage' onClick={(e) => this.props.updateState(e)}>
              Next
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AdditionalFuture;
