import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const employment = ['Full-time', 'Part-time'];
const contract = ['Temporary', 'Commission', 'Contract', 'New - Graduate', 'Internship', 'Permanent'];
const joinDate = ['Yes', 'No'];

const JobDetails = () => {
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const [employmentType, setEmploymentType] = React.useState('Full-time');
  const [jobContract, setJobContract] = React.useState('Temporary');
  const [startDate, setStartDate] = React.useState('Yes');

  const handleEmployment = (event) => {
    setEmploymentType(event.target.value);
  };
  const handleJobContract = (event) => {
    setJobContract(event.target.value);
  };
  const handleDate = (event) => {
    setStartDate(event.target.value);
  };

  return (
    <div className='FormInnerDiv DBlock'>
      <h4>Job Info</h4>
      <form action=''>
        <div className='form-row'>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Type of Employment:</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <Button aria-controls='simple-menu1' aria-haspopup='true' onClick={(e) => setAnchorEl1(e.currentTarget)}>
              Select
            </Button>
            <Menu id='simple-menu1' anchorEl={anchorEl1} keepMounted open={Boolean(anchorEl1)} onClose={(e) => setAnchorEl1(null)}>
              <RadioGroup aria-label='employment' name='employment' value={employmentType} onChange={handleEmployment}>
                {employment.map((emp, i) => (
                  <FormControlLabel key={i} value={emp} control={<Radio />} label={emp} />
                ))}
              </RadioGroup>
            </Menu>
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Contract Type:</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <Button aria-controls='simple-menu2' aria-haspopup='true' onClick={(e) => setAnchorEl2(e.currentTarget)}>
              Select
            </Button>
            <Menu id='simple-menu2' anchorEl={anchorEl2} keepMounted open={Boolean(anchorEl2)} onClose={(e) => setAnchorEl2(null)}>
              <RadioGroup aria-label='contract' name='contract' value={jobContract} onChange={handleJobContract}>
                {contract.map((contract, i) => (
                  <FormControlLabel key={i} value={contract} control={<Radio />} label={contract} />
                ))}
              </RadioGroup>
            </Menu>
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Planed Date to Start:</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <Button aria-controls='simple-menu3' aria-haspopup='true' onClick={(e) => setAnchorEl3(e.currentTarget)}>
              Select
            </Button>
            <Menu id='simple-menu3' anchorEl={anchorEl3} keepMounted open={Boolean(anchorEl3)} onClose={(e) => setAnchorEl3(null)}>
              <RadioGroup aria-label='joinDate' name='joinDate' value={startDate} onChange={handleDate}>
                {joinDate.map((date, i) => (
                  <FormControlLabel key={i} value={date} control={<Radio />} label={date} />
                ))}
              </RadioGroup>
            </Menu>
          </div>
        </div>
      </form>
    </div>
  );
};

export default JobDetails;
