import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

function valuetext(value) {
  return `${value}°C`;
}

const TimeSlots = ({ updateState }) => {
  const classes = useStyles();
  const [morning, setMorning] = React.useState([0, 100]);
  const [evening, setEvening] = React.useState([30, 90]);
  const [afternoon, setAfternoon] = React.useState([50, 80]);
  const handleMorning = (event, newValue) => {
    setMorning(newValue);
  };
  const handleEvening = (event, newValue) => {
    setEvening(newValue);
  };
  const handleAfternoon = (event, newValue) => {
    setAfternoon(newValue);
  };
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>8</span>
        <h3>
          Available Time Slots <small>(Inspection)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Morning: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <div className={`w-100 ${classes.root}`}>
                <Slider
                  value={morning}
                  onChange={handleMorning}
                  valueLabelDisplay='auto'
                  aria-labelledby='range-slider'
                  getAriaValueText={valuetext}
                />
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Afternoon: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <div className={`w-100 ${classes.root}`}>
                <Slider
                  value={afternoon}
                  onChange={handleAfternoon}
                  valueLabelDisplay='auto'
                  aria-labelledby='range-slider'
                  getAriaValueText={valuetext}
                />
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Evening: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <div className={`w-100 ${classes.root}`}>
                <Slider
                  value={evening}
                  onChange={handleEvening}
                  valueLabelDisplay='auto'
                  aria-labelledby='range-slider'
                  getAriaValueText={valuetext}
                />
              </div>
            </div>
          </div>
        </form>
        <div className='NextBtnDiv DBlock text-center'>
          <button className='NextBtn' name='setComponent' value='WhatsappPermit' onClick={(e) => updateState(e)}>
            Next
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TimeSlots;
