import React from 'react';
import { jobsItems } from '../searchJob/Api';

const Preview = () => {
  return (
    <div className='DetailsComponent DBlock shadow-none px-0'>
      <ul className='BreadCrumbUl DFlex justify-content-start'>
        <li>Home </li>
        <li>Jobs</li>
        <li>Job Seeker</li>
        <li>UI/UX Designer/ Citi Bank</li>
      </ul>
      <div className='JobDetailsInnerDiv DBlock'>
        <div className='JobDetImg DBlock'>
          <img src={jobsItems[0].img[0].images} className='JobImg' alt='' />
        </div>
        <h3>Job Tittle</h3>
        <ul className='JobUl DBlock'>
          <li>
            Company Name:<span>{jobsItems[0].name}</span>
          </li>
          <li>
            Email:<span>{jobsItems[0].gmail}</span>
          </li>
          <li>
            Phone Number:<span>{jobsItems[0].call}</span>
          </li>
          <li>
            Location:<span>{jobsItems[0].location}</span>
          </li>
        </ul>
        <h3>Job Details</h3>
        <ul className='JobUl DBlock'>
          <li>
            Type of employment:<span>{jobsItems[0].employmentType}</span>
          </li>
          <li>
            Contract Type:<span>{jobsItems[0].contract}</span>
          </li>
          <li>
            Planed date to start:<span>{jobsItems[0].startDate}</span>
          </li>
        </ul>
        <h3>Salary</h3>
        <ul className='JobUl DBlock'>
          <li>
            Amount:<span>{jobsItems[0].Amount}</span>
          </li>
          <li>
            Salary Type:<span>{jobsItems[0].salaryType}</span>
          </li>
        </ul>
        <h3>Job Description</h3>
        <div className='JobDescription DBlock mb-0'>
          {jobsItems[0].description && jobsItems[0].description !== null ? (
            <React.Fragment>
              {jobsItems[0].description.map((text, index) => (
                <p key={text.id}>{text.text}</p>
              ))}
            </React.Fragment>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Preview;
