import React from 'react';

const AddDescription = ({ updateState }) => {
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>2</span>
        <h3>
          Ad Description <small>(Basic Information about your Ad)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Ad Title: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <input type='text' placeholder='Name + Surname' />
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Ad Description: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <textarea name='' id='' placeholder='Description'></textarea>
            </div>
          </div>
        </form>
        <div className='NextBtnDiv DBlock text-center'>
          <button className='NextBtn' name='setComponent' value='MotorSpecification' onClick={(e) => updateState(e)}>
            Next
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddDescription;
