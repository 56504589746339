import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { candidatesItems } from '../Api';
import SearchForm from '../SearchForm';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ['Call Candidate', 'Message Candidate', 'Print'];

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

function MainDetailsComponent(props) {
  const classes = useStyles();
  const theme = useTheme();
  let { id } = useParams();
  const detailItem = candidatesItems.findIndex((item) => item.id === parseInt(id));
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  return (
    <React.Fragment>
      <div className='MainComDiv DBlock'>
        <Link to='/candidate-search' className='GoBack'>
          Back to Candidate listings
        </Link>
        <SearchForm />
        {detailItem !== -1 ? (
          <div className='CandidateDetailDiv DBlock'>
            <div className='Title DBlock'>
              <h3>Candidate</h3>
            </div>
            <ul className='BreadCrumbUl DFlex justify-content-start'>
              <li>Home </li>
              <li>Jobs</li>
              <li>Job Employer</li>
              <li>Search Candidate</li>
            </ul>
            <div className='CandidateInfo DFlex'>
              <div className='CandidateImg DBlock'>
                <img src={candidatesItems[detailItem].img[0].images} alt='' className='' />
              </div>
              <div className='CandidateText DBlock'>
                <div className='InfoTitle DFlex'>
                  <div className='CandInfo DBlock'>
                    <h2>{candidatesItems[detailItem].name}</h2>
                    <p>{candidatesItems[detailItem].title}</p>
                  </div>
                  <ul className='InfoUl DFlex'>
                    <li>
                      <a href='#!'>
                        <i className='fas fa-share-alt icon'></i>
                      </a>
                    </li>
                    <li>
                      <a href='#!'>
                        <i className='far fa-heart icon heart'></i>
                      </a>
                    </li>
                    <li>
                      <FormControl className={clsx(classes.formControl, classes.noLabel)}>
                        <Select
                          multiple
                          displayEmpty
                          value={personName}
                          onChange={handleChange}
                          input={<Input />}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <em>Action</em>;
                            }

                            return selected.join(', ');
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem disabled value=''>
                            <em>Action</em>
                          </MenuItem>
                          {names.map((name) => (
                            <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
                              <Checkbox checked={personName.indexOf(name) > -1} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </li>
                  </ul>
                </div>
                <ul className='EduUl DFlex'>
                  <li>
                    Age:<span>{candidatesItems[detailItem].age}</span>
                  </li>
                  <li>
                    Gender:<span>{candidatesItems[detailItem].gender}</span>
                  </li>
                  <li>
                    Nationality:
                    <span>{candidatesItems[detailItem].nationality}</span>
                  </li>
                  <li>
                    Residence:
                    <span>{candidatesItems[detailItem].residence}</span>
                  </li>
                </ul>
                <ol className='SocailOl DBlock'>
                  <li>
                    Email:
                    <span>{candidatesItems[detailItem].email}</span>
                  </li>
                  <li>
                    Linkedin Profie:
                    <span>{candidatesItems[detailItem].linkedin}</span>
                  </li>
                  <li>
                    Total Experience:
                    <span>{candidatesItems[detailItem].totalExperience} year's</span>
                  </li>
                </ol>
              </div>
            </div>
            <div className='CandidateDetail DBlock'>
              <h4>Sumary</h4>
              <p>{candidatesItems[detailItem].summary}</p>
              <h4>Experience</h4>
              {candidatesItems[detailItem].experience.map((exp, i) => (
                <ul className='DetUl DBlock' key={i}>
                  <li>{exp.jobTitle}</li>
                  <li>{exp.company}</li>
                  <li>{exp.date}</li>
                </ul>
              ))}
              <h4>Job Description</h4>
              {candidatesItems[detailItem].description.map((text, i) => (
                <p key={i}>{text.text}</p>
              ))}
              <h4>Education</h4>
              {candidatesItems[detailItem].education.map((edu, i) => (
                <ul className='DetUl DBlock' key={i}>
                  <li>{edu.degree}</li>
                  <li>{edu.school}</li>
                  <li>{edu.date}</li>
                </ul>
              ))}
              <h4>Licenses</h4>
              <div className='ListDiv DFlex justify-content-start'>
                {candidatesItems[detailItem].license.map((license, i) => (
                  <span key={i}>{license},</span>
                ))}
              </div>
              <h4>Skills</h4>
              <div className='ListDiv DFlex justify-content-start'>
                {candidatesItems[detailItem].skills.map((skill, i) => (
                  <span key={i}>{skill},</span>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className='NotFound DBlock'>
            <h3>Opps Your Items is not found!</h3>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default MainDetailsComponent;
