import React from 'react';
import { relatedItems } from '../Api';
import GridViewComponent from '../GridViewComponent';

function RightSidebarDetails() {
  const [item] = React.useState(relatedItems);
  return (
    <React.Fragment>
      <div className='RightSideDiv DBlock'>
        <div className='RightSideSearch DBlock p-0 BorderRad'>
          <img src='/assets/images/ChatMsg.png' className='w-100' alt='' />
        </div>
        <div className='SocialLinksDiv DBlock h-auto text-center'>
          <p>Share with friends</p>
          <ul className='SocialLinks DFlex justify-content-center'>
            <li>
              <a href='https://twitter.com/?lang=en' target='blank'>
                <img src='/assets/images/Twitter.png' alt='' />
              </a>
            </li>
            <li>
              <a href='https://www.facebook.com/' target='blank'>
                <img src='/assets/images/Facebook.png' alt='' />
              </a>
            </li>
            <li>
              <a href='https://www.instagram.com/?hl=en' target='blank'>
                <img src='/assets/images/Instagram.png' alt='' />
              </a>
            </li>
            <li>
              <a href='https://www.whatsapp.com/' target='blank'>
                <img src='/assets/images/WhatsApp.png' alt='' />
              </a>
            </li>
          </ul>
        </div>
        <div className='RelatedSearch DBlock'>
          <h4>Related Search </h4>
          <GridViewComponent item={item} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default RightSidebarDetails;
