import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import LeftSidebar from '../LeftSidebar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { subCategories } from '../searchJob/Api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#26326c',
    borderRadius: '25px',
  },
}));

const options = ['CV 1', 'CV 2', 'CV 3'];

const PostCV = () => {
  const classes = useStyles();
  const history = useHistory();
  const [category, setCategory] = React.useState(subCategories);
  const [hasCV, setHasCV] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const BG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div className='JobsCom DBlock position-relative'>
        <div className='CompDiv DFlex'>
          <div className='LeftSideBar DBlock'>
            <LeftSidebar />
          </div>
          <div className='MainComponent DBlock mx-auto shadow-none p-0'>
            <div className='PostCVDiv DBlock'>
              <ul className='BreadCrumbUl DFlex justify-content-start'>
                <li>Home </li>
                <li>Jobs</li>
                <li>Job Seeker</li>
                <li>Post CV</li>
              </ul>
              <div className='PostArea DBlock'>
                <h3>Post CV</h3>
                <div className='PostCategoryDiv DBlock text-center'>
                  <h2>Choose a subcategory</h2>
                  <select name='category' id='' style={BG}>
                    {category &&
                      category.map((category, index) => (
                        <option value={category.text} key={category.id}>
                          {category.text}
                        </option>
                      ))}
                  </select>
                </div>
                <ul className='JobModalBodyUl DFlex justify-content-center'>
                  <li>
                    <div className='UploadCv DFlex justify-content-center'>
                      <label htmlFor='resume'>Upload CV</label>
                      <input type='file' id='resume' name='resume' />
                    </div>
                  </li>
                  <li>
                    <button className='LinedIn'>Upload From Linkedin</button>
                  </li>
                  <li>
                    <Link to='/jobs/create-cv' onClick={() => history.push('/jobs/create-cv')} data-dismiss='modal'>
                      Create CV
                    </Link>
                  </li>
                  {hasCV && (
                    <React.Fragment>
                      <li>
                        <div className={classes.root}>
                          <List component='nav' className='p-0 bg-transparent shadow-none' aria-label='Device settings'>
                            <ListItem
                              button
                              aria-haspopup='true'
                              aria-controls='lock-menu'
                              aria-label='Upload Saved CV'
                              onClick={handleClickListItem}
                            >
                              <ListItemText primary='Upload Saved CV' secondary={options[selectedIndex]} />
                            </ListItem>
                          </List>
                          <Menu id='lock-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                            {options.map((option, index) => (
                              <MenuItem key={option} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
                                {option}
                              </MenuItem>
                            ))}
                          </Menu>
                        </div>
                      </li>
                    </React.Fragment>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PostCV;
