import { Link } from 'react-router-dom';
import React from 'react';

const JobsAds = ({ item, type }) => {
  const leftArrow = {
    backgroundImage: 'url(assets/svg/LeftArrow.svg)',
  };
  const rightArrow = {
    backgroundImage: 'url(assets/svg/RightArrow.svg)',
  };
  return (
    <React.Fragment>
      <div className='ListViewDiv DBlock'>
        {item.map((item, index) => (
          <div key={index} id={item.id} className='ListView DFlex'>
            <div className='ListImg DBlock'>
              <div id={`carouselExampleInterval${type}${index}`} className='carousel slide' data-ride='carousel'>
                <div className='carousel-inner'>
                  {item.img.map((img, index) => (
                    <div className={index === 0 ? 'carousel-item active' : 'carousel-item'} key={index} data-interval='2000'>
                      <Link to={`/job-search/job-detail/${item.id}`}>
                        <img src={img.images} className='d-block w-100' alt='Item_Image' />
                      </Link>
                    </div>
                  ))}
                </div>
                <a className='carousel-control-prev' href={`#carouselExampleInterval${type}${index}`} role='button' data-slide='prev'>
                  <span className='carousel-control-prev-icon' style={leftArrow} aria-hidden='true'></span>
                  <span className='sr-only'>Previous</span>
                </a>
                <a className='carousel-control-next' href={`#carouselExampleInterval${type}${index}`} role='button' data-slide='next'>
                  <span className='carousel-control-next-icon' style={rightArrow} aria-hidden='true'></span>
                  <span className='sr-only'>Next</span>
                </a>
              </div>
            </div>
            <div className='ListText DBlock'>
              <div className='TextHeader DFlex'>
                <span>
                  <Link to={`/job-search/job-detail/${item.id}`}>{item.title}</Link>
                  <small>{item.date}</small>
                </span>
              </div>
              <div className='TextBody DBlock'>
                <ul className='TextBodyUl DBlock'>
                  <li>
                    Type Of Employment:
                    <span>{item.employmentType}</span>
                  </li>
                  <li>
                    Salary:
                    <span>{item.salary}</span>
                  </li>
                  <li>
                    Field:
                    <span>{item.field}</span>
                  </li>
                  <li>
                    Location:
                    <span>{item.location}</span>
                  </li>
                </ul>
              </div>
              <div className='TextFooter DBlock'>
                <ul className='ListIconUl DFlex w-auto justify-content-end'>
                  <li>
                    <a href='#!'>
                      <i className='fas fa-share-alt icon'></i>
                    </a>
                  </li>
                  <li>
                    <a href='#!'>
                      <i className='far fa-heart icon heart'></i>
                    </a>
                  </li>
                  <li>
                    <Link className='ViewJob' to={`/job-search/job-detail/${item.id}`}>
                      View Job
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default JobsAds;
