import React from 'react';
import '../Motors.scss'
import LeftSidebar from '../LeftSidebar';
import MainDetailsComponent from './MainDetailsComponent';
import RightSidebarDetails from './RightSidebarDetails';

export default function MotorsDetails() {
  return (
    <React.Fragment>
      <div className='ClassifiedCom DBlock position-relative'>
        <div className='CompDiv DFlex'>
          <div className='LeftSideBar DBlock'>
            <LeftSidebar />
          </div>
          <div className='MainComponent DBlock'>
            <MainDetailsComponent />
          </div>
          <div className='RightSideBar DBlock'>
            <RightSidebarDetails />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
