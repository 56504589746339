import React from 'react';
import '../../Jobs.scss';
import LeftSidebar from '../LeftSidebar';
import MainDetailsComponent from './MainDetailsComponent';
import RightSidebarDetails from './RightSidebarDetails';

export default function SearchJobsDetails() {
  return (
    <React.Fragment>
      <div className='JobsCom DBlock position-relative'>
        <div className='CompDiv DFlex'>
          <div className='LeftSideBar DBlock'>
            <LeftSidebar />
          </div>
          <div className='MainComponent DBlock shadow-none'>
            <MainDetailsComponent />
          </div>
          <div className='RightSideBar DBlock'>
            <RightSidebarDetails />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
