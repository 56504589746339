import React from 'react';

const Summary = () => {
  return (
    <div className='FormInnerDiv DBlock'>
      <h4>Summary</h4>
      <form action=''>
        <div className='form-row'>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Add Summary: * </label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <textarea name='' placeholder='Enter Your Summary'></textarea>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Summary;
