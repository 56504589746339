import React from 'react';
import { Link } from 'react-router-dom';

const FilterComponent = ({ removeFilter }) => {
  const selectBG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };
  return (
    <React.Fragment>
      <div className='FilterDiv DBlock'>
        <div className='TopBar DFlex'>
          <Link to='#!' onClick={removeFilter}>
            <i className='fas fa-long-arrow-alt-left icon'></i>
          </Link>
          <p>Filter</p>
        </div>
        <form action=''>
          <div className='form-row'>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>Dubai</option>
                <option value=''>Sharjah</option>
                <option value=''>USA</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>Baby Items</option>
                <option value=''>Baby Items 2</option>
                <option value=''>Baby Items 3</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>All Categories</option>
                <option value=''>All Categories 2</option>
                <option value=''>All Categories 3</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Price (AED)</label>
              <input type='text' name='' placeholder='Price From' />
              <input type='text' name='' placeholder='Price To' />
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Age</label>
              <ul className='FilterInnerUl DFlex justify-content-start'>
                <li>Brand New</li>
                <li>Less than 1 month</li>
                <li>Less than 6 month</li>
                <li>Less than 1 year</li>
                <li>Less than 5 year</li>
                <li>Less than 10 year</li>
                <li>Any year</li>
              </ul>
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Usage</label>
              <ul className='FilterInnerUl DFlex justify-content-start'>
                <li>Never used</li>
                <li>Used once</li>
                <li>Light usage </li>
                <li>Normal usage </li>
                <li>Heavy usage </li>
              </ul>
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Ads posted</label>
              <ul className='FilterInnerUl DFlex justify-content-start'>
                <li>Any time</li>
                <li>Today</li>
                <li>Within 3 Days</li>
                <li>Withing 1 Week</li>
                <li>Withing 2 Week</li>
                <li>Withing 1 Month</li>
                <li>Withing 2 Month</li>
                <li>Withing 6 Month</li>
              </ul>
            </div>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>Neighborhood</option>
                <option value=''>Neighborhood 1</option>
                <option value=''>Neighborhood 2</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Keywords</label>
              <input type='text' name='' placeholder='Search keywords' />
            </div>
          </div>
          <div className='FilterBtnDiv DFlex justify-content-center'>
            <button type='submit'>Search</button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default FilterComponent;
