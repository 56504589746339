import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import LeftSideBar from '../leftSideBar/LeftSideBar';
import './Header.scss';
import { Link, useHistory } from 'react-router-dom';

const Header = () => {
  let activeUrl = useHistory().location.pathname.slice(1);
  const [login, setLogin] = React.useState(true);
  const [active, setActive] = React.useState(activeUrl);
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleActive = (e) => {
    setActive(e.target.name);
  };

  return (
    <React.Fragment>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <header className='MobileHeader DFlex'>
            <Button onClick={toggleDrawer(anchor, true)}>
              <img src='/assets/svg/ToggleMenu.svg' alt='' />
            </Button>
            <div className='Logo DBlock mx-auto'>
              <Link to='/'>
                <img src='/assets/svg/logo.svg' alt='' />
              </Link>
            </div>
            <ul className='navbar-nav'>
              {login ? (
                <li className='nav-item dropdown'>
                  <Link
                    className='nav-link dropdown-toggle NoAfter'
                    to='#SignIn'
                    id='navbarDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <button className='ProfileBtn text-center'>
                      <i className='far fa-user icon'></i>
                      <span>Profile</span>
                    </button>
                  </Link>
                  <div className='dropdown-menu p-0 overflow-hidden Top' aria-labelledby='navbarDropdown'>
                    <ul className='FormLoginUl DBlock'>
                      <li>
                        <Link to='/my-ads' className={active === 'my-ads' ? 'active' : ''} name='my-ads' onClick={(e) => handleActive(e)}>
                          <img src='assets/svg/ads.svg' alt='' /> My Ads
                        </Link>
                      </li>
                      <li>
                        <Link to='/liked-ads' className={active === 'liked-ads' ? 'active' : ''} name='liked-ads' onClick={(e) => handleActive(e)}>
                          <img src='assets/svg/like.svg' alt='' /> Liked Ads
                        </Link>
                      </li>
                      <li>
                        <Link
                          to='/ads-analytics'
                          className={active === 'ads-analytics' ? 'active' : ''}
                          name='ads-analytics'
                          onClick={(e) => handleActive(e)}
                        >
                          <img src='assets/svg/data.svg' alt='' /> Data
                        </Link>
                      </li>
                      <li>
                        <Link to='/profile' className={active === 'profile' ? 'active' : ''} name='profile' onClick={(e) => handleActive(e)}>
                          <img src='assets/svg/person.svg' alt='' /> My profile
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : (
                <li className='nav-item dropdown'>
                  <Link
                    className='nav-link dropdown-toggle'
                    to='#SignIn'
                    id='navbarDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    Sign in{' '}
                  </Link>
                  <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                    <form action='' className='form'>
                      <div className='form-row'>
                        <div className='col-12 mb-3'>
                          <input type='email' name='email' id='email' placeholder='Email' required />
                        </div>
                        <div className='col-12 mb-3'>
                          <input type='password' name='password' id='password' placeholder='Password' required />
                        </div>
                        <div className='col-12 mb-3 pb-3 Border'>
                          <button className='FormBtn' type='submit'>
                            Sign In
                          </button>
                        </div>
                      </div>
                    </form>
                    <ul className='FormOuterUl DFlex justify-content-center text-center'>
                      <li>
                        <p>Don't have an account</p>
                      </li>
                      <li>
                        <Link to='/sign-up'>Sign Up</Link>
                      </li>
                    </ul>
                  </div>
                </li>
              )}
            </ul>
          </header>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            <LeftSideBar toggleDrawer={toggleDrawer} anchor={anchor} />
          </Drawer>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default Header;
