import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export class WhatsappPermit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      whatsapp: false,
      value: 'no',
    };
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  render() {
    return (
      <React.Fragment>
        <div className='FormHeading DFlex justify-content-start'>
          <span className='CustomSpan'>7</span>
          <h3>
            Whatsapp Permit <small>(Prefered number for Whatsapp)</small>
          </h3>
        </div>
        <div className='FormDescription DBlock'>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Do you allow the buyer to contact you on WhatsApp?</FormLabel>
            <RadioGroup aria-label='gender' name='gender1' value={this.state.value} onChange={(e) => this.handleChange(e)}>
              <FormControlLabel value='yes' control={<Radio />} label='Yes' />
              <FormControlLabel value='no' control={<Radio />} label='No' />
            </RadioGroup>
          </FormControl>
          {this.state.value === 'yes' ? (
            <React.Fragment>
              <form action='' className='form'>
                <div className='form-row mt-3'>
                  <div className='col-lg-4'>
                    <label htmlFor=''>Phone No.</label>
                  </div>
                  <div className='col-lg-8'>
                    <input type='text' placeholder='00 971 00 00 000 000' />
                    <button className='EditBtn'>
                      <i className='fas fa-pencil-alt icon'></i>
                    </button>
                  </div>
                </div>
              </form>
            </React.Fragment>
          ) : (
            ''
          )}
          <div className='NextBtnDiv DBlock text-center'>
            <button className='NextBtn' name='setComponent' value='AdsFeature' onClick={(e) => this.props.updateState(e)}>
              Next
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default WhatsappPermit;
