import React from 'react';

const MotorSpecification = ({ updateState }) => {
  const BG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>3</span>
        <h3>
          Motors Specification <small>(Car technical specifications detail)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Price:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <input type='text' placeholder='10.000.00 AED' />
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Manufacturer:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Manufacturer</option>
                <option value=''>Manufacturer 1</option>
                <option value=''>Manufacturer 2</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Model:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Enter the Model of your Car</option>
                <option value=''>Enter the Model of your Car 1</option>
                <option value=''>Enter the Model of your Car 2</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Doors:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Select the number of Doors</option>
                <option value=''> 1</option>
                <option value=''> 2</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Color:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Car Color</option>
                <option value=''> 1</option>
                <option value=''> 2</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Fuel Type:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>Petrol</option>
                <option value=''> 1</option>
                <option value=''> 2</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Horsepower:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>1000 bhp</option>
                <option value=''> 1</option>
                <option value=''> 2</option>
              </select>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Body Condition:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='' style={BG}>
                <option value=''>No accidents, few faults</option>
                <option value=''> 1</option>
                <option value=''> 2</option>
              </select>
            </div>
          </div>
        </form>
        <div className='NextBtnDiv DBlock text-center'>
          <button className='NextBtn' name='setComponent' value='KeyFutures' onClick={(e) => updateState(e)}>
            Next
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MotorSpecification;
