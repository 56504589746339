import React from 'react';

const AddDescription = ({ addDescriptionHandler, title, description }) => {
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>2</span>
        <h3>
          Ad Description <small>(Basic Information about your Ad)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-12 mb-3'>
              <input type='text' name='title' required placeholder='Ad Title: *' value={title} onChange={(e) => addDescriptionHandler(e)} />
            </div>
            <div className='col-12 mb-3'>
              <textarea
                name='description'
                id=''
                required
                placeholder='Ad Description: *'
                value={description}
                onChange={(e) => addDescriptionHandler(e)}
              ></textarea>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default AddDescription;
