import React from 'react';
import { Link } from 'react-router-dom';
import './Home.scss';

export default function Home() {
  return (
    <React.Fragment>
      <div className='HomeArea DBlock'>
        <div className='container'>
          <div className='HomeDiv DBlock'>
            <div className='Title DBlock h-auto'>
              <h2>
                You <span>Ad</span> We <span>Seed</span>
              </h2>
              <form action='' className='form'>
                <div className='form-group'>
                  <input type='text' placeholder='I am Looking for...' name='' id='' required />
                  <button className='SubBtn' type='submit'>
                    <i className='fas fa-search icon'></i>
                  </button>
                </div>
              </form>
            </div>
            <div className='Desc DBlock h-auto'>
              <ul className='DescUl DFlex position-relative'>
                <li className='DropDown'>
                  <Link to='/classified'>
                    <img src='assets/svg/Classifieds.svg' alt='' />
                    <p>Classifieds</p>
                  </Link>
                  <div className='DropDownMenu DBlock'>
                    <ul className='DropDownUl DFlex justify-content-start'>
                      <li>Baby Items</li>
                      <li>Books</li>
                      <li>Business & Industrial</li>
                      <li>Cameras & Imaging</li>
                      <li>Clothing & Accessories</li>
                      <li>Collectibles</li>
                      <li>Computers & Networking</li>
                      <li>DVDs & Movies</li>
                      <li>Electronics</li>
                      <li>Free Stuff</li>
                      <li>Furniture, Home & Garden</li>
                      <li>Gaming</li>
                      <li>Home Appliances</li>
                      <li>Jewelry & Watches</li>
                      <li>Mobile Phones & PDAs</li>
                      <li>Music</li>
                      <li>Pets</li>
                      <li>Sports Equipment</li>
                      <li>Stuff Wanted</li>
                      <li>Tickets & Vouchers</li>
                      <li>Toys</li>
                      <li>Misc</li>
                    </ul>
                  </div>
                </li>
                <li className='DropDown'>
                  <Link to='/motors'>
                    <img src='assets/svg/Motors.svg' alt='' />
                    <p>Motors</p>
                  </Link>
                  <div className='DropDownMenu DBlock'>
                    <ul className='DropDownUl DFlex justify-content-start'>
                      <li>Used Cars For Sale</li>
                      <li>Auto Accessories & Parts</li>
                      <li>Boats</li>
                      <li>Heavy Vehicles</li>
                      <li>Motorcycles</li>
                      <li>Number Plates</li>
                      <li>Wanted</li>
                    </ul>
                  </div>
                </li>
                <li className='DropDown'>
                  <Link to='/jobs'>
                    <img src='assets/svg/Job.svg' alt='' />
                    <p>Jobs</p>
                  </Link>
                  <div className='DropDownMenu DBlock'>
                    <ul className='DropDownUl DFlex justify-content-start'>
                      <li>Accounting</li>
                      <li>Airlines & Aviation</li>
                      <li>Architecture & Interior Design</li>
                      <li>Art & Entertainment</li>
                      <li>Automotive</li>
                      <li>Banking & Finance</li>
                      <li>Beauty</li>
                      <li>Business Development</li>
                      <li>Business Supplies & Equipment</li>
                      <li>Construction</li>
                      <li>Consulting</li>
                      <li>Customer Service</li>
                      <li>Education</li>
                      <li>Engineering</li>
                      <li>Environmental Services</li>
                      <li>Event Management</li>
                      <li>Executive</li>
                      <li>Fashion</li>
                      <li>Food & Beverages</li>
                      <li>Graphic Design</li>
                      <li>Hospitality & Restaurants</li>
                      <li>HR & Recruitment</li>
                      <li>Import & Export</li>
                      <li>Industrial & Manufacturing</li>
                      <li>Information Technology</li>
                      <li>Insurance</li>
                      <li>Internet</li>
                      <li>Legal Services</li>
                      <li>Logistics & Distribution</li>
                      <li>Marketing & Advertising</li>
                      <li>Media</li>
                      <li>Medical & Healthcare</li>
                      <li>Oil, Gas & Energy</li>
                      <li>Online Media</li>
                      <li>Pharmaceuticals</li>
                      <li> Public Relations Real Estate</li>
                      <li>Research & Development </li>
                      <li>Retail & Consumer Goods</li>
                      <li>Safety & Security</li>
                      <li>Sales</li>
                      <li>Secretarial</li>
                      <li>Sports & Fitness</li>
                      <li>Telecommunications</li>
                      <li>Transportation</li>
                      <li>Travel and Tourism</li>
                      <li>Veterinary and Animals</li>
                      <li>Warehousing</li>
                    </ul>
                  </div>
                </li>
                <li className='DropDown'>
                  <Link to='/properties'>
                    <img src='assets/svg/Properties.svg' alt='' />
                    <p>Properties </p>
                  </Link>
                  <div className='DropDownMenu DBlock'>
                    <ul className='DropDownUl DFlex justify-content-start'>
                      <li>Properties For Sale</li>
                      <li>Properties For Rent</li>
                    </ul>
                  </div>
                </li>
                <li className='DropDown'>
                  <Link to='/community'>
                    <img src='assets/svg/Community.svg' alt='' />
                    <p>Community</p>
                  </Link>
                  <div className='DropDownMenu DBlock'>
                    <ul className='DropDownUl DFlex justify-content-start'>
                      <li>Activities</li>
                      <li>Artists</li>
                      <li>Car Lift</li>
                      <li>Childcare</li>
                      <li>Classes</li>
                      <li>Clubs</li>
                      <li>Domestic</li>
                      <li>Education</li>
                      <li>Freelancers</li>
                      <li>Music</li>
                      <li>News</li>
                      <li>Photography Services</li>
                      <li>Sports</li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
