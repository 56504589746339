import React from 'react';
import Iframe from 'react-iframe';

const MapLocation = ({ updateState }) => {
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>7</span>
        <h3>
          Location in Map <small>(Google Maps)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-lg-4'>
              <label htmlFor=''>Location</label>
            </div>
            <div className='col-lg-8 '>
              <input type='text' placeholder='Type here...' />
            </div>
          </div>
        </form>
        <div className='LocationDiv DBlock text-center'>
          <p>Is the pin in the right location? </p>
          <span>Click and drag the pin to the exact spot. Users are more likely To respond to ads that are correctly shown on the map</span>
          <Iframe
            url='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3610.471564063551!2d55.261409!3d25.187315!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8e6efd668fcec9bf!2sClicktap%20Digital%20Technologies!5e0!3m2!1sen!2sus!4v1598352114100!5m2!1sen!2sus'
            id='myId'
            className='myClassname'
            frameborder='0'
            allowfullscreen=''
            aria-hidden='false'
            tabindex='0'
            position='relative'
          />
        </div>
        <div className='NextBtnDiv DBlock text-center'>
          <button className='NextBtn' name='setComponent' value='TimeSlots' onClick={(e) => updateState(e)}>
            Next
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MapLocation;
