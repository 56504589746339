import React, { useState } from 'react';
import ProfileLeftSideBar from '../ProfileLeftSideBar';
import ClassifiedAnalytics from './classifiedAnalytics/ClassifiedAnalytics';
import CommunityAnalytics from './communityAnalytics/CommunityAnalytics';
import CandidateAnalytics from './candidateAnalytics/CandidateAnalytics';
import MotorsAnalytics from './motorsAnalytics/MotorsAnalytics';
import PropertiesAnalytics from './propertiesAnalytics/PropertiesAnalytics';
import JobsAnalytics from './jobsAnalytics/JobsAnalytics';
import CompanyAnalytics from './companyAnalytics/CompanyAnalytics';
import { classifiedItems } from '../ProfileApi';
import { communityItems } from '../ProfileApi';
import { motorsItems } from '../ProfileApi';
import { candidatesItems } from '../ProfileApi';
import { jobsItems } from '../ProfileApi';
import { propertiesItems } from '../ProfileApi';
import { companyItems } from '../ProfileApi';

const DataAnalytics = () => {
  let [adsCategory, setAdsCategory] = useState('job');
  const [classifiedItem, setClassifiedItem] = useState(classifiedItems);
  const [motorsItem, setMotorsItem] = useState(motorsItems);
  const [candidatesItem, setCandidatesItem] = useState(candidatesItems);
  const [jobsItem, setJobsItem] = useState(jobsItems);
  const [communityItem, setCommunityItem] = useState(communityItems);
  const [propertiesItem, setPropertiesItem] = useState(propertiesItems);
  const [companiesItem, setCompaniesItem] = useState(companyItems);

  const handleAds = (e) => {
    setAdsCategory((adsCategory = e.target.value));
  };

  const previewAds = () => {
    switch (adsCategory) {
      case 'jobs':
        return <CandidateAnalytics item={candidatesItem} type='candidate' />;
      case 'motors':
        return <MotorsAnalytics item={motorsItem} type='motors' />;
      case 'classifieds':
        return <ClassifiedAnalytics item={classifiedItem} type='classified' />;
      case 'properties':
        return <PropertiesAnalytics item={propertiesItem} type='property' />;
      case 'community':
        return <CommunityAnalytics item={communityItem} type='community' />;
      case 'postJob':
        return <JobsAnalytics item={jobsItem} type='jobs' />;
      case 'company':
        return <CompanyAnalytics item={companiesItem} type='company' />;
      default:
        return <CandidateAnalytics item={candidatesItem} type='candidate' />;
    }
  };
  return (
    <React.Fragment>
      <div className='ProfileWrapper DBlock'>
        <div className='SelectCategory DFlex justify-content-center text-center'>
          <label htmlFor='selectAdsCategory'>Category</label>
          <select name='selectAdsCategory' id='selectAdsCategory' onChange={(e) => handleAds(e)}>
            <option value='jobs'>Job Employer</option>
            <option value='classifieds'>Classifieds</option>
            <option value='motors'>Motors</option>
            <option value='properties'>Properties</option>
            <option value='community'>Community</option>
            <option value='postJob'>Post Job</option>
            <option value='company'>Company</option>
          </select>
        </div>
        <div className='LeftSideBar DBlock'>
          <ProfileLeftSideBar />
        </div>
        <div className='MainProfileDiv DBlock'>{previewAds()}</div>
      </div>
    </React.Fragment>
  );
};

export default DataAnalytics;
