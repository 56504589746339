import React from 'react';
import { Link } from 'react-router-dom';

const FilterComponent = ({ removeFilter }) => {
  const selectBG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };

  return (
    <React.Fragment>
      <div className='FilterDiv DBlock'>
        <div className='TopBar DFlex'>
          <Link to='#!' onClick={removeFilter}>
            <i className='fas fa-long-arrow-alt-left icon'></i>
          </Link>
          <p>Filter</p>
        </div>
        <form action=''>
          <div className='form-row'>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>Dubai</option>
                <option value=''>Sharjah</option>
                <option value=''>USA</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>All Makes</option>
                <option value=''>All Makes 2</option>
                <option value=''>All Makes 3</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Price (AED)</label>
              <input type='text' name='' placeholder='Price From' />
              <input type='text' name='' placeholder='Price To' />
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Year</label>
              <select name='' id='' style={selectBG} className='mb-3'>
                <option value=''>From Year</option>
                <option value=''>From 2</option>
                <option value=''>From 3</option>
              </select>
              <select name='' id='' style={selectBG} className='mb-3'>
                <option value=''>To Year</option>
                <option value=''>To 2</option>
                <option value=''>To 3</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Kilometers</label>
              <select name='' id='' style={selectBG} className='mb-3'>
                <option value=''>From KM</option>
                <option value=''>From 2</option>
                <option value=''>From 3</option>
              </select>
              <select name='' id='' style={selectBG} className='mb-3'>
                <option value=''>To KM</option>
                <option value=''>To 2</option>
                <option value=''>To 3</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Body Type</label>
              <ul className='FilterInnerUl DFlex justify-content-start'>
                <li>Pick Up Truck</li>
                <li>Sedan</li>
                <li>SUV</li>
                <li>Van</li>
                <li>Utility Truck</li>
                <li>Soft Top Convertible</li>
                <li>Sport Car</li>
                <li>Wagon</li>
                <li>Other</li>
              </ul>
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Fuel Type</label>
              <ul className='FilterInnerUl DFlex justify-content-start'>
                <li>Gasoline</li>
                <li>Hybrid</li>
                <li>Diesel</li>
                <li>Electric</li>
              </ul>
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Transmission Type</label>
              <ul className='FilterInnerUl DFlex justify-content-start'>
                <li>Manual Transmission</li>
                <li>Automatic Transmission</li>
              </ul>
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Color </label>
              <ul className='FilterInnerUl DFlex justify-content-start'>
                <li>Red</li>
                <li>Black</li>
                <li>Blue</li>
                <li>Gray</li>
                <li>Brown</li>
                <li>Silver</li>
                <li>Tan</li>
                <li>Other Color</li>
              </ul>
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Warranty </label>
              <ul className='FilterInnerUl DFlex justify-content-start'>
                <li>Yes</li>
                <li>No</li>
                <li>Does not apply</li>
              </ul>
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Ads posted </label>
              <ul className='FilterInnerUl DFlex justify-content-start'>
                <li>Any time</li>
                <li>Today</li>
                <li>Within 3 Days</li>
                <li>Within 1 Week</li>
                <li>Within 2 Weeks</li>
                <li>Within 1 Months</li>
                <li>Within 2 Months</li>
                <li>Within 6 Months</li>
              </ul>
            </div>
            <div className='col-12 mb-3'>
              <select name='' id='' style={selectBG}>
                <option value=''>Neighborhood</option>
                <option value=''>Neighborhood 1</option>
                <option value=''>Neighborhood 2</option>
              </select>
            </div>
            <div className='col-12 mb-3'>
              <label htmlFor=''>Keywords</label>
              <input type='text' name='' placeholder='Search keywords' />
            </div>
          </div>
          <div className='FilterBtnDiv DFlex justify-content-center'>
            <button type='submit'>Search</button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default FilterComponent;
