import React from 'react';
import { relatedItems } from '../Api';
import GridViewComponent from '../GridViewComponent';

function RightSidebarDetails() {
  const [item] = React.useState(relatedItems);
  return (
    <React.Fragment>
      <div className='RightSideDiv DBlock'>
        <div className='RightSideSearch DBlock p-0 BorderRad'>
          <div className='RightSideJob DBlock'>
            <img src='/assets/images/Company1.png' className='OpenJobImg' alt='' />
            <h4>Current Opening</h4>
            <p>Banking</p>
            <ol className='SideOl DFlex w-auto justify-content-center'>
              <li>
                <a href={`mailto:${item.gmail}`}>
                  <i className='fas fa-share-alt icon'></i>
                </a>
              </li>
              <li>
                <a href={`tel:${item.call}`}>
                  <i className='far fa-heart icon heart'></i>
                </a>
              </li>
            </ol>
            <h4>Citi Bank Opens</h4>
            <ul className='SideUl DBlock'>
              <li>
                <div className='ImgDiv DBlock'>
                  <img src='/assets/images/Job1.png' alt='' className='' />
                </div>
                <div className='TextDiv DBlock'>
                  <h5>UI/UX Designer</h5>
                  <p>
                    <i className='fas fa-map-marker-alt icon'></i> Dubai
                  </p>
                </div>
              </li>
              <li>
                <div className='ImgDiv DBlock'>
                  <img src='/assets/images/Job2.png' alt='' className='' />
                </div>
                <div className='TextDiv DBlock'>
                  <h5>Cashier</h5>
                  <p>
                    <i className='fas fa-map-marker-alt icon'></i> Abu Dhabi
                  </p>
                </div>
              </li>
              <li>
                <div className='ImgDiv DBlock'>
                  <img src='/assets/images/Job1.png' alt='' className='' />
                </div>
                <div className='TextDiv DBlock'>
                  <h5>Branch Manager</h5>
                  <p>
                    <i className='fas fa-map-marker-alt icon'></i> Dubai
                  </p>
                </div>
              </li>
              <li>
                <div className='ImgDiv DBlock'>
                  <img src='/assets/images/Job2.png' alt='' className='' />
                </div>
                <div className='TextDiv DBlock'>
                  <h5>Developer</h5>
                  <p>
                    <i className='fas fa-map-marker-alt icon'></i> Sharjah
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className='SocialLinksDiv DBlock h-auto text-center'>
          <p>Share with friends</p>
          <ul className='SocialLinks DFlex justify-content-center'>
            <li>
              <a href='https://twitter.com/?lang=en' target='blank'>
                <img src='/assets/images/Twitter.png' alt='' />
              </a>
            </li>
            <li>
              <a href='https://www.facebook.com/' target='blank'>
                <img src='/assets/images/Facebook.png' alt='' />
              </a>
            </li>
            <li>
              <a href='https://www.instagram.com/?hl=en' target='blank'>
                <img src='/assets/images/Instagram.png' alt='' />
              </a>
            </li>
            <li>
              <a href='https://www.whatsapp.com/' target='blank'>
                <img src='/assets/images/WhatsApp.png' alt='' />
              </a>
            </li>
          </ul>
        </div>
        <div className='RelatedSearch DBlock'>
          <h4>Related Search </h4>
          <GridViewComponent item={item} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default RightSidebarDetails;
