import React from 'react';

const CompanyInfo = () => {
  const BG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };
  return (
    <div className='FormInnerDiv DBlock'>
      <h4>Company Info</h4>
      <form action=''>
        <div className='form-row'>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Name: </label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='text' placeholder='Name' required />
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Company name: </label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='text' placeholder='Company Name' required />
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Phone Number: </label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='text' placeholder='000 00 00 000 000' required />
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Location:</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <select name='' id='' style={BG}>
              <option value=''>Dubai, UAE</option>
              <option value=''>Sharjah</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CompanyInfo;
