import React from 'react';

const UploadImage = ({ updateState }) => {
  const BG = {
    backgroundImage: 'url(assets/svg/DownArr.svg)',
  };
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>6</span>
        <h3>
          Upload your images <small>(format allowed: jpg - png)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-lg-8 offset-lg-4'>
              <div className='UploadImgDiv DBlock'>
                <input type='file' name='uploadImg' className='UploadImg' accept='image/x-png,image/gif,image/jpeg' />
                <label htmlFor='uploadImg' style={BG}>
                  Upload Images
                </label>
              </div>
            </div>
          </div>
        </form>
        <div className='UploadImgDiv DBlock text-center'>
          <div className='ImgDiv DBlock float-none'>
            <span className='CrossBtn'>
              <i className='far fa-times-circle icon'></i>
            </span>
            <img src='/assets/images/Claseed1.png' alt='' />
          </div>
          <div className='progress'>
            <span className='CrossBtn'>
              <i className='far fa-times-circle icon'></i>
            </span>
            <div className='progress-bar w-50' role='progressbar' aria-valuenow='25' aria-valuemin='0' aria-valuemax='100'></div>
          </div>
        </div>
        <div className='NextBtnDiv DBlock text-center'>
          <button className='NextBtn' name='setComponent' value='MapLocation' onClick={(e) => updateState(e)}>
            Next
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UploadImage;
