import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const salary = ['Per Month', 'Per Hour', 'Per Day', 'Per Week', 'Per Year'];

const Salary = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [salaryType, setSalaryType] = React.useState('Per Month');

  const handleSalary = (event) => {
    setSalaryType(event.target.value);
  };

  return (
    <div className='FormInnerDiv DBlock'>
      <h4>Company Info</h4>
      <form action=''>
        <div className='form-row'>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Amount *:</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <input type='text' placeholder='Example 6.000.00 AED' required />
          </div>
          <div className='col-md-5 col-12 mb-3'>
            <label htmlFor=''>Salary Type *:</label>
          </div>
          <div className='col-md-7 col-12 mb-3'>
            <Button aria-controls='simple-menu' aria-haspopup='true' onClick={(e) => setAnchorEl(e.currentTarget)}>
              Select
            </Button>
            <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={(e) => setAnchorEl(null)}>
              <RadioGroup aria-label='contract' name='contract' value={salaryType} onChange={handleSalary}>
                {salary.map((salary, i) => (
                  <FormControlLabel key={i} value={salary} control={<Radio />} label={salary} />
                ))}
              </RadioGroup>
            </Menu>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Salary;
