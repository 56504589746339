import React from 'react';

const AddDescription = ({ updateState }) => {
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <h3>Company Info</h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action=''>
          <div className='form-row'>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Company Name: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <input type='text' placeholder='Citi Bank' />
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Industry:: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <input type='text' placeholder='Banking' />
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Trade License No.: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <input type='text' placeholder='00000000' />
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Established Since: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <input type='text' placeholder='2000' />
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Number Of Employees: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <input type='text' placeholder='000' />
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Specialities:</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <textarea name='' id='' placeholder='Type here...'></textarea>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>About:</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <textarea name='' id='' placeholder='Type here...'></textarea>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Website:</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <input type='text' placeholder='www.company.com' />
            </div>
          </div>
        </form>
        <div className='NextBtnDiv DBlock text-center'>
          <button className='NextBtn' name='setComponent' value='MapLocation' onClick={(e) => updateState(e)}>
            Next
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddDescription;
