export const companyItems = [
  {
    id: 1,
    img: [
      { id: 1, images: '/assets/images/Company1.png' },
      { id: 2, images: '/assets/images/Company1.png' },
      { id: 3, images: '/assets/images/Company1.png' },
      { id: 4, images: '/assets/images/Company1.png' },
    ],
    coverImg: '/assets/images/CoverImg.png',
    title: 'Citi Bank',
    date: '10/12/2018',
    industry: 'Banking',
    tradeLicence: 123456789,
    established: 1234,
    Employees: 10.0,
    call: '+91 (31) 476 4998',
    location: 'Dubai, UAE',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    description: [
      {
        id: 1,
        text:
          "Citi's mission is to serve as a trusted partner to our clients by responsibly providing financial services that enable growth and economic progress. Our core activities are safeguarding assets, lending money, making payments and accessing the capital markets on behalf of our clients. ",
      },
      {
        id: 2,
        text:
          "We have 200 years of experience helping our clients meet the world's toughest challenges and embrace its greatest opportunities. We are Citi, the global bank – an institution connecting millions of people across hundreds of countries and cities.",
      },
      {
        id: 3,
        text: 'For information on Citi’s commitment to privacy, visit on.citi/privacy.',
      },
    ],
  },
  {
    id: 2,
    img: [
      { id: 1, images: '/assets/images/Company2.png' },
      { id: 2, images: '/assets/images/Company2.png' },
      { id: 3, images: '/assets/images/Company2.png' },
      { id: 4, images: '/assets/images/Company2.png' },
    ],
    coverImg: '/assets/images/CoverImg.png',
    title: 'National Bank',
    date: '10/12/2018',
    industry: 'Banking',
    tradeLicence: 123456789,
    established: 1234,
    Employees: 10.0,
    call: '+91 (31) 476 4998',
    location: 'Dubai, UAE',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    description: [
      {
        id: 1,
        text:
          "Citi's mission is to serve as a trusted partner to our clients by responsibly providing financial services that enable growth and economic progress. Our core activities are safeguarding assets, lending money, making payments and accessing the capital markets on behalf of our clients. ",
      },
      {
        id: 2,
        text:
          "We have 200 years of experience helping our clients meet the world's toughest challenges and embrace its greatest opportunities. We are Citi, the global bank – an institution connecting millions of people across hundreds of countries and cities.",
      },
      {
        id: 3,
        text: 'For information on Citi’s commitment to privacy, visit on.citi/privacy.',
      },
    ],
  },
  {
    id: 3,
    img: [
      { id: 1, images: '/assets/images/Company1.png' },
      { id: 2, images: '/assets/images/Company1.png' },
      { id: 3, images: '/assets/images/Company1.png' },
      { id: 4, images: '/assets/images/Company1.png' },
    ],
    coverImg: '/assets/images/CoverImg.png',
    title: 'Citi Bank',
    date: '10/12/2018',
    industry: 'Banking',
    tradeLicence: 123456789,
    established: 1234,
    Employees: 10.0,
    call: '+91 (31) 476 4998',
    location: 'Dubai, UAE',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    description: [
      {
        id: 1,
        text:
          "Citi's mission is to serve as a trusted partner to our clients by responsibly providing financial services that enable growth and economic progress. Our core activities are safeguarding assets, lending money, making payments and accessing the capital markets on behalf of our clients. ",
      },
      {
        id: 2,
        text:
          "We have 200 years of experience helping our clients meet the world's toughest challenges and embrace its greatest opportunities. We are Citi, the global bank – an institution connecting millions of people across hundreds of countries and cities.",
      },
      {
        id: 3,
        text: 'For information on Citi’s commitment to privacy, visit on.citi/privacy.',
      },
    ],
  },
  {
    id: 4,
    img: [
      { id: 1, images: '/assets/images/Company2.png' },
      { id: 2, images: '/assets/images/Company2.png' },
      { id: 3, images: '/assets/images/Company2.png' },
      { id: 4, images: '/assets/images/Company2.png' },
    ],
    coverImg: '/assets/images/CoverImg.png',
    title: 'National Bank',
    date: '10/12/2018',
    industry: 'Banking',
    tradeLicence: 123456789,
    established: 1234,
    Employees: 10.0,
    call: '+91 (31) 476 4998',
    location: 'Dubai, UAE',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    description: [
      {
        id: 1,
        text:
          "Citi's mission is to serve as a trusted partner to our clients by responsibly providing financial services that enable growth and economic progress. Our core activities are safeguarding assets, lending money, making payments and accessing the capital markets on behalf of our clients. ",
      },
      {
        id: 2,
        text:
          "We have 200 years of experience helping our clients meet the world's toughest challenges and embrace its greatest opportunities. We are Citi, the global bank – an institution connecting millions of people across hundreds of countries and cities.",
      },
      {
        id: 3,
        text: 'For information on Citi’s commitment to privacy, visit on.citi/privacy.',
      },
    ],
  },
  {
    id: 5,
    img: [
      { id: 1, images: '/assets/images/Company1.png' },
      { id: 2, images: '/assets/images/Company1.png' },
      { id: 3, images: '/assets/images/Company1.png' },
      { id: 4, images: '/assets/images/Company1.png' },
    ],
    coverImg: '/assets/images/CoverImg.png',
    title: 'Citi Bank',
    date: '10/12/2018',
    industry: 'Banking',
    tradeLicence: 123456789,
    established: 1234,
    Employees: 10.0,
    call: '+91 (31) 476 4998',
    location: 'Dubai, UAE',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    description: [
      {
        id: 1,
        text:
          "Citi's mission is to serve as a trusted partner to our clients by responsibly providing financial services that enable growth and economic progress. Our core activities are safeguarding assets, lending money, making payments and accessing the capital markets on behalf of our clients. ",
      },
      {
        id: 2,
        text:
          "We have 200 years of experience helping our clients meet the world's toughest challenges and embrace its greatest opportunities. We are Citi, the global bank – an institution connecting millions of people across hundreds of countries and cities.",
      },
      {
        id: 3,
        text: 'For information on Citi’s commitment to privacy, visit on.citi/privacy.',
      },
    ],
  },
  {
    id: 6,
    img: [
      { id: 1, images: '/assets/images/Company2.png' },
      { id: 2, images: '/assets/images/Company2.png' },
      { id: 3, images: '/assets/images/Company2.png' },
      { id: 4, images: '/assets/images/Company2.png' },
    ],
    coverImg: '/assets/images/CoverImg.png',
    title: 'National Bank',
    date: '10/12/2018',
    industry: 'Banking',
    tradeLicence: 123456789,
    established: 1234,
    Employees: 10.0,
    call: '+91 (31) 476 4998',
    location: 'Dubai, UAE',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    description: [
      {
        id: 1,
        text:
          "Citi's mission is to serve as a trusted partner to our clients by responsibly providing financial services that enable growth and economic progress. Our core activities are safeguarding assets, lending money, making payments and accessing the capital markets on behalf of our clients. ",
      },
      {
        id: 2,
        text:
          "We have 200 years of experience helping our clients meet the world's toughest challenges and embrace its greatest opportunities. We are Citi, the global bank – an institution connecting millions of people across hundreds of countries and cities.",
      },
      {
        id: 3,
        text: 'For information on Citi’s commitment to privacy, visit on.citi/privacy.',
      },
    ],
  },
];

export const relatedItems = [
  {
    id: 1,
    img: [
      { id: 1, images: '/assets/images/Company1.png' },
      { id: 2, images: '/assets/images/Company1.png' },
      { id: 3, images: '/assets/images/Company1.png' },
      { id: 4, images: '/assets/images/Company1.png' },
    ],
    coverImg: '/assets/images/CoverImg.png',
    title: 'Citi Bank',
    date: '10/12/2018',
    industry: 'Banking',
    tradeLicence: 123456789,
    established: 1234,
    Employees: 10.0,
    call: '+91 (31) 476 4998',
    location: 'Dubai, UAE',
    morning: '9 - 11 am',
    afternoon: '3 - 4 pm',
    evening: '6 - 9 pm',
    description: [
      {
        id: 1,
        text:
          "Citi's mission is to serve as a trusted partner to our clients by responsibly providing financial services that enable growth and economic progress. Our core activities are safeguarding assets, lending money, making payments and accessing the capital markets on behalf of our clients. ",
      },
      {
        id: 2,
        text:
          "We have 200 years of experience helping our clients meet the world's toughest challenges and embrace its greatest opportunities. We are Citi, the global bank – an institution connecting millions of people across hundreds of countries and cities.",
      },
      {
        id: 3,
        text: 'For information on Citi’s commitment to privacy, visit on.citi/privacy.',
      },
    ],
  },
];
