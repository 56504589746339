import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Profile = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className='ProfileWrapper DBlock'>
        <div className='MainProfileDiv DBlock'>
          <div className='ProfileDiv DBlock'>
            <div className='ProfileData DBlock'>
              <div className='row align-items-center'>
                <div className='col-md-6 mb-3'>
                  <div className='ProfileImg DBlock'>
                    <img src='/assets/images/Profile.jpg' alt='' />
                    <Link to='/edit-profile'>
                      <i className='fas fa-pencil-alt icon'></i>
                    </Link>
                  </div>
                </div>
                <div className='col-md-6 mb-3'>
                  <div className='ProfileText DBlock'>
                    <h2>John Galt</h2>
                    <p>johngalt@gmail.com</p>
                    <p>+971 456 52 76 76</p>
                    <p>Language: English</p>
                    <p>City: Dubai</p>
                    <Link to='/edit-profile' className='EditProfileBtn'>
                      <i className='fas fa-pencil-alt icon'></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='SavedProfileDiv DBlock'>
              <div className={classes.root}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                    <Typography className={classes.heading}>Saved Files </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <React.Fragment>
                      <div className='SavedAccBodyDiv DBlock'>
                        <div className='Title DFlex'>
                          <h4>Name</h4>
                        </div>
                        <ul className='DescUl DBlock'>
                          <li className='DFlex'>
                            <span> Company , Job Post 1</span>
                            <div className='ActionBtnDiv DFlex w-auto'>
                              <button>
                                <i className='fas fa-pencil-alt icon'></i>
                              </button>
                              <button>
                                <i className='fas fa-trash-alt icon'></i>
                              </button>
                            </div>
                          </li>
                          <li className='DFlex'>
                            <span>CV 1</span>
                            <div className='ActionBtnDiv DFlex w-auto'>
                              <button>
                                <i className='fas fa-pencil-alt icon'></i>
                              </button>
                              <button>
                                <i className='fas fa-trash-alt icon'></i>
                              </button>
                            </div>
                          </li>
                          <li className='DFlex'>
                            <span>Job post 1</span>
                            <div className='ActionBtnDiv DFlex w-auto'>
                              <button>
                                <i className='fas fa-pencil-alt icon'></i>
                              </button>
                              <button>
                                <i className='fas fa-trash-alt icon'></i>
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Profile;
