import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import LeftSidebar from '../LeftSidebar';
import { subCategories } from '../searchJob/Api';

const PostJob = () => {
  const history = useHistory();
  const [category, setCategory] = React.useState(subCategories);

  const BG = {
    backgroundImage: 'url(/assets/svg/DownArr.svg)',
  };

  return (
    <React.Fragment>
      <div className='JobsCom DBlock position-relative'>
        <div className='CompDiv DFlex'>
          <div className='LeftSideBar DBlock'>
            <LeftSidebar />
          </div>
          <div className='MainComponent DBlock mx-auto shadow-none p-0'>
            <div className='PostCVDiv DBlock'>
              <ul className='BreadCrumbUl DFlex justify-content-start'>
                <li>Home </li>
                <li>Jobs</li>
                <li>Job Employer</li>
                <li>Post Job</li>
              </ul>
              <div className='PostArea DBlock'>
                <h3>Post Job</h3>
                <div className='PostCategoryDiv DBlock text-center'>
                  <h2>Choose a subcategory</h2>
                  <select name='category' id='' style={BG}>
                    {category &&
                      category.map((category, index) => (
                        <option value={category.text} key={category.id}>
                          {category.text}
                        </option>
                      ))}
                  </select>
                </div>
                <ul className='JobModalBodyUl DFlex justify-content-center'>
                  <li>
                    <button className='LinedIn'>Upload From Linkedin</button>
                  </li>
                  <li>
                    <Link to='/jobs/create-job' onClick={() => history.push('/jobs/create-job')} data-dismiss='modal'>
                      Create a Job
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PostJob;
