import React from 'react';

const ClassifiedSpecification = ({ handleSpecification, specificationWarranty }) => {
  const [values, setValues] = React.useState({ price: '', warranty: '' });

  const handleForm = (e) => {
    e.preventDefault();
    handleSpecification({ values });
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  const BG = {
    backgroundImage: 'url(assets/svg/DownArr.svg)',
  };
  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>3</span>
        <h3>
          Classified Specification <small>(Classified technical specifications detail)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action='' onSubmit={(e) => handleForm(e)}>
          <div className='form-row'>
            <div className='col-lg-4 mb-3'>
              <label htmlFor='price'>Price:*</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <input type='number' name='price' required value={values.price} placeholder='10.000.00 AED' onChange={(e) => handleChange(e)} />
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor='warranty'>Warranty: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              <select name='warranty' required style={BG} value={values.warranty} onChange={(e) => handleChange(e)}>
                <option value=''>Select an option</option>
                {specificationWarranty.map((warranty, index) => (
                  <option value={warranty.name} key={index}>
                    {warranty.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='NextBtnDiv DBlock text-center'>
            <button className='NextBtn' type='submit'>
              Next
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default ClassifiedSpecification;
