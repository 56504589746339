import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

function valuetext(value) {
  return `${value}°C`;
}

const morningMarks = [
  {
    value: 5,
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    value: 10,
  },
  {
    value: 11,
  },
];

const afternoonMarks = [
  {
    value: 12,
  },
  {
    value: 13,
  },
  {
    value: 14,
  },
  {
    value: 15,
  },
  {
    value: 16,
  },
  {
    value: 17,
  },
];

const eveningMarks = [
  {
    value: 18,
  },
  {
    value: 19,
  },
  {
    value: 20,
  },
  {
    value: 21,
  },
  {
    value: 22,
  },
];

const TimeSlots = ({ updateTimeSlots }) => {
  const classes = useStyles();
  let [morning, setMorning] = React.useState([6, 10]);
  let [afternoon, setAfternoon] = React.useState([13, 14]);
  let [evening, setEvening] = React.useState([18, 20]);
  let [morningAvail, setMorningAvail] = React.useState('no');
  let [afternoonAvail, setAfternoonAvail] = React.useState('no');
  let [eveningAvail, setEveningAvail] = React.useState('no');

  const handleMorning = (event, newValue) => {
    console.log(newValue);
    setMorning((morning = newValue));
  };

  const handleEvening = (event, newValue) => {
    setEvening((afternoon = newValue));
  };

  const handleAfternoon = (event, newValue) => {
    setAfternoon((evening = newValue));
  };

  const handleSlots = (e) => {
    e.preventDefault();
    if (morningAvail === 'yes') {
      if (afternoonAvail === 'yes') {
        if (eveningAvail === 'yes') {
          updateTimeSlots(morning, afternoon, evening);
        } else {
          updateTimeSlots(morning, afternoon);
        }
      } else {
        updateTimeSlots(morning);
      }
    } else {
      updateTimeSlots();
    }
  };

  const BG = {
    backgroundImage: 'url(assets/svg/DownArr.svg)',
  };

  return (
    <React.Fragment>
      <div className='FormHeading DFlex justify-content-start'>
        <span className='CustomSpan'>7</span>
        <h3>
          Available Time Slots <small>(Inspection)</small>
        </h3>
      </div>
      <div className='FormDescription DBlock'>
        <form action='' onSubmit={(e) => handleSlots(e)}>
          <div className='form-row'>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Morning: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              {morningAvail !== 'no' ? (
                <React.Fragment>
                  <div className={`w-100 ${classes.root}`}>
                    <Slider
                      value={morning}
                      onChange={handleMorning}
                      min={5}
                      max={11}
                      marks={morningMarks}
                      valueLabelDisplay='auto'
                      aria-labelledby='discrete-slider-always'
                      getAriaValueText={valuetext}
                      scale={(x) => x + 'AM'}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <select name='morningAvail' style={BG} value={morningAvail} onChange={(e) => setMorningAvail(([e.target.name] = e.target.value))}>
                  <option value='no'>No</option>
                  <option value='yes'>Yes</option>
                </select>
              )}
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Afternoon: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              {afternoonAvail !== 'no' ? (
                <React.Fragment>
                  <div className={`w-100 ${classes.root}`}>
                    <Slider
                      value={afternoon}
                      onChange={handleAfternoon}
                      min={12}
                      max={17}
                      marks={afternoonMarks}
                      valueLabelDisplay='auto'
                      aria-labelledby='discrete-slider-always'
                      scale={(x) => x + 'PM'}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <select
                  name='afternoonAvail'
                  style={BG}
                  value={afternoonAvail}
                  onChange={(e) => setAfternoonAvail(([e.target.name] = e.target.value))}
                >
                  <option value='no'>No</option>
                  <option value='yes'>Yes</option>
                </select>
              )}
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor=''>Evening: *</label>
            </div>
            <div className='col-lg-8 mb-3'>
              {eveningAvail !== 'no' ? (
                <React.Fragment>
                  <div className={`w-100 ${classes.root}`}>
                    <Slider
                      value={evening}
                      onChange={handleEvening}
                      getAriaValueText={valuetext}
                      min={18}
                      max={22}
                      marks={eveningMarks}
                      valueLabelDisplay='auto'
                      aria-labelledby='discrete-slider-always'
                      scale={(x) => x + 'PM'}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <select name='eveningAvail' style={BG} value={eveningAvail} onChange={(e) => setEveningAvail(([e.target.name] = e.target.value))}>
                  <option value='no'>No</option>
                  <option value='yes'>Yes</option>
                </select>
              )}
            </div>
          </div>
          <div className='NextBtnDiv DBlock text-center'>
            <button className='NextBtn' type='submit'>
              Next
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default TimeSlots;
