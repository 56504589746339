import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CommunityMainComponent } from './CommunityMainComponent';

export class CommunityAds extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mainCom: false,
      category: subCategory,
      breadCrumb: [],
    };
  }

  handleCategory = (id, text) => {
    const filterCategory = this.state.category.find((list) => list.id === id);
    let joined = this.state.breadCrumb.concat(text);
    this.setState({
      breadCrumb: joined,
    });
    if (filterCategory.sub_menu) {
      this.setState({
        category: filterCategory.sub_menu,
      });
    } else {
      this.setState({
        mainCom: true,
      });
    }
  };

  handleBack = () => {
    this.setState({
      breadCrumb: [],
      category: subCategory,
      mainCom: false,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className='ClassifiedWrapper DBlock'>
          {this.state.mainCom ? (
            <React.Fragment>
              <CommunityMainComponent breadCrumb={this.state.breadCrumb} handleBack={this.handleBack} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='TopBar DFlex'>
                <Link to='/place-your-ad'>
                  <i className='fas fa-long-arrow-alt-left icon'></i>
                </Link>
                <p className='mx-auto'>Community</p>
              </div>
              <div className='ClassifiedList DBlock'>
                <ul className='ClassifiedUl DBlock'>
                  {this.state.category &&
                    this.state.category.map((item, index) => (
                      <li key={index} id={item.id} onClick={() => this.handleCategory(item.id, item.text)}>
                        {item.text} {item.sub_menu ? `(${item.sub_menu.length})` : ''} <i className='fas fa-caret-right icon'></i>
                      </li>
                    ))}
                </ul>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default CommunityAds;

const subCategory = [
  {
    id: 1,
    text: 'Activities',
  },
  {
    id: 2,
    text: 'Artists',
  },
  {
    id: 3,
    text: 'Car Lift',
  },
  {
    id: 4,
    text: 'Childcare',
  },
  {
    id: 5,
    text: 'Classes',
  },
  {
    id: 6,
    text: 'Domestic',
  },
  {
    id: 7,
    text: 'Education',
  },
  {
    id: 8,
    text: 'Freelancers',
  },
  {
    id: 9,
    text: 'Music',
  },
  {
    id: 10,
    text: 'Photography',
  },
  {
    id: 11,
    text: 'Services',
  },
  {
    id: 12,
    text: 'Sports',
  },
];
