import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const Form = () => {
  const classes = useStyles();
  const [experience, setExperience] = React.useState([{ experience: null, title: null, company: null, date: null, description: null }]);

  const addRate = (e) => {
    e.preventDefault();
    setExperience([...experience, { experience: null, company: null, title: null, date: null, description: null }]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form Handling');
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        {experience.map((row, index) => (
          <div key={index} className='form-row'>
            <div className='col-md-5 col-12 mb-3'>
              <label htmlFor=''>Years of Experience: *</label>
            </div>
            <div className='col-md-7 col-12 mb-3'>
              <input type='text' placeholder='10' required />
            </div>
            <div className='col-md-5 col-12 mb-3'>
              <label htmlFor=''>Job Title: *</label>
            </div>
            <div className='col-md-7 col-12 mb-3'>
              <input type='text' placeholder='Enter Title' required />
            </div>
            <div className='col-md-5 col-12 mb-3'>
              <label htmlFor=''>Company Name: *</label>
            </div>
            <div className='col-md-7 col-12 mb-3'>
              <input type='text' placeholder='Enter Company' required />
            </div>
            <div className='col-md-3 col-12 mb-3'>
              <label htmlFor=''>From: *</label>
            </div>
            <div className='col-md-4 col-12 mb-3'>
              <TextField
                id='date'
                type='date'
                defaultValue='2017-05-24'
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className='col-md-1 col-12 mb-3'>
              <span className='d-block text-center'>-</span>
            </div>
            <div className='col-md-4 col-12 mb-3'>
              <TextField
                id='date'
                type='date'
                defaultValue='2017-05-24'
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className='col-md-5 col-12 mb-3'>
              <label htmlFor=''>Job Description: *</label>
            </div>
            <div className='col-md-7 col-12 mb-3'>
              <textarea name='' placeholder='Enter Description'></textarea>
            </div>
          </div>
        ))}
        <button onClick={(e) => addRate(e)} className='AddBtn'>
          <i className='fas fa-plus-circle icon'></i> Add Experience
        </button>
      </form>
    </React.Fragment>
  );
};

const Experience = () => {
  return (
    <div className='FormInnerDiv DBlock'>
      <h4>Experience</h4>
      <Form />
    </div>
  );
};

export default Experience;
