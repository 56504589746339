import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { jobsItems } from '../Api';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#26326c',
    borderRadius: '25px',
  },
}));

const options = ['CV 1', 'CV 2', 'CV 3'];

function MainDetailsComponent(props) {
  const history = useHistory();
  let { id } = useParams();
  const detailItem = jobsItems.findIndex((item) => item.id === parseInt(id));
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hasCV, setHasCV] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Link to='/job-search' className='GoBack'>
        Back to Jobs listings
      </Link>
      {detailItem !== -1 ? (
        <React.Fragment>
          <div className='DetailsComponent DBlock shadow-none px-0'>
            <ul className='BreadCrumbUl DFlex justify-content-start'>
              <li>Home </li>
              <li>Jobs</li>
              <li>Job Seeker</li>
              <li>UI/UX Designer/ Citi Bank</li>
            </ul>
            <div className='JobDetailsInnerDiv DBlock'>
              <div className='JobDetImg DBlock'>
                <img src={jobsItems[detailItem].img[0].images} className='JobImg' alt='' />
              </div>
              <h3>Job Tittle</h3>
              <ul className='JobUl DBlock'>
                <li>
                  Company Name:<span>{jobsItems[detailItem].name}</span>
                </li>
                <li>
                  Email:<span>{jobsItems[detailItem].gmail}</span>
                </li>
                <li>
                  Phone Number:<span>{jobsItems[detailItem].call}</span>
                </li>
                <li>
                  Location:<span>{jobsItems[detailItem].location}</span>
                </li>
              </ul>
              <h3>Job Details</h3>
              <ul className='JobUl DBlock'>
                <li>
                  Type of employment:<span>{jobsItems[detailItem].employmentType}</span>
                </li>
                <li>
                  Contract Type:<span>{jobsItems[detailItem].contract}</span>
                </li>
                <li>
                  Planed date to start:<span>{jobsItems[detailItem].startDate}</span>
                </li>
              </ul>
              <h3>Salary</h3>
              <ul className='JobUl DBlock'>
                <li>
                  Amount<span>{jobsItems[detailItem].Amount}</span>
                </li>
                <li>
                  Salary Type:<span>{jobsItems[detailItem].salaryType}</span>
                </li>
              </ul>
              <h3>Job Description</h3>
              <div className='JobDescription DBlock'>
                {jobsItems[detailItem].description && jobsItems[detailItem].description !== null ? (
                  <React.Fragment>
                    {jobsItems[detailItem].description.map((text, index) => (
                      <p key={text.id}>{text.text}</p>
                    ))}
                  </React.Fragment>
                ) : (
                  ''
                )}
              </div>
              <div className='JobPagination DFlex'>
                <div className='Prev DFlex w-auto'>
                  <span className='Arrow'>
                    <i className='fas fa-angle-double-left icon'></i>
                  </span>
                  Previous Job
                </div>
                <div className='Apply DFlex justify-content-center w-auto'>
                  <button className='ApplyBtn' data-toggle='modal' data-target='#staticBackdropApply'>
                    Apply
                  </button>
                </div>
                <div className='Next DFlex w-auto'>
                  Next Job
                  <span className='Arrow'>
                    <i className='fas fa-angle-double-right icon'></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='modal fade JobModal'
            id='staticBackdropApply'
            data-backdrop='static'
            data-keyboard='false'
            tabIndex='-1'
            aria-labelledby='staticBackdropApplyLabel'
            aria-hidden='true'
          >
            <div className='modal-dialog modal-lg'>
              <div className='modal-content'>
                <div className='modal-header border-0'>
                  <h5 className='modal-title' id='staticBackdropLabel'>
                    Apply
                  </h5>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div className='modal-body'>
                  <ul className='JobModalBodyUl DFlex justify-content-center'>
                    <li>
                      <div className='UploadCv DFlex justify-content-center'>
                        <label htmlFor='resume'>Upload CV</label>
                        <input type='file' id='resume' name='resume' />
                      </div>
                    </li>
                    <li>
                      <button className='LinedIn'>Upload From Linkedin</button>
                    </li>
                    <li>
                      <Link to='/jobs/create-cv' onClick={() => history.push('/jobs/create-cv')} data-dismiss='modal'>
                        Create CV
                      </Link>
                    </li>
                    {hasCV && (
                      <React.Fragment>
                        <li>
                          <div className={classes.root}>
                            <List component='nav' className='p-0 bg-transparent shadow-none' aria-label='Device settings'>
                              <ListItem
                                button
                                aria-haspopup='true'
                                aria-controls='lock-menu'
                                aria-label='Upload Saved CV'
                                onClick={handleClickListItem}
                              >
                                <ListItemText primary='Upload Saved CV' secondary={options[selectedIndex]} />
                              </ListItem>
                            </List>
                            <Menu id='lock-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                              {options.map((option, index) => (
                                <MenuItem key={option} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Menu>
                          </div>
                        </li>
                      </React.Fragment>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className='NotFound DBlock'>
          <h3>Opps Your Items is not found! </h3>
        </div>
      )}
    </React.Fragment>
  );
}

export default MainDetailsComponent;
