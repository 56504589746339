import { Link } from '@material-ui/core';
import React from 'react';
import ProfileLeftSideBar from '../ProfileLeftSideBar';

const EditProfile = () => {
  const [changePassword, setChangePassword] = React.useState(false);
  return (
    <React.Fragment>
      <div className='ProfileWrapper DBlock'>
        <div className='LeftSideBar DBlock'>
          <ProfileLeftSideBar />
        </div>
        <div className='MainProfileDiv DBlock'>
          <div className='ProfileDiv DBlock'>
            <div className='ProfileData DBlock mb-0 p-5'>
              <div className='row align-items-start'>
                <div className='col-md-6 mb-3'>
                  <div className='ProfileImg DBlock'>
                    <img src='/assets/images/Profile.jpg' alt='' />
                    <Link to='#!'>
                      <i className='fas fa-pencil-alt icon'></i>
                    </Link>
                  </div>
                </div>
                <div className='col-md-6 mb-3'>
                  <div className='ProfileForm DBlock'>
                    <form action='' className='form'>
                      <div className='form-row'>
                        <div className='col-4 mb-3'>
                          <label htmlFor=''>Full Name*</label>
                        </div>
                        <div className='col-8 mb-3'>
                          <input type='text' placeholder='John Galt' name='' />
                        </div>
                        <div className='col-4 mb-3'>
                          <label htmlFor=''>E-mail</label>
                        </div>
                        <div className='col-8 mb-3'>
                          <input type='text' placeholder='johngalt@gmail.com' name='' />
                        </div>
                        <div className='col-4 mb-3'>
                          <label htmlFor=''>Phone Number*</label>
                        </div>
                        <div className='col-8 mb-3'>
                          <input type='text' placeholder='+971 456 52 76 76' name='' />
                        </div>
                        <div className='col-4 mb-3'>
                          <label htmlFor=''>Language</label>
                        </div>
                        <div className='col-8 mb-3'>
                          <input type='text' placeholder='English' name='' />
                        </div>
                        <div className='col-4 mb-3'>
                          <label htmlFor=''>City</label>
                        </div>
                        <div className='col-8 mb-3'>
                          <input type='text' placeholder='Dubai, UAE' name='' />
                        </div>
                        <div className='col-12'>
                          <button type='submit'>Save Changes</button>
                        </div>
                      </div>
                    </form>
                    <button className='ChangePasswordBtn' onClick={() => setChangePassword({ changePassword: true })}>
                      Change Password
                    </button>
                    {changePassword ? (
                      <React.Fragment>
                        <form action='' className='form'>
                          <div className='form-row'>
                            <div className='col-4 mb-3'>
                              <label htmlFor=''>Actual Password*</label>
                            </div>
                            <div className='col-8 mb-3'>
                              <input type='password' placeholder='John Galt' name='' />
                            </div>
                            <div className='col-4 mb-3'>
                              <label htmlFor=''>New Password*</label>
                            </div>
                            <div className='col-8 mb-3'>
                              <input type='password' placeholder='johngalt@gmail.com' name='' />
                              <span>Choose a strong password</span>
                            </div>
                            <div className='col-12'>
                              <button type='submit'>Save Changes</button>
                            </div>
                          </div>
                        </form>
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditProfile;
