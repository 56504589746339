import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <React.Fragment>
      <div className='HomeArea DBlock'>
        <div className='container'>
          <div className='HomeDiv DBlock'>
            <div className='Title DBlock h-auto'>
              <h2>
                You <span>Ad</span> We <span>Seed</span>
              </h2>
              <form action='' className='form'>
                <div className='form-group'>
                  <input type='text' placeholder='I am Looking for...' name='' id='' required />
                  <button className='SubBtn' type='submit'>
                    <i className='fas fa-search icon'></i>
                  </button>
                </div>
              </form>
            </div>
            <div className='Desc DBlock h-auto'>
              <ul className='DescUl DFlex position-relative'>
                <li className='DropDown'>
                  <Link to='/classified'>
                    <img src='assets/svg/Classifieds.svg' alt='' />
                    <p>Classifieds</p>
                  </Link>
                </li>
                <li className='DropDown'>
                  <Link to='/motors'>
                    <img src='assets/svg/Motors.svg' alt='' />
                    <p>Motors</p>
                  </Link>
                </li>
                <li className='DropDown'>
                  <Link to='/jobs'>
                    <img src='assets/svg/Job.svg' alt='' />
                    <p>Jobs</p>
                  </Link>
                </li>
                <li className='DropDown'>
                  <Link to='/properties'>
                    <img src='assets/svg/Properties.svg' alt='' />
                    <p>Properties </p>
                  </Link>
                </li>
                <li className='DropDown'>
                  <Link to='/community'>
                    <img src='assets/svg/Community.svg' alt='' />
                    <p>Community</p>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
